import { CompanyApi } from "@/api"
import useNotification from "@/hooks/useNotification"
import CompaniesStore from "@/store/companies"
import UiPageSessionStore from "@/store/ui-page-session"
import UserSessionStore from "@/store/user-session"
import { useCallback, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"

const useLogic = () => {
    const { t } = useTranslation()
    const { profile } = UserSessionStore()
    const { refetchCompanies, update: updateCompanieState } = CompaniesStore()
    const [loading, setLoading] = useState<boolean>(false)
    const [metadata, setMetadata] = useState({
        page_size: 10,
        page_number: 0,
        total_count: 0,
    })
    const { notif } = useNotification()
    const { updateState, selectedItem } = UiPageSessionStore()
    const [items, setItems] = useState([])

    const refreshListCompanies = useCallback(
        async ({ page_number = 0, page_size = 10 }) => {
            setLoading(true)
            if (!profile?.uid) {
                return
            }
            const response = await CompanyApi.fetchListsCompanies({
                page_number: page_number === 0 ? 1 : page_number,
                page_size,
                user_uid: profile.uid,
            })

            if (response?.error) {
                notif({ message: t("SYSTEM_ERROR.INVALID_REQUEST"), type: "ERROR" })
            } else if (response?.metadata?._count) {
                setMetadata((prev) => ({
                    ...prev,
                    total_count: response.metadata._count,
                    page_size: response.metadata._limit,
                }))
                // The line bellow updated the companies to an empty array
                // updateCompanieState({ refetchCompanies: false }) TODO: à revoir car cette partie a été commentée pour s'adapter à un autre feature autre que companies
                setItems(response.data)
            }
            setLoading(false)
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [profile?.uid]
    )

    const handleChangePage = useCallback(
        (_: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
            setMetadata((prev) => ({
                ...prev,
                page_number: newPage,
            }))
            refreshListCompanies({ page_number: newPage + 1 })
        },
        [refreshListCompanies]
    )

    useEffect(() => {
        if (refetchCompanies) {
            refreshListCompanies({})
        }
    }, [refetchCompanies, refreshListCompanies])

    return {
        handleChangePage,
        metadata,
        items,
        updateViewState: updateState,
        selectedItem,
        refreshListCompanies,
        loading,
    }
}

export default useLogic
