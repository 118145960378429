import TablePaginationActions from "@/components/base/table/PaginationActions"
import { PropsWithChildren } from "react"
import useStyles from "./style"
import { useTranslation } from "react-i18next"
import { Paper, TablePagination, useTheme } from "@mui/material"
import ListContainer from "@/components/widget/page-container/list-container"
import TitleWrapper from "@/components/widget/page-container/list-container/title-wrapper"
import { SkeletonHeaderBtn } from "@/components/widget/skeleton/header-btn-left-right"
import IconNames from "@/types/icons"
import { MouseEvent } from "react"
import InputSearch from "@/components/base/input-search"

type PaginationsSearchLayoutProps = PropsWithChildren<{
    loading: boolean
    metadata: { total_count: number; page_size: number; page_number: number }
    handleChangePage: (_: MouseEvent<HTMLButtonElement> | null, newPage: number) => void
    titleIcon: IconNames
    layoutTitle: string
    searchValue: string
    onSearch: (searchValue: string) => void
}>

export const PaginationSearchLayout: React.FC<PaginationsSearchLayoutProps> = ({
    metadata,
    searchValue,
    onSearch,
    ...props
}) => {
    const { t } = useTranslation()
    const { classes } = useStyles()
    const { palette } = useTheme()

    return (
        <ListContainer>
            <>
                <TitleWrapper
                    pageIcon={props.titleIcon}
                    title={props.layoutTitle}
                    loading={props.loading}
                    iconSx={{
                        stroke: palette.primary.main,
                    }}
                />
                {(props.loading && searchValue?.length === 0) || (
                    <>
                        <Paper
                            sx={{
                                gap: "8px",
                                backgroundColor: "common.white",
                                flexDirection: "column",
                                padding: "8px 16px",
                            }}
                        >
                            <InputSearch onValueChange={onSearch} placeholder={t("LISTINGS.SEARCH")} />
                        </Paper>
                    </>
                )}
                <>{props.loading && searchValue?.length === 0 && <SkeletonHeaderBtn withoutSecondValue />}</>
                {props.children}
                <TablePagination
                    className={classes.pagination}
                    component={"div"}
                    labelDisplayedRows={({ from, to, count }) => {
                        return `${from}-${to} ${t("USERS.PAGING")} ${count}`
                    }}
                    count={metadata.total_count}
                    rowsPerPage={10}
                    page={metadata.page_number}
                    onPageChange={props.handleChangePage}
                    rowsPerPageOptions={[]}
                    ActionsComponent={TablePaginationActions}
                />
            </>
        </ListContainer>
    )
}
