import { Box } from "@mui/material"
import { PropsWithChildren, useCallback, useEffect, useState } from "react"

import BaseAppBar from "@/components/base/app-bar"
import BaseMenu from "@/components/widget/menu"
import useProfileRole from "@/hooks/useProfileRole"
import useWindowSize from "@/hooks/useWindowSize"
import UiStore from "@/store/ui"
import useStyles from "./styles"

const Layout = ({ children }: PropsWithChildren<any>) => {
    const { classes } = useStyles()
    const [width] = useWindowSize()
    const { setShowMenu } = UiStore()
    const isBasePath = window.location.pathname === "/"
    const { checkingPageAccess } = useProfileRole()
    const [loading, setLoading] = useState(true)
    const checkAccess = useCallback(async () => {
        setLoading(true)
        try {
            await checkingPageAccess()
        } finally {
            setLoading(false)
        }
    }, [checkingPageAccess])

    useEffect(() => {
        setShowMenu(width >= 768)
    }, [width, setShowMenu])

    useEffect(() => {
        checkAccess()
    }, [checkAccess])

    if (loading) return <></>

    return (
        <Box className={classes.layout}>
            {!isBasePath && <BaseMenu />}
            <Box className="children-container">
                {!isBasePath && <BaseAppBar />}
                <Box className="children-wrapper">{children}</Box>
            </Box>
        </Box>
    )
}

export default Layout
