import Icon from "@/components/base/Icon"
import { Stack, Typography } from "@mui/material"
import { useTranslation } from "react-i18next"

export const SectionEditHeader = () => {
    const { t } = useTranslation()
    return (
        <Stack flexDirection={"row"} justifyContent={"right"} gap={2} width={"100%"}>
            <Stack flexDirection={"row"} alignItems={"center"} gap={1}>
                <Typography component={"span"} variant="body1" fontWeight={500} color={"#949FB8"}>
                    {t("BUSINESSES.SYNCHRONIZE_WITH")}
                </Typography>
                <Icon name={"google"} size={20} />
            </Stack>
        </Stack>
    )
}
