import { makeStyles } from "tss-react/mui"

const useStyles = makeStyles()((theme) => ({
    wrapper: {
        flexDirection: "row",
        justifyContent: "space-between",
        alignContent: "center",
        alignItems: "center",
        "@media (min-width: 768px)": {
            padding: "8px 16px",
        },
        "& button": {
            padding: "8px 22px",
        },
        "& .icon-parameter": {
            "@media (max-width: 767px)": {
                minWidth: "auto",
                padding: "8px",
            },
        },
    },
}))

export default useStyles
