import { makeStyles } from "tss-react/mui"

const useStyles = makeStyles()((theme) => ({
    container: {
        "& .inputs-container": {
            flexDirection: "column",
            gap: 16,
            paddingBottom: "16px",
            "@media (min-width: 768px)": {
                gap: 24,
            },
            "& .inputs-wrapper": {
                height: "fit-content",
                display: "flex",
                flexDirection: "column",
                gap: 8,
                "@media (min-width: 768px)": {
                    gap: 16,
                },
                "& .error": {
                    textAlign: "center",
                },
                "& .wrapper": {
                    display: "flex",
                    flexDirection: "column",
                    gap: 8,
                },
                "& .has-adornment .MuiInputBase-root": {
                    gap: 8,
                    "& svg": {
                        margin: "auto",
                        marginBottom: 8,
                    },
                },
                "& .inputs": {
                    display: "flex",
                    flexDirection: "column",
                    gap: 8,
                    width: "100%",
                    "@media (min-width: 768px)": {
                        gap: 16,
                        flexDirection: "row",
                        flexWrap: "wrap",
                    },
                    "& > *": {
                        "@media (min-width: 768px)": {
                            width: "calc(50% - 8px)",
                            "&:nth-of-type(3)": {
                                width: "100%",
                            },
                        },
                    },
                },
            },
        },
    },
}))

export default useStyles
