import { ResellerApi } from "@/api"
import useLocation from "@/hooks/useLocation"
import useNotification from "@/hooks/useNotification"
import useBusinessRegularHoursStore from "@/store/overview/regular-hours"
import UiStore from "@/store/ui"
import UiPageSessionStore from "@/store/ui-page-session"
import { DayItem } from "@/types/businesses"
import { parseRegularHours, validateHours } from "@/utils"
import { zodResolver } from "@hookform/resolvers/zod"
import dayjs from "dayjs"
import customParseFormat from "dayjs/plugin/customParseFormat"
import cloneDeep from "lodash.clonedeep"
import { useCallback, useEffect, useMemo, useState } from "react"
import { SubmitErrorHandler, SubmitHandler, useForm } from "react-hook-form"
import { useTranslation } from "react-i18next"
import { z } from "zod"
import useBusinessLogic from "../useBusinessLogic"
dayjs.extend(customParseFormat)
interface EditScheduleValue {
    regularHours: []
}

const useLogic = (handlePrevious: () => void) => {
    const { navigateTo } = useBusinessLogic()
    const { t } = useTranslation()
    const [loading, setLoading] = useState(false)
    const [isFormValid, setIsFormValid] = useState(false)
    const [isFormSubmitted, setIsFormSubmitted] = useState(false)

    const { location, scheduleStatus } = useLocation()
    const { status, regularHours, updateRegularHours } = useBusinessRegularHoursStore()
    const [defaultStatusSchedule, setDefaultStatusSchedule] = useState<string>("")
    const { notif } = useNotification()
    const { preferredLanguage } = UiStore()
    const { previousItem, updatePreviousItem, updateState: setUiPageSession } = UiPageSessionStore()
    const checked = useMemo(() => {
        return previousItem?.isOpenWithoutHour
    }, [previousItem?.isOpenWithoutHour])
    const defaultValue: DayItem[] = useMemo(() => {
        return ["SUNDAY", "MONDAY", "TUESDAY", "WEDNESDAY", "THURSDAY", "FRIDAY", "SATURDAY"].map((name) => ({
            name,
            isOpen: false,
            isAvailableAllDay: false,
            times: [
                {
                    start: null,
                    end: null,
                    startValid: false,
                    endValid: false,
                    tomorrow: false,
                },
            ],
        }))
    }, [])
    const showAa = useMemo(() => {
        let value = false
        switch (preferredLanguage?.toLowerCase()) {
            case "en":
                value = true
                break
        }
        return value
    }, [preferredLanguage])
    const [days, setDays] = useState<DayItem[]>(defaultValue)

    const handlePreviousButton = useCallback(() => {
        navigateTo("detail")
        updatePreviousItem({ days })
        setIsFormSubmitted(false)
        if (typeof handlePrevious === "function") {
            handlePrevious()
        }
    }, [days])

    const handleCancelButton = useCallback(() => {
        const newRegularHourArray = parseRegularHours(regularHours, showAa)
        const initialValueStatus =
            status === "OPEN" && regularHours?.length === 0 ? "OPEN_WITHOUT_STANDARD_HOURS" : status
        updatePreviousItem({
            days: newRegularHourArray?.length > 0 ? cloneDeep(newRegularHourArray) : null,
            regularHours: regularHours === null ? null : cloneDeep(newRegularHourArray),
            isOpenWithoutHour: regularHours?.length === 0,
            status: initialValueStatus,
        })
        setDays(() => {
            return previousItem?.regularHours
        })
        setIsFormSubmitted(false)
        navigateTo("detail")
        if (typeof handlePrevious === "function") {
            handlePrevious()
        }
    }, [updatePreviousItem, previousItem, location])

    const onErrors: SubmitErrorHandler<any> = async (errors) => {
        console.log(errors)
    }
    const schema = useMemo(() => {
        return z.object({
            regularHours: z.string().array().optional(),
        })
    }, [])

    const { handleSubmit } = useForm<EditScheduleValue>({
        defaultValues: {
            regularHours: [],
        },
        resolver: zodResolver(schema),
        mode: "onTouched",
    })

    const onSubmit: SubmitHandler<any> = async () => {
        if (isFormValid) {
            setLoading(true)
            const body = { ...location }
            const arrayHours = []
            switch (previousItem?.status) {
                case "OPEN":
                    body["status"] = "OPEN"
                    break
                case "OPEN_WITHOUT_STANDARD_HOURS":
                    body["regularHours"] = []
                    body["status"] = "OPEN"
                    break
                case "CLOSED_TEMPORARILY":
                    body["status"] = "CLOSED_TEMPORARILY"
                    break
                case "CLOSED_PERMANENTLY":
                    body["status"] = "CLOSED_PERMANENTLY"
                    break
            }
            if (previousItem?.status === "OPEN") {
                days?.map((day, index, arr) => {
                    if (day.isOpen) {
                        if (day.isAvailableAllDay) {
                            arrayHours.push({
                                openDay: day.name.toLowerCase(),
                                openTime: "00:00",
                                closeDay: day.name.toLowerCase(),
                                closeTime: "24:00",
                            })
                        } else {
                            day.times?.map((hour) => {
                                if (hour.start && hour.end) {
                                    let tomorrowDayName = day.name.toLowerCase()
                                    if (hour.tomorrow && index < arr.length) {
                                        tomorrowDayName = arr?.[index + 1]?.name?.toLowerCase()
                                    } else if (hour.tomorrow) {
                                        tomorrowDayName = arr?.[0]?.name?.toLowerCase()
                                    }
                                    arrayHours.push({
                                        openDay: day.name.toLowerCase(),
                                        openTime: hour.start.format("HH:mm"),
                                        closeDay: tomorrowDayName || "",
                                        closeTime: hour.end.format("HH:mm"),
                                    })
                                }
                            })
                        }
                    }
                })
                body["regularHours"] = [...arrayHours]
            }
            const response = await ResellerApi.updateLocation({ ...body })
            if (response?.error) {
                notif({ message: t("SYSTEM_ERROR.INVALID_REQUEST"), type: "ERROR" })
            } else {
                setUiPageSession({ refreshItems: true })
                notif({ message: t("BUSINESSES.EDIT_SUCCESS"), type: "SUCCESS" })
                navigateTo("detail")
                updatePreviousItem({ days: null, regularHours: null, status: null })
                // updateLocation(response)
                updateRegularHours({
                    status: response?.status,
                    regularHours: response?.regularHours,
                })
                if (typeof handlePrevious === "function") {
                    handlePrevious()
                }
            }
            setLoading(false)
        }
        setIsFormSubmitted(true)
    }

    const handleChange = useCallback(() => {
        updatePreviousItem({
            isOpenWithoutHour: !previousItem?.isOpenWithoutHour,
        })
    }, [previousItem?.isOpenWithoutHour])

    const handleStatusScheduleClick = useCallback(
        (item: any) => {
            setDefaultStatusSchedule(item.value)
            if (item.value === "OPEN_WITHOUT_STANDARD_HOURS") {
                updatePreviousItem({ status: item.value, regularHours: null })
            } else if (item.value === "OPEN") {
                updatePreviousItem({ status: item.value, regularHours: previousItem?.regularHours || [] })
            } else {
                updatePreviousItem({ status: item.value })
            }
        },
        [previousItem?.regularHours]
    )

    useEffect(() => {
        if (previousItem?.status) {
            if (previousItem?.regularHours === null && previousItem?.status === "OPEN") {
                handleStatusScheduleClick({ value: "OPEN_WITHOUT_STANDARD_HOURS" })
            } else {
                handleStatusScheduleClick({ value: previousItem?.status })
            }
        }
    }, [previousItem?.status, handleStatusScheduleClick])

    useEffect(() => {
        setDays(() => {
            if (previousItem?.days) {
                return previousItem.days || []
            }
            const newRegularHourArray = parseRegularHours(regularHours, showAa)
            return newRegularHourArray
        })
    }, [previousItem?.days, showAa, regularHours])

    useEffect(() => {
        setIsFormSubmitted(false)
        if (previousItem?.isOpenWithoutHour) {
            setIsFormValid(true)
        } else {
            const isValid = validateHours(days || [])
            setIsFormValid(isValid)
        }
    }, [validateHours, days, previousItem?.isOpenWithoutHour])

    return {
        t,
        days,
        location,
        loading,
        isFormValid,
        checked,
        isFormSubmitted,
        defaultValue,
        scheduleStatus,
        defaultStatusSchedule,
        navigateTo,
        setDays,
        setIsFormValid,
        handleSubmit,
        onSubmit,
        onErrors,
        handlePreviousButton,
        handleCancelButton,
        handleChange,
        validateHours,
        handleStatusScheduleClick,
    }
}

export default useLogic
