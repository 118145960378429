import Icon from "@/components/base/Icon"
import CustomTooltip from "@/components/base/Tooltip"
import { IconButton } from "@mui/material"
import { useTranslation } from "react-i18next"

type ViewQuicksiteProps = {
    link: string
}

const ViewQuicksite: React.FC<ViewQuicksiteProps> = ({ link }) => {
    const { t } = useTranslation()
    return (
        <CustomTooltip
            title={t("WEB_PAGE.CARD.VIEW_IN_NET")}
            sx={{ backgroundColor: "#F5F5F5", width: "36px", height: "36px", borderRadius: "8px" }}
        >
            <IconButton
                onClick={() => link && window.open(link.startsWith("http") ? link : `https://${link}`, "_blank")}
            >
                <Icon name="net" />
            </IconButton>
        </CustomTooltip>
    )
}

export default ViewQuicksite
