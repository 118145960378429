import { Box, IconButton, Paper } from "@mui/material"
import Icon from "@/components/base/Icon"
import { Stack, Divider } from "@mui/material"
import React, { useCallback, useMemo, useState } from "react"
import { chevronBtn, colorItem, endAdornmentColor, paperColor } from "./style"
import BaseInput from "@/components/base/input"
import { HexColor } from "@/types/hexadecimal"
import { isValidHex } from "@/utils/web-page/get-text-color-from-hex"

interface ColorPickerProps {
    colors: string[]
    onSelectColor: (color: HexColor) => void
    currentValue?: HexColor
}

const ColorPicker: React.FC<ColorPickerProps> = ({ colors, onSelectColor, currentValue }) => {
    const [selectedColor, setSelectedColor] = useState<HexColor | null>(currentValue ?? "#000000")
    const [isModalOpen, setIsModalOpen] = useState(false)
    const [error, setError] = useState(false)

    const colorBox = useMemo(() => {
        return isValidHex(selectedColor) ? selectedColor : "#000"
    }, [selectedColor])

    const handleColorClick = (color: string) => {
        if (!isValidHex(color)) {
            return
        }
        setSelectedColor(color as HexColor)
        onSelectColor(color as HexColor)
        setIsModalOpen(false)
        setError(false)
    }

    const handleChangeColor = useCallback(
        (e: React.ChangeEvent<HTMLInputElement>) => {
            const color = (e.target.value || "").trim()
            setSelectedColor(color as HexColor)
            if (isValidHex(color)) {
                onSelectColor(color as HexColor)
                setError(false)
            } else if (color && !isValidHex) {
                setError(true)
            }
        },
        [onSelectColor]
    )

    return (
        <Box className="m-auto relative" data-testid="color-picker">
            <Stack gap={1} flexDirection={"row"} alignItems={"center"} justifyContent={"center"}>
                <Box sx={{ ...colorItem, backgroundColor: colorBox, border: colorBox }} />

                <IconButton
                    onClick={() => setIsModalOpen((prev) => !prev)}
                    sx={chevronBtn}
                    data-testid="open-modal-picker"
                >
                    <Icon name="chevronDown" />
                </IconButton>
            </Stack>

            {isModalOpen && (
                <Paper sx={paperColor}>
                    <Stack gap={1.5} flexDirection={"row"} flexWrap={"wrap"} width={"114px"}>
                        {colors.map((color) => (
                            <Box
                                key={color}
                                onClick={() => handleColorClick(color)}
                                sx={{
                                    ...colorItem,
                                    backgroundColor: color,
                                }}
                            />
                        ))}
                    </Stack>
                    <Divider />
                    <BaseInput
                        placeholder={"#HEX"}
                        hiddenLabel
                        value={selectedColor}
                        onChange={(e) => {
                            handleChangeColor(e)
                        }}
                        error={error}
                        endAdornment={
                            <Stack
                                sx={{
                                    backgroundColor: selectedColor,
                                    fill: selectedColor,
                                    ...endAdornmentColor,
                                }}
                            />
                        }
                        inputProps={{
                            style: {
                                width: "75%",
                                whiteSpace: "nowrap",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                                minWidth: 0,
                            },
                        }}
                        sx={{
                            width: "100%",
                            "& .MuiFilledInput-root": {
                                gap: "6px",
                            },
                        }}
                        data-testid="color-input"
                    />
                </Paper>
            )}
        </Box>
    )
}

export default ColorPicker
