import { Stack, StackProps } from "@mui/material"

type CardMediaProps = {
    image: string
    alt: string
} & StackProps

const CardMedia = ({ image, alt, ...props }: CardMediaProps) => {
    return (
        <Stack
            sx={{
                backgroundColor: "grey",
                height: "171px",
                width: "171px",
                borderRadius: 2,
            }}
            {...props}
        >
            <img
                src={image}
                alt={alt}
                style={{
                    width: "100%",
                    height: "100%",
                    objectFit: "cover",
                }}
            />
        </Stack>
    )
}

export default CardMedia
