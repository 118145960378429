import Icon from "@/components/base/Icon"
import { TitleWrapper } from "@/components/base/title-wrapper"
import StatusChip from "@/components/features/web-page/status-chip"
import {
    Box,
    Paper,
    Stack,
    Typography,
    Menu,
    MenuItem,
    ListItemIcon,
    ListItemText,
    Divider,
    Skeleton,
    CircularProgress,
} from "@mui/material"
import { useTranslation } from "react-i18next"
import { useNavigate, useParams } from "react-router-dom"
import { useState, useCallback, useEffect, useMemo } from "react"
import { DeleteModal } from "@/components/widget/modal-delete"
import { LocationsApi, QuicksiteService } from "@/api"
import UserSessionStore from "@/store/user-session"
import StatusText from "@/components/features/web-page/status-text"
import useNotification from "@/hooks/useNotification"

const WebPageParameters = () => {
    const { locationId } = useParams<{ locationId: string }>()
    const { t } = useTranslation()
    const navigate = useNavigate()
    const { profile, isSingleLocation, singleLocationId } = UserSessionStore()
    const { notif } = useNotification()

    const [menuAnchor, setMenuAnchor] = useState<HTMLElement | null>(null)
    const [loading, setLoading] = useState<{ general: boolean; action: boolean }>({ general: false, action: false })
    const [currentLocation, setCurrentLocation] = useState<any | null>(null)
    const [action, setAction] = useState<"DELETE" | "SUSPEND" | null>(null)

    const isMenuOpen = Boolean(menuAnchor)

    const handleMenuOpen = useCallback((event: React.MouseEvent<HTMLDivElement>) => {
        setMenuAnchor(event.currentTarget)
    }, [])

    const handleMenuClose = useCallback(() => {
        setMenuAnchor(null)
    }, [])

    const handleAction = useCallback((typeAction: "DELETE" | "SUSPEND") => {
        setAction(typeAction)
    }, [])

    const handleRepublish = useCallback(() => {
        setLoading((prev) => ({ ...prev, action: true }))
        QuicksiteService.unsuspend(locationId, profile.uid)
            .then(() => {
                notif({
                    message: t("WEB_PAGE.NOTIFICATIONS.DOMAIN_PUBLISHED"),
                    type: "SUCCESS",
                })
                handleMenuClose()
                navigate(`/web-page/${locationId}`)
            })
            .catch(() => {
                notif({ message: t("SYSTEM_ERROR.INVALID_REQUEST"), type: "ERROR" })
            })
            .finally(() => {
                handleMenuClose()
                setLoading((prev) => ({ ...prev, action: false }))
            })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [handleMenuClose, locationId, profile.uid])

    const confirmAction = useCallback(() => {
        if (!action || !locationId) return

        setLoading((prev) => ({ ...prev, action: true }))

        const isDeleteAction = action === "DELETE"

        const actionPromise = isDeleteAction
            ? QuicksiteService.deleteDomain(locationId, profile.uid)
            : QuicksiteService.suspend(locationId, profile.uid)

        actionPromise
            .then(() => {
                notif({
                    message: t(
                        isDeleteAction
                            ? `WEB_PAGE.NOTIFICATIONS.DOMAIN_DELETED`
                            : "WEB_PAGE.NOTIFICATIONS.DOMAIN_SUSPENDED"
                    ),
                    type: "SUCCESS",
                })
                navigate(`/web-page/${!isDeleteAction ? locationId : isSingleLocation ? singleLocationId : ""}`)
            })
            .catch(() => {
                notif({ message: t("SYSTEM_ERROR.INVALID_REQUEST"), type: "ERROR" })
            })
            .finally(() => setLoading((prev) => ({ ...prev, action: false })))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [action, locationId, profile.uid])

    useEffect(() => {
        if (!locationId || !profile.uid) return

        setLoading((prev) => ({ ...prev, general: true }))
        LocationsApi.getALocation({ id: locationId, user_uid: profile.uid })
            .then(setCurrentLocation)
            .catch(console.error)
            .finally(() => setLoading((prev) => ({ ...prev, general: false })))
    }, [locationId, profile.uid])

    const status = useMemo(() => currentLocation?.quicksite?.status || "", [currentLocation])
    const url = currentLocation?.quicksite?.url ?? ""

    return (
        <Stack gap={2}>
            <DeleteModal
                open={Boolean(action)}
                onClose={() => setAction(null)}
                loading={loading.action}
                onDelete={confirmAction}
                title={t(`WEB_PAGE.MODAL.${action}.CONFIRM`)}
                description={t(`WEB_PAGE.MODAL.${action}.DETAILS`)}
                submitLabel={t(`WEB_PAGE.MODAL.${action}.SUBMIT`)}
                noIcon
                warnButton
                withDivider
            />

            <Stack style={{ cursor: "pointer" }} data-testid="navigate-back">
                <TitleWrapper
                    title={t("GENERAL.ACTION.PARAMETERS")}
                    handlePreviousButton={() => navigate(-1)}
                    className="title-overview"
                />
            </Stack>

            <Paper>
                <Stack direction="row" padding={1} justifyContent="space-between">
                    <Typography variant="h2">
                        {loading.general ? <Skeleton width={200} height={40} /> : t("WEB_PAGE.STATUS.LABEL")}
                    </Typography>
                    <Box gap={2} alignItems="center" display="flex">
                        {loading.general ? <Skeleton width={80} height={30} /> : <StatusChip status={status} />}
                        <div onClick={handleMenuOpen} style={{ cursor: "pointer" }}>
                            <Icon name="threeDotsHorizontal" />
                        </div>
                    </Box>
                </Stack>
            </Paper>

            <Paper>
                <Stack direction="row" padding={1} justifyContent="space-between">
                    <Typography variant="h2">
                        {loading.general ? <Skeleton width={200} height={40} /> : t("WEB_PAGE.DOMAIN")}
                    </Typography>
                    {/* https://ubiweb.atlassian.net/jira/software/c/projects/DLP/issues/DLP-4583 */}
                    {/* QA - Le statut de connexion du site sous notre domaine est Connecté mais le statut de publication du site est Échoué */}
                    {/* The status domain features has been hidden until the next sprint for it needs the choice domaine features to be logicaally implemented */}
                    {/* {loading.general ? <Skeleton width={80} height={30} /> : <StatusDomain status="CONNECTED" />} */}
                </Stack>

                <Stack padding={1} gap={1}>
                    <Typography variant="h6">Url</Typography>
                    {loading.general ? (
                        <Skeleton width={300} />
                    ) : (
                        <Typography
                            component="a"
                            color="primary"
                            href={url.startsWith("http") ? url : `https://${url}`}
                            target="_blank"
                            style={{ cursor: "pointer" }}
                        >
                            {url}
                        </Typography>
                    )}
                </Stack>

                <Stack padding={1} gap={1}>
                    <Typography variant="h6">{t("LABEL.STATUS")}</Typography>
                    {loading.general ? <Skeleton width={100} /> : <StatusText status={status} />}
                </Stack>
            </Paper>

            <Menu
                anchorEl={menuAnchor}
                open={isMenuOpen}
                onClose={handleMenuClose}
                anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                transformOrigin={{ vertical: "top", horizontal: "right" }}
                PaperProps={{
                    style: {
                        padding: 0,
                    },
                }}
                disableAutoFocusItem
            >
                <MenuItem
                    disabled={loading.action || status === "IN_PROGRESS"}
                    onClick={() => (status === "SUSPENDED" ? handleRepublish() : handleAction("SUSPEND"))}
                >
                    <ListItemIcon>
                        {loading.action && action === "SUSPEND" ? (
                            <CircularProgress size={16} />
                        ) : (
                            <Icon name="linkBroken" />
                        )}
                    </ListItemIcon>
                    <ListItemText
                        primary={t(status === "SUSPENDED" ? "WEB_PAGE.ACTION.REPUBLISH" : "WEB_PAGE.ACTION.SUSPEND")}
                        primaryTypographyProps={{ sx: { color: "primary.main", fontWeight: 500 } }}
                    />
                </MenuItem>

                <Divider />

                <MenuItem disabled={loading.action || status === "IN_PROGRESS"} onClick={() => handleAction("DELETE")}>
                    <ListItemIcon>
                        {loading.action && action === "DELETE" ? (
                            <CircularProgress size={16} />
                        ) : (
                            <Icon name="trash" sx={{ color: "error.main" }} />
                        )}
                    </ListItemIcon>
                    <ListItemText
                        primary={t("WEB_PAGE.ACTION.DELETE")}
                        primaryTypographyProps={{ sx: { color: "error.main", fontWeight: 500 } }}
                    />
                </MenuItem>
            </Menu>
        </Stack>
    )
}

export default WebPageParameters
