export enum AMPLITUDE_EVENT {
    LOGGED_IN = "Logged In",
}

export type USER_PROPERTIES = {
    user_id: string
    business_id: string
    business_name: string
    company_id: string
    company_name: string
    reseller_id: string
    reseller_name: string
    user_type: "client" | "resellerAdmin" | "dilypseAdmin"
}

export type LOGGED_IN_PROPERTIES = {
    user_id: string
    timestamp: Date
    login_method: "mail/password" | "Google" | "SSO" | ""
    login_status: "succès" | "échec" | ""
    platform: "Application mobile" | "Application web" | "Application web téléphone" | ""
    failure_reason: "erreur système" | "mauvais mot de passe" | "compte inexistant" | ""
}

export type AMPLITUDE_EVENT_PROPERTIES = LOGGED_IN_PROPERTIES
