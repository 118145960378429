import { getWebStatusChipProps, WebStatusProps } from "@/utils/web-page/get-status"
import { Typography } from "@mui/material"
import { useTranslation } from "react-i18next"

const StatusText: React.FC<WebStatusProps> = ({ status }) => {
    const { t } = useTranslation()
    const { label } = getWebStatusChipProps(t, status)
    return <Typography>{`${t("WEB_PAGE.STATUS.INTRODUCTION")} ${label}`}</Typography>
}

export default StatusText
