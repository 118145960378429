import { ResellerApi } from "@/api"
import useLocation from "@/hooks/useLocation"
import useNotification from "@/hooks/useNotification"
import useAttributesStore, { AttributChecked, AttributeType, ListEditedAttributes } from "@/store/overview/attributes"
import useScoreStore from "@/store/overview/score/useScoreStore"
import UiStore from "@/store/ui"
import UiPageSessionStore from "@/store/ui-page-session"
import UserSessionStore from "@/store/user-session"
import { zodResolver } from "@hookform/resolvers/zod"
import { useCallback, useEffect, useMemo, useState } from "react"
import { SubmitErrorHandler, SubmitHandler, useForm } from "react-hook-form"
import { useTranslation } from "react-i18next"
import { useParams } from "react-router-dom"
import { z } from "zod"
import useBusinessLogic from "../useBusinessLogic"

interface LoggedAttribute {
    [key: string]: boolean | string[]
}

const useLogic = () => {
    const { navigateTo } = useBusinessLogic()
    const { t } = useTranslation()
    const { businessId } = useParams()
    const { profile } = UserSessionStore()
    const [loading, setLoading] = useState(false)
    const { notif } = useNotification()
    const { refreshScoring } = useScoreStore({})
    const { editedAttributes, attributIds, update, listAttributes, attributeUnavailable } = useAttributesStore()
    const { menuPage, showPage, previousItem, updatePreviousItem } = UiPageSessionStore()
    const { location } = useLocation()
    const { preferredLanguage } = UiStore()

    const getAttributes = useCallback(async () => {
        const attributes: Array<{ title: string; attributes: any[] }> = []
        const locationAttrSelect = new Set(Object.keys(attributIds ?? {}))

        if (Array.isArray(listAttributes)) {
            listAttributes?.forEach((attribute) => {
                if (attribute.type !== "RADIO" && attribute.type !== "CHECKBOX") return

                let selected: any = attribute.type === "CHECKBOX" ? [] : null
                const opt: Array<{ display: string; value: string; checked: boolean }> = []
                const isSelected = locationAttrSelect.has(attribute.id)

                attribute.values.forEach((val) => {
                    const checked =
                        isSelected &&
                        ((attribute.type === "RADIO" && attributIds[attribute.id] === val.value) ||
                            (attribute.type === "CHECKBOX" &&
                                (attributIds[attribute.id] as string[]).includes(String(val.value))))

                    const option = { display: val.displayName, value: val.value, checked }
                    opt.push(option)

                    if (checked) {
                        if (attribute.type === "RADIO") {
                            selected = option
                        } else {
                            ;(selected as Array<typeof option>).push(option)
                        }
                    }
                })

                if (attribute.type === "RADIO" && !opt.some((el) => el.checked)) {
                    const noneOption = {
                        display: t("BUSINESSES.EDIT_ATTRIBUTES.UNDEFINED"),
                        value: "NONE",
                        checked: true,
                    }
                    opt.push(noneOption)
                    selected = noneOption
                }

                const optionGroup = {
                    title: attribute.groupLabel,
                    attributes: [
                        {
                            id: attribute.id,
                            type: attribute.type,
                            title: attribute.label,
                            options: opt,
                            selected,
                        },
                    ],
                }

                const existingGroup = attributes.find((el) => el.title === attribute.groupLabel)
                if (existingGroup) {
                    existingGroup.attributes.push(optionGroup.attributes[0])
                } else {
                    attributes.push(optionGroup)
                }
            })
        }

        update({ editedAttributes: attributes })
    }, [businessId, attributIds, listAttributes])

    useEffect(() => {
        if (previousItem && previousItem.attributes?.length > 0) {
            update({ editedAttributes: [...previousItem.attributes] })
        }
        if (
            profile.uid &&
            businessId &&
            menuPage === "businesses" &&
            showPage === "edit-attribute" &&
            (!previousItem || (previousItem && !previousItem.attributes))
        ) {
            getAttributes()
        }
    }, [showPage, location, preferredLanguage, getAttributes, profile?.uid, businessId])

    const handlePreviousButton = useCallback(() => {
        navigateTo("detail")
        updatePreviousItem({
            attributes: [...editedAttributes],
        })
    }, [updatePreviousItem, editedAttributes])

    const handleCancelButton = useCallback(() => {
        navigateTo("detail")
        update({ editedAttributes: [] })
        updatePreviousItem({
            attributes: null,
            isOpenWithoutHour: false,
        })
    }, [updatePreviousItem])

    const onErrors: SubmitErrorHandler<any> = async (errors) => {
        console.log(errors)
    }
    const schema = useMemo(() => {
        return z.object({
            attributes: z.string().array().optional(),
        })
    }, [])

    const { handleSubmit } = useForm<ListEditedAttributes>({
        defaultValues: {
            attributes: [],
        },
        resolver: zodResolver(schema),
        mode: "onTouched",
    })

    const handleCheckboxAttribute = (attr: AttributeType, arrayAttributes: LoggedAttribute) => {
        if (attr.type === "CHECKBOX" && attr.selected && attr.selected.value !== "NONE") {
            const arrayBox = attr.selected.map((sel: AttributChecked) => sel.value)
            if (arrayBox.length > 0) {
                arrayAttributes[attr.id] = [...arrayBox]
            } else {
                arrayAttributes[attr.id] = []
            }
        }
    }
    const onSubmit: SubmitHandler<any> = useCallback(async () => {
        setLoading(true)
        const body: any = { ...location }
        const newAttributIds: LoggedAttribute = {}
        const currentAttributIds = { ...attributIds }

        editedAttributes.map((section) => {
            section.attributes.map((attr: AttributeType) => {
                if (attr.type === "RADIO") {
                    if (attr.selected && attr.selected.value != "NONE") {
                        newAttributIds[attr.id] = attr.selected.value
                    } else {
                        newAttributIds[attr.id] = null
                    }
                    delete currentAttributIds[attr.id]
                }
                handleCheckboxAttribute(attr, newAttributIds)
            })
        })

        body["attributIds"] = { ...currentAttributIds, ...newAttributIds }

        const response = await ResellerApi.updateLocation({ ...body })
        if (response?.error) {
            notif({ message: t("SYSTEM_ERROR.INVALID_REQUEST"), type: "ERROR" })
        } else {
            notif({ message: t("BUSINESSES.EDIT_SUCCESS"), type: "SUCCESS" })
            navigateTo("detail")
            update({ editedAttributes: [] })
            updatePreviousItem({
                attributes: null,
            })
            await refreshScoring()
            update({ attributIds: response.attributIds })
        }
        setLoading(false)
    }, [location, attributIds, editedAttributes, notif, t, navigateTo, update, updatePreviousItem, refreshScoring])

    return {
        handleSubmit,
        onSubmit,
        onErrors,
        handleCancelButton,
        handlePreviousButton,
        t,
        loading,
        editedAttributes,
        attributeUnavailable,
    }
}

export default useLogic
