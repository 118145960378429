import { ResellerApi } from "@/api"
import useLocation from "@/hooks/useLocation"
import useNotification from "@/hooks/useNotification"
import UiPageSessionStore from "@/store/ui-page-session"
import { DescriptionValue, ProfessionnalInfoValue } from "@/types/businesses"
import { zodResolver } from "@hookform/resolvers/zod"
import { useCallback, useEffect, useMemo, useState } from "react"
import { SubmitErrorHandler, SubmitHandler, useForm } from "react-hook-form"
import { useTranslation } from "react-i18next"
import { z } from "zod"
import useBusinessLogic from "../useBusinessLogic"
import OverviewDescriptionStore from "@/store/overview/long-description"
import useScoreStore from "@/store/overview/score/useScoreStore"
import OverviewProInfosStore from "@/store/overview/pro-infos"

const useLogic = (handlePrevious: () => void) => {
    const { navigateTo } = useBusinessLogic()
    const { t } = useTranslation()
    const { location } = useLocation()
    const { professionalInfo: proInfos, update: updateInfo } = OverviewProInfosStore()
    const [loading, setLoading] = useState(false)
    const { notif } = useNotification()
    const { refreshScoring } = useScoreStore({})
    const { updatePreviousItem, previousItem } = UiPageSessionStore()
    const schema = useMemo(() => {
        return z.object({
            professionalInfo: z.string().trim().optional(),
        })
    }, [])
    const onErrors: SubmitErrorHandler<any> = async (errors) => {
        console.log(errors)
    }
    const { control, handleSubmit, trigger, setValue, watch, getValues } = useForm<ProfessionnalInfoValue>({
        defaultValues: {
            professionalInfo: "",
        },
        resolver: zodResolver(schema),
        mode: "onTouched",
    })

    const description = watch("professionalInfo") ?? ""

    const setValues = useCallback(
        (description) => {
            setValue("professionalInfo", description || "")
            trigger(["professionalInfo"])
        },
        [setValue, trigger]
    )

    const onSubmit: SubmitHandler<any> = async (data: ProfessionnalInfoValue) => {
        setLoading(true)
        const body = { ...location, professionalInfo: proInfos }
        body["professionalInfo"] = data.professionalInfo

        const response = await ResellerApi.updateLocation({ ...body })
        if (response?.error) {
            notif({ message: t("SYSTEM_ERROR.INVALID_REQUEST"), type: "ERROR" })
        } else {
            notif({ message: t("BUSINESSES.EDIT_SUCCESS"), type: "SUCCESS" })
            updateInfo({ professionalInfo: response.professionalInfo })
            updatePreviousItem({ professionalInfo: null })
            await refreshScoring()
            navigateTo("detail")
            if (typeof handlePrevious === "function") {
                handlePrevious()
            }
        }
        setLoading(false)
    }

    const handleCancelButton = useCallback(() => {
        navigateTo("detail")
        updatePreviousItem({ professionalInfo: proInfos })
        if (typeof handlePrevious === "function") {
            handlePrevious()
        }
    }, [proInfos])

    const handlePreviousButton = useCallback(() => {
        navigateTo("detail")
        updatePreviousItem({ professionalInfo: getValues().professionalInfo })
        if (typeof handlePrevious === "function") {
            handlePrevious()
        }
    }, [getValues])

    useEffect(() => {
        if (location?.id) {
            if (previousItem?.professionalInfo) {
                setValue("professionalInfo", previousItem.professionalInfo || "")
            } else {
                setValues(proInfos)
            }
        }
    }, [proInfos, previousItem, location?.id])

    return {
        handlePreviousButton,
        handleSubmit,
        onSubmit,
        onErrors,
        t,
        watch,
        control,
        handleCancelButton,
        loading,
        setValues,
        getValues,
        description,
    }
}

export default useLogic
