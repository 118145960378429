import ReactDOM from "react-dom/client"
import "swiper/css"
import App from "./App"
import env from "./config/env"
import "./i18n"
import "./index.css"
import { NotificationService, SentryService, ServiceWorker } from "./services"

import TagManager from "react-gtm-module"

const tagManagerArgs = {
    gtmId: env.GTM_ID,
}

const root = ReactDOM.createRoot(document.getElementById("root"))

TagManager.initialize(tagManagerArgs)

// SentryService.init()
// AmplitudeService.init()
SentryService.init()
root.render(<App />)
if (env.ENV === "local") {
    ServiceWorker.unregister()
} else {
    ServiceWorker.register({
        onUpdate: (version: string) => {
            NotificationService.showNewVersion(true)
            NotificationService.updateAppVersion(version)
        },
    })
}
