import * as amplitude from "@amplitude/analytics-browser"
import { AMPLITUDE_EVENT, AMPLITUDE_EVENT_PROPERTIES } from "./types"

declare global {
    interface Window {
        amplitude: any
    }
}

class AmplitudeService {
    amplitude: amplitude.Types.BrowserClient

    constructor() {
        this.init()
    }

    init() {
        if (!this.amplitude) {
            this.amplitude = window?.amplitude
        }
    }

    setUserId(userId: string) {
        this.init()
        this.amplitude?.setUserId(userId)
    }

    trackEvent(eventName: AMPLITUDE_EVENT, eventProperties?: AMPLITUDE_EVENT_PROPERTIES) {
        this.init()
        this.amplitude?.track(eventName, eventProperties)
    }

    async setUserProperties(userProperties: object) {
        this.init()
        if (this.amplitude) {
            const userId = this.amplitude?.getUserId()
            if (!userId && userProperties["user_id"]) {
                this.setUserId(userProperties["user_id"])
            }
            const identify = new amplitude.Identify()
            Object.entries(userProperties).forEach(([key, value]) => {
                identify.set(key, value)
            })
            await this.amplitude?.identify(identify)
        }
    }
}

export default new AmplitudeService()
