import { Paper, Skeleton } from "@mui/material"
import { useStyles } from "./styles"

type SkeletonHeaderBtnProps = {
    withoutSecondValue?: boolean
}

export const SkeletonHeaderBtn: React.FC<SkeletonHeaderBtnProps> = ({ withoutSecondValue }) => {
    const { classes } = useStyles()
    return (
        <Paper className={classes.header} data-testid="bloc-header-btn">
            <Skeleton className="btn-left" variant="rectangular" height={56} />
            <>{withoutSecondValue === true || <Skeleton className="btn-right" variant="rectangular" height={56} />}</>
        </Paper>
    )
}
