import { HexColor } from "@/types/hexadecimal"

/**
 * Vérifie si une chaîne est une couleur hexadécimale valide.
 *
 * @param {string} color - La chaîne à tester.
 * @returns {color is HexColor} `true` si la couleur est un hex valide, sinon `false`.
 *
 * @example
 * isValidHex("#FF5733"); // true
 * isValidHex("FF5733");  // false
 * isValidHex("#XYZ123"); // false
 */
export const isValidHex = (color: string): color is HexColor => /^#([0-9A-Fa-f]{3}|[0-9A-Fa-f]{6})$/.test(color)

/**
 * Détermine la couleur du texte (noir ou blanc) en fonction d'une couleur de fond hexadécimale.
 *
 * 🔹 **Règle définie dans le ticket de spécification:**
 *
 * - La couleur des textes dans les zones dont la couleur est modifiable suit la règle suivante :
 * - Deux couleurs possibles pour le texte : **noir (`#000000`) ou blanc (`#FFFFFF`)**
 * - Pour déterminer la couleur du texte sur un fond donné (en HEX), on se base sur la **luminosité perçue**.
 *
 * 📌 **Méthode de calcul :**
 *
 * 1. Convertir la valeur HEX en valeurs RGB.
 * 2. Calculer la luminosité perçue avec la formule :
 *
 *    **Luminosité = (Rouge × 299 + Vert × 587 + Bleu × 114) / 1000**
 *
 * 3. Appliquer la règle :
 *    - Si la **luminosité > 128** → utiliser du **texte noir** (`#000000`).
 *    - Sinon → utiliser du **texte blanc** (`#FFFFFF`).
 *
 *   **Source : https://ubiweb.atlassian.net/jira/software/c/projects/DLP/issues/DLP-4375**
 *
 * @param {string} hexColor - La couleur de fond au format hexadécimal.
 * @returns {"#000000" | "#FFFFFF"} `#000000` si la couleur est claire, sinon `#FFFFFF`.
 *
 * @example
 * getTextColor("#FFFFFF"); // "#000000"
 * getTextColor("#000000"); // "#FFFFFF"
 * getTextColor("#FF5733"); // "#000000"
 */
export const getTextColor = (hexColor: string): "#000000" | "#FFFFFF" => {
    if (!isValidHex(hexColor)) return "#FFFFFF"

    let r: number, g: number, b: number

    if (hexColor.length === 4) {
        r = parseInt(hexColor[1] + hexColor[1], 16)
        g = parseInt(hexColor[2] + hexColor[2], 16)
        b = parseInt(hexColor[3] + hexColor[3], 16)
    } else {
        r = parseInt(hexColor.slice(1, 3), 16)
        g = parseInt(hexColor.slice(3, 5), 16)
        b = parseInt(hexColor.slice(5, 7), 16)
    }

    const brightness = (r * 299 + g * 587 + b * 114) / 1000

    return brightness > 128 ? "#000000" : "#FFFFFF"
}
