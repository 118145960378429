import { SxProps, Theme } from "@mui/material/styles"

export const modalContainer: SxProps<Theme> = {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "100vh",
    bgcolor: "rgba(0, 0, 0, 0.5)", // Background semi-transparent
}

export const paperStyle: SxProps<Theme> = {
    width: "500px",
    borderRadius: 2,
    position: "relative",
}

export const closeButton: SxProps<Theme> = {
    position: "absolute",
    top: 8,
    right: 8,
}

export const titleStyle: SxProps<Theme> = {
    fontWeight: 500,
}

export const subtitleStyle: SxProps<Theme> = {
    color: "text.secondary",
}

export const textFieldStyle: SxProps<Theme> = {}

export const cancelButton: SxProps<Theme> = {
    color: "primary.main",
    background: "rgba(1, 128, 255, 0.08)",
    border: "none",
    minWidth: 0,
    boxShadow: "none",
}

export const saveButton: SxProps<Theme> = {
    flex: 1,
}
