import env from "@/config/env"
import { mockServer } from "@/utils/mock-server"
import { DispatchrService } from ".."
import { ApiService } from "../base"
import { HexColor } from "@/types/hexadecimal"

class QuicksiteService extends ApiService {
    constructor() {
        super({
            baseURL: `${env.QUICKSITE_API_PATH}`,
        })
    }

    getLocationStatus(locationId: string) {
        return this.get(`/quicksite/${locationId}/status`)
    }

    publish(data: any) {
        return this.post("/quicksite/publish", data)
    }

    getQuicksite({ location_id, user_uid, company_id }: { location_id: string; user_uid: string; company_id: string }) {
        return this.get(`quicksite/${location_id}/preview`, {
            user_uid,
            company_id,
        })
    }

    getQuicksitePreview({
        location_id,
        user_uid,
        company_id,
    }: {
        location_id: string
        user_uid: string
        company_id: string
    }) {
        return this.get(`quicksite/${location_id}/preview`, {
            user_uid,
            company_id,
        })
    }

    getDomain(locationId: string) {
        return DispatchrService.get(`quicksite-domain/${locationId}`)
    }

    suspend(locationId: string, userUid: string) {
        return this.put(`/quicksite/${locationId}/suspend`, { userUid })
    }

    unsuspend(locationId: string, userUid: string) {
        return this.put(`/quicksite/${locationId}/unsuspend`, { userUid })
    }

    deleteDomain(locationId: string, userUid: string) {
        return this.delete(`/quicksite/${locationId}/delete`, { data: { userUid } })
    }

    updateMedia(
        locationId: string,
        payload: {
            userUid: string
            medias?: string[]
            hero_image?: string
        }
    ) {
        return DispatchrService.put(`/quicksite/${locationId}/media`, payload)
    }

    updateColor(
        locationId: string,
        payload: {
            userUid: string
            design: {
                primaryColor: HexColor
                secondaryColor: HexColor
            }
        }
    ) {
        return this.put(`/quicksite/${locationId}/settings`, payload)
    }

    //Exemple decorator mockServer
    @mockServer("/quicksite-mock/quicksite/1/test")
    /*
     * en utilisant cette fonction on peut mocker la réponse de la requête et avoir directement comme API BASE PATH celui du mock serveur + l"url en paramètre du decorator
     */
    testFunction() {
        return this.get("/quicksite/test")
    }
}

export default new QuicksiteService()
