import Icon from "@/components/base/Icon"
import CustomTooltip from "@/components/base/Tooltip"
import useFormater from "@/hooks/useFormater"
import { modalStyle } from "@/pages/users/styles"
import { withSxProp } from "@/utils"
import {
    Box,
    Button,
    Checkbox,
    CircularProgress,
    FormControlLabel,
    IconButton,
    Modal,
    Paper,
    Stack,
    Typography,
} from "@mui/material"
import { useTranslation } from "react-i18next"
import useStyles from "./styles"

interface PropsModal {
    open: boolean
    onClose: () => void
    listReviews: any
    setOpen: (value: boolean) => void
    handleSelect: (value: string, checked: boolean) => void
    addSuggestDefault: () => void
    loading: boolean
    disable: boolean
}

export const SuggestModel = ({
    open,
    onClose,
    setOpen,
    listReviews,
    loading,
    disable,
    handleSelect,
    addSuggestDefault,
}: PropsModal) => {
    const { t } = useTranslation()
    const { classes } = useStyles()
    const { parseMentionPreferLang, translateString } = useFormater()
    return (
        <Modal
            open={open}
            onClose={onClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-remove"
            disableRestoreFocus
        >
            <Paper
                elevation={6}
                className={classes.blocSuggestion}
                sx={withSxProp(modalStyle)}
                data-testid="modal-content"
            >
                <Box className="title-wrapper">
                    <Typography id="modal-modal-title" variant="h2">
                        {t("CAMPAIGN.MODEL.SUGGEST_MODEL")}
                    </Typography>
                    <CustomTooltip title={t("USERS.CLOSE")}>
                        <IconButton onClick={onClose}>
                            <Icon name="x" />
                        </IconButton>
                    </CustomTooltip>
                </Box>
                <Box className="notification">
                    <Typography component="span">{t("CAMPAIGN.MODEL.SUGGEST_INFOS")}</Typography>
                </Box>
                <Paper elevation={2} data-testid="suggest-fr">
                    {listReviews && (
                        <FormControlLabel
                            className="bloc-checkbox"
                            control={
                                <Checkbox
                                    className="checkbox"
                                    onChange={(event) => handleSelect("FR", event.target.checked)}
                                />
                            }
                            label={
                                <Stack>
                                    <Typography className="text-black">{t("CAMPAIGN.MODEL.FRENCH")}</Typography>
                                    <div
                                        className="ellipse-three-line mention-blue"
                                        dangerouslySetInnerHTML={{
                                            __html: parseMentionPreferLang(
                                                translateString("CAMPAIGN.MODEL.TEMPLATE_SHORT", "fr-FR")
                                            ),
                                        }}
                                    />
                                </Stack>
                            }
                        />
                    )}
                </Paper>
                <Paper elevation={2} data-testid="suggest-en">
                    {listReviews && (
                        <Stack flexDirection={"row"} alignItems={"start"} width="100%" gap={1}>
                            <FormControlLabel
                                className="bloc-checkbox"
                                control={
                                    <Checkbox
                                        className="checkbox"
                                        onChange={(event) => handleSelect("EN", event.target.checked)}
                                    />
                                }
                                label={
                                    <Stack>
                                        <Typography className="text-black">{t("CAMPAIGN.MODEL.ENGLISH")}</Typography>
                                        <div
                                            className="ellipse-three-line mention-blue"
                                            dangerouslySetInnerHTML={{
                                                __html: parseMentionPreferLang(
                                                    translateString("CAMPAIGN.MODEL.TEMPLATE_SHORT", "en")
                                                ),
                                            }}
                                        />
                                    </Stack>
                                }
                            />
                        </Stack>
                    )}
                </Paper>
                <Box className="action-wrapper">
                    <Button disabled={!disable || loading} onClick={() => setOpen(true)} data-testid="create-new-model">
                        {t("CAMPAIGN.MODEL.CREATE_NEW_MODEL")}
                    </Button>
                    <Button
                        className="btn-remove"
                        onClick={() => addSuggestDefault()}
                        variant="contained"
                        disabled={disable}
                        data-testid="add-suggest"
                    >
                        {loading && <CircularProgress size={16} sx={{ marginRight: "8px" }} />}
                        {t("CAMPAIGN.MODEL.ADD")}
                    </Button>
                </Box>
            </Paper>
        </Modal>
    )
}
