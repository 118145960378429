import { ResellerApi } from "@/api"
import useBusinessLogic from "@/hooks/businesses/useBusinessLogic"
import useLocation from "@/hooks/useLocation"
import useLocationStore from "@/store/location"
import useBusinessServicesStore from "@/store/overview/services"
import UiPageSessionStore from "@/store/ui-page-session"
import { Box, Paper } from "@mui/material"
import { useCallback, useEffect, useMemo } from "react"
import { useTranslation } from "react-i18next"
import BoxChip from "../../box-chip"
import OverviewTitle from "../title"

const OverviewServices = ({ servicesRef, isLoading, data }) => {
    const { t } = useTranslation()
    const { navigateTo } = useBusinessLogic()
    const { services, updateServices } = useBusinessServicesStore()
    const { location } = useLocationStore()
    const { refreshItems } = UiPageSessionStore()
    const { serviceTypeIdsMap } = useLocation()
    const servicesData = useMemo(() => {
        let list = data?.services?.sections || services.sections
        if (list?.length > 0) {
            list = list
                .map((section) => {
                    return section?.items?.map((item) => serviceTypeIdsMap?.[item?.serviceTypeId] || item?.label)
                })
                ?.flat()
                ?.sort((a, b) => (a?.toLowerCase() > b?.toLowerCase() ? 1 : -1))
            return list.map((item) => {
                return {
                    label: item,
                    id: item,
                }
            })
        }
        return []
    }, [data?.services, services?.sections])

    const haveItemsServices = useMemo(() => {
        if (services?.sections?.length > 0) {
            for (const section of services?.sections || []) {
                if (section?.items?.length > 0) {
                    return true
                }
            }
        }
        return false
    }, [services?.sections])

    const fetchServices = useCallback(
        async (uid = "") => {
            const response: any = await ResellerApi.fetchServices({ uid })
            if (response) {
                const services = response?.data?.[0] || {}
                updateServices({ services })
            }
        },
        [updateServices]
    )

    useEffect(() => {
        if (location?.id && (refreshItems || !data?.services)) {
            fetchServices(location?.id)
        }
    }, [location?.id && refreshItems && data?.services])

    return (
        <Paper
            elevation={2}
            className="clickable services"
            id="services"
            data-testid="services"
            ref={servicesRef}
            onClick={() => !isLoading && navigateTo("edit-service")}
        >
            <OverviewTitle
                title={t("BUSINESSES.SERVICES")}
                // icon="coinsHand"
                isSecondTitle
                tooltipText={t("BUSINESSES.EDIT_SERVICES.MAIN_TOOLTIP")}
            />
            {servicesData.length > 0 && haveItemsServices && !isLoading && (
                <Box className="chip-wrapper" data-testid="chip-wrapper">
                    <BoxChip items={servicesData} type="multiple" isAction={false} dataTestid="services-chip" />
                </Box>
            )}
        </Paper>
    )
}

export default OverviewServices
