import { DatePicker, DatePickerProps } from "@mui/x-date-pickers"
import { SxProps, Typography } from "@mui/material"
import dayjs, { Dayjs } from "dayjs"
import { forwardRef } from "react"
import { AdapterDayjs } from "@mui/x-date-pickers-pro/AdapterDayjs"
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider"
import { enUS, frFR } from "@mui/x-date-pickers/locales"
import "dayjs/locale/en-gb"
import "dayjs/locale/fr"
import { defaultSx } from "./styles"
import { useTranslation } from "react-i18next"

interface BaseDatePickerProps {
    sx?: SxProps
    label?: string
    preferredLanguage?: string
    register?: any
    readOnly?: boolean
    shrink?: boolean
    noBottomBorder?: boolean
    showLabelStar?: boolean
    error?: boolean
}

export const DatePickerCustom = forwardRef(
    (props: BaseDatePickerProps & DatePickerProps<Dayjs>, ref: React.Ref<any>) => {
        const {
            sx,
            value,
            onChange,
            disabled,
            defaultValue,
            register,
            readOnly = false,
            shrink = false,
            showLabelStar = false,
            noBottomBorder = true,
            error,
            preferredLanguage,
            ...rest
        } = props

        const { t } = useTranslation()

        return (
            <LocalizationProvider
                dateAdapter={AdapterDayjs}
                localeText={
                    preferredLanguage === "en"
                        ? enUS.components.MuiLocalizationProvider.defaultProps.localeText
                        : frFR.components.MuiLocalizationProvider.defaultProps.localeText
                }
                adapterLocale={preferredLanguage === "en" ? "en-gb" : "fr"}
            >
                <DatePicker
                    label={props.label}
                    slotProps={{
                        actionBar: {
                            actions: ["today"],
                        },
                        field: {
                            readOnly: readOnly,
                            disabled: disabled,
                        },
                        textField: {
                            variant: "filled",
                            InputLabelProps: { shrink: shrink },
                            helperText: error && (
                                <Typography day-testid="error" component={"span"} variant={"caption"} className="error">
                                    {t(`BUSINESSES.SCHEDULE.DATE_ERROR`)}
                                </Typography>
                            ),
                            error: error,
                            "data-testid": "date-picker",
                        },
                    }}
                    sx={{
                        ...defaultSx({ noBottomBorder: error ? false : noBottomBorder, showLabelStar, error }),
                        ...sx,
                    }}
                    disableOpenPicker={disabled}
                    defaultValue={defaultValue ? dayjs(defaultValue) : null}
                    value={value ? dayjs(value) : null}
                    format={preferredLanguage === "en" ? "DD MMM YYYY" : "DD MMMM YYYY"}
                    onChange={onChange}
                    ref={ref}
                    {...(register || {})}
                    {...rest}
                />
            </LocalizationProvider>
        )
    }
)
