import React, { useEffect, useState } from "react"
import { Box, Grid, Card, Checkbox } from "@mui/material"
import CardMedia from "@/components/base/card-media"

const checkboxStyles = {
    position: "absolute",
    top: 8,
    right: 8,
    color: "white",
    width: 24,
    height: 24,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    fontSize: "0.8rem",
    fontWeight: "bold",
}

type Media = {
    thumbnailUrl: string
    url: string
}

type MediaSelectionProps = {
    maxImages: number
    medias: Media[]
    onSelectionChange?: (selected: number[]) => void
    defaultSelected?: number[]
}

const MediaSelection: React.FC<MediaSelectionProps> = ({ maxImages, medias, onSelectionChange, defaultSelected }) => {
    const [selectedMedia, setSelectedMedia] = useState<number[]>(defaultSelected || [])

    const handleSelect = (index: number) => {
        if (maxImages === 1) {
            setSelectedMedia((previousSelection) => {
                if (previousSelection.includes(index)) {
                    return []
                }
                return [index]
            })
            return
        }
        if (selectedMedia.length >= maxImages && !selectedMedia.includes(index)) {
            return
        }
        setSelectedMedia((previousSelection) => {
            const isSelected = previousSelection.includes(index)
            if (!isSelected) {
                if (previousSelection.length < maxImages) {
                    return [...previousSelection, index]
                }
            } else {
                return previousSelection.filter((i) => i !== index)
            }
            return previousSelection
        })
    }

    useEffect(() => {
        setSelectedMedia(defaultSelected || [])
    }, [defaultSelected])

    useEffect(() => {
        onSelectionChange?.(selectedMedia)
    }, [onSelectionChange, selectedMedia])

    return (
        <Grid container spacing={2}>
            {medias.map((image, index) => {
                const isSelected = selectedMedia.includes(index)
                return (
                    <Grid item key={index}>
                        <Card
                            sx={{
                                position: "relative",
                                cursor: "pointer",
                                padding: 0,
                            }}
                            onClick={() => handleSelect(index)}
                        >
                            <Box
                                sx={{
                                    ":after": {
                                        content: '""',
                                        display: "block",
                                        position: "absolute",
                                        top: 0,
                                        left: 0,
                                        width: "100%",
                                        height: "100%",
                                        backgroundColor: isSelected ? "rgba(255, 255, 255, 0.72)" : "transparent",
                                        border: isSelected && "0.75px solid",
                                        borderColor: isSelected ? "primary.main" : "transparent",
                                        borderRadius: 2,
                                    },
                                }}
                            >
                                <CardMedia image={image.thumbnailUrl} alt={`media-${index}`} />
                            </Box>

                            {isSelected && maxImages > 1 ? (
                                <Box
                                    sx={{
                                        ...checkboxStyles,
                                        borderRadius: "10%",
                                        backgroundColor: "primary.main",
                                    }}
                                >
                                    {selectedMedia.indexOf(index) + 1}
                                </Box>
                            ) : (
                                <Checkbox
                                    sx={{
                                        ...checkboxStyles,
                                        "& .MuiSvgIcon-root": {
                                            color: "white",
                                        },
                                    }}
                                    checked={isSelected}
                                />
                            )}
                        </Card>
                    </Grid>
                )
            })}
        </Grid>
    )
}

export default MediaSelection
