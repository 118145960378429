import { ResellerApi } from "@/api"
import useLocation from "@/hooks/useLocation"
import useNotification from "@/hooks/useNotification"
import useLocationStore from "@/store/location"
import useBusinessCategoriesStore from "@/store/overview/categories"
import useBusinessServicesStore from "@/store/overview/services"
import UiStore from "@/store/ui"
import UiPageSessionStore from "@/store/ui-page-session"
import { useCallback, useEffect, useMemo, useState } from "react"
import { useTranslation } from "react-i18next"
import useBusinessLogic from "../useBusinessLogic"

interface ServiceTypes {
    displayName: string
    serviceTypeId?: string
    categoryId: string
}

const useLogic = (handlePrevious: () => void) => {
    const { navigateTo } = useBusinessLogic()
    const { t } = useTranslation()
    const { preferredLanguage } = UiStore()
    const { services, updateServices } = useBusinessServicesStore()
    const { location } = useLocationStore()
    const { primaryCategory, secondaryCategory, updateCategories, getInitialState, categories } =
        useBusinessCategoriesStore()
    const { fetchPrimaryCategory, fetchSecondaryCategory, serviceTypeIdsMap, setCategories } = useLocation()
    const [primaryServices, setPrimaryServices] = useState<ServiceTypes[]>([])
    const [secondaryServices, setSecondaryServices] = useState<ServiceTypes[]>([])
    const { showPage, previousItem, updatePreviousItem, updateState: setUiPageSession } = UiPageSessionStore()
    const { notif } = useNotification()
    const [data, setData] = useState<any>({})
    const [serviceIds, setServiceIds] = useState<string[]>([])
    const [formValid, setFormValid] = useState<boolean>(true)
    const [loading, setLoading] = useState<boolean>(false)

    const sections = useMemo(() => {
        if (previousItem?.sections) {
            return previousItem?.sections || []
        }
        return services.sections || []
    }, [services, previousItem?.sections])

    const primaryCategories = useMemo(() => {
        const items = sections
            ?.filter((section) => {
                return section.categoryId === primaryCategory.id
            })
            .map((section) => {
                section.items = section?.items?.map((item) => {
                    if (item.serviceTypeId) {
                        const label = serviceTypeIdsMap?.[item.serviceTypeId] || item.label
                        item.label = label
                    }
                    return item
                })
                return section
            })

        return {
            categoryId: primaryCategory?.id,
            label: primaryCategory.label,
            items,
        }
    }, [primaryCategory, serviceTypeIdsMap, sections])

    const secondaryCategories = useMemo(() => {
        const data = secondaryCategory?.map((category) => {
            const items = sections
                ?.filter((section) => {
                    return section.categoryId === category.id
                })
                .map((section) => {
                    section.items = section?.items?.map((item) => {
                        if (item.serviceTypeId) {
                            const label = serviceTypeIdsMap?.[item.serviceTypeId] || item.label
                            item.label = label
                        }
                        return item
                    })
                    return section
                })
            return {
                categoryId: category?.id,
                label: category.label,
                items,
            }
        })
        return data
    }, [secondaryCategory, serviceTypeIdsMap, sections])

    const currency = useMemo(() => {
        return {
            code: location?.currency?.code,
            label: location?.currency?.label?.split(" ")?.[0] || "",
        }
    }, [location])

    const primaryServicesFiltered = useMemo(() => {
        return primaryServices.filter((item) => !serviceIds.includes(item.serviceTypeId))
    }, [primaryServices, serviceIds])

    const secondaryServicesFiltered = useMemo(() => {
        return secondaryServices.filter((item) => !serviceIds.includes(item.serviceTypeId))
    }, [secondaryServices, serviceIds])

    const handleFormChange = useCallback(
        (type, formData, category?) => {
            if (category?.categoryId) {
                setData((previous) => {
                    return {
                        ...previous,
                        [category.categoryId]: {
                            ...category,
                            items: formData,
                        },
                    }
                })
            }
        },
        [setData, data]
    )

    const handleSubmit = useCallback(
        async (event) => {
            event.preventDefault()
            setLoading(true)
            const sections = Object.values(data || {})?.map((section: any) => {
                return {
                    ...section,
                    items: section.items.map((item) => {
                        const itemForm = {
                            label: item.label,
                            description: item.description,
                            price: item.price,
                            state: item.state,
                        }
                        if (item.priceType === "free") {
                            Object.assign(itemForm, { free: true })
                        }
                        if (item.serviceTypeId) {
                            Object.assign(itemForm, { serviceTypeId: item.serviceTypeId })
                        }
                        return itemForm
                    }),
                }
            })
            if (services?.id) {
                ResellerApi.updateServices(services.id, {
                    ...services,
                    sections,
                })
                    .then((res) => {
                        setUiPageSession({ refreshItems: true })
                        notif({ message: t("BUSINESSES.EDIT_SUCCESS"), type: "SUCCESS" })
                        navigateTo("detail")
                        updateServices({ services: res })
                        updatePreviousItem({
                            sections: null,
                        })
                        if (typeof handlePrevious === "function") {
                            handlePrevious()
                        }
                    })
                    .finally(() => {
                        setLoading(false)
                    })
            } else {
                ResellerApi.createServices({
                    location: location.id,
                    ...services,
                    sections,
                })
                    .then((res) => {
                        setUiPageSession({ refreshItems: true })
                        notif({ message: t("BUSINESSES.EDIT_SUCCESS"), type: "SUCCESS" })
                        navigateTo("detail")
                        updateServices({ services: res })
                        updatePreviousItem({
                            sections: null,
                        })
                        if (typeof handlePrevious === "function") {
                            handlePrevious()
                        }
                    })
                    .finally(() => {
                        setLoading(false)
                    })
            }
        },
        [data, services, setUiPageSession, notif, t, navigateTo, previousItem]
    )

    const handlePreviousButton = useCallback(() => {
        updatePreviousItem({
            sections: Object.values(data),
        })
        navigateTo("detail")
        if (typeof handlePrevious === "function") {
            handlePrevious()
        }
    }, [data, navigateTo, updatePreviousItem])

    const handleCancelButton = useCallback(() => {
        navigateTo("detail")
        setLoading(false)
        updatePreviousItem({
            sections: null,
        })
        if (typeof handlePrevious === "function") {
            handlePrevious()
        }
    }, [navigateTo, updatePreviousItem])

    useEffect(() => {
        if (data) {
            let breakFlag = false
            for (const section of Object.values(data) as any) {
                if (section.items?.length > 0) {
                    for (const item of section.items) {
                        if ((!item.serviceTypeId && !item.label) || (item.priceType === "fixed" && !item.price)) {
                            setFormValid(false)
                            breakFlag = true
                            break
                        }
                    }
                }
                if (breakFlag) {
                    break
                }
            }
            if (!breakFlag) {
                setFormValid(true)
            }
        }
    }, [data])

    useEffect(() => {
        if (showPage === "edit-service" && categories) {
            setCategories(categories, { isDraft: false })
        }
    }, [categories, setCategories, showPage])

    useEffect(() => {
        if (showPage === "edit-service" && primaryCategories) {
            if (primaryCategories?.categoryId) {
                fetchPrimaryCategory(primaryCategories?.categoryId).then((response) => {
                    const serviceTypes = (response || {})?.serviceTypes || []
                    setPrimaryServices(
                        serviceTypes?.map((service) => ({
                            ...service,
                            categoryId: primaryCategories?.categoryId,
                        }))
                    )
                })
            }
        }
    }, [fetchPrimaryCategory, primaryCategories, showPage, preferredLanguage])

    useEffect(() => {
        if (showPage === "edit-service" && secondaryCategories) {
            const items = secondaryCategories?.map((item) => item?.categoryId)
            if (items?.length > 0) {
                fetchSecondaryCategory(items).then((response) => {
                    const serviceTypes =
                        (response || [])
                            .map((category) =>
                                category?.serviceTypes?.map((service) => ({ ...service, categoryId: category.id }))
                            )
                            ?.flat() || []
                    setSecondaryServices(serviceTypes)
                })
            }
        }
    }, [fetchSecondaryCategory, secondaryCategories, showPage, preferredLanguage])

    useEffect(() => {
        if (showPage === "edit-service" && sections) {
            const ids = []
            sections?.forEach((section) => {
                section?.items?.forEach((section) => {
                    if (section.serviceTypeId) {
                        ids.push(section.serviceTypeId)
                    }
                })
            })
            setServiceIds(ids)
        }
    }, [sections, showPage])

    return {
        primaryCategories,
        currency,
        primaryServicesFiltered,
        serviceIds,
        secondaryCategories,
        secondaryServicesFiltered,
        loading,
        formValid,
        t,
        handleFormChange,
        handleSubmit,
        setServiceIds,
        handlePreviousButton,
        handleCancelButton,
    }
}

export default useLogic
