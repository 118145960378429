import { LocationCard } from "@/components/features/web-page/location-card"
import { PaginationSearchLayout } from "@/components/widget/pagination-search-layout"
import { LocationCardSkeleton } from "@/components/widget/skeleton/location-card-skeleton"
import useResponsive from "@/hooks/use-responsive"
import UserSessionStore from "@/store/user-session"
import { Box } from "@mui/material"
import { useEffect } from "react"
import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router-dom"
import useLogic from "./useLogic"

const WebPageLanding: React.FC = () => {
    const { isMobile } = useResponsive()
    const { t } = useTranslation()
    const navigate = useNavigate()
    const { page, isSingleLocation, singleLocationId } = UserSessionStore()

    const { metadata, isLoading, locations, filterValue, handleChangePage, setFilterValue } = useLogic()

    useEffect(() => {
        if (!page?.quicksite) {
            navigate("/reputation")
        }
    }, [navigate, page?.quicksite])

    useEffect(() => {
        if (isSingleLocation && !!singleLocationId) {
            navigate(`/web-page/${singleLocationId}`)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [singleLocationId, singleLocationId])

    return (
        <PaginationSearchLayout
            layoutTitle={t("WEB_PAGE.MENU")}
            titleIcon="webPage"
            metadata={metadata}
            handleChangePage={handleChangePage}
            loading={isLoading}
            searchValue={filterValue}
            onSearch={setFilterValue}
        >
            <Box sx={{ flexGrow: 1 }}>
                <div
                    style={{
                        display: "grid",
                        gap: 16,
                        gridTemplateColumns: isMobile ? "1fr" : "repeat(2, 1fr)",
                    }}
                >
                    {isLoading
                        ? [...Array(8)].map((_, index) => <LocationCardSkeleton key={index} />)
                        : locations.map((location) => (
                              <LocationCard
                                  key={location.id}
                                  location={location}
                                  onClick={() => navigate(`/web-page/${location.id}`)}
                                  isMobile={isMobile}
                              />
                          ))}
                </div>
            </Box>
        </PaginationSearchLayout>
    )
}

export default WebPageLanding
