import { useCallback, useState } from "react"

const useSelectionMedia = () => {
    const [submitingSelection, setSubmitingSelection] = useState(false)
    const [selectedMedia, setSelectedMedia] = useState<
        {
            thumbnailUrl: string
            url: string
            id: string
        }[]
    >([])

    const handleSelection = useCallback((medias: { thumbnailUrl: string; url: string; id: string }[]) => {
        setSelectedMedia(medias)
    }, [])

    return { selectedMedia, handleSelection, submitingSelection, setSubmitingSelection }
}

export default useSelectionMedia
