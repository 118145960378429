import { CompanyApi, LocationsApi } from "@/api"
import useNotification from "@/hooks/useNotification"
import useUiPageSession from "@/hooks/useUiPageSession"
import useLocationStore from "@/store/location"
import useUserSessionStore from "@/store/user-session"
import { useCallback, useEffect, useReducer, useState } from "react"
import { useTranslation } from "react-i18next"

interface Metadata {
    page_number: number
    page_size: number
    total_count: number
}

type MetadataAction =
    | { type: "SET_PAGE"; page: number }
    | { type: "SET_TOTAL"; total: number }
    | { type: "SET_SIZE"; size: number }

const metadataReducer = (state: Metadata, action: MetadataAction): Metadata => {
    switch (action.type) {
        case "SET_PAGE":
            return { ...state, page_number: action.page }
        case "SET_TOTAL":
            return { ...state, total_count: action.total }
        case "SET_SIZE":
            return { ...state, page_size: action.size }
        default:
            return state
    }
}

const useLogic = () => {
    const { profile } = useUserSessionStore()
    const { isLoading: isLoadingBusiness, businesses } = useLocationStore()
    const [isLoading, setIsLoading] = useState(true)
    const { t } = useTranslation()
    const { notif } = useNotification()
    const [metadata, dispatchMetadata] = useReducer(metadataReducer, {
        page_number: 0,
        page_size: 100,
        total_count: 0,
    })
    const [companies, setCompanies] = useState([])

    // const { items: companies } = useCompanies()

    const [filterValue, setFilterValue] = useState("")
    const [locations, setLocations] = useState([])

    useUiPageSession({
        title: "WEB_PAGE.MENU",
        page: "web-page",
    })
    const fetchCompanies = useCallback(async () => {
        if (profile?.uid) {
            const response = await CompanyApi.fetchListsCompanies({
                page_number: 1,
                page_size: 1000,
                user_uid: profile.uid,
            })
            if (response?.data) {
                setCompanies(response.data)
            }
        }
    }, [profile.uid])
    const refreshLocationList = useCallback(
        async ({ page_number = 0, search = "", missing_info_required = false, completion = null }) => {
            setIsLoading(true)

            try {
                const response = await LocationsApi.fetchLocations({
                    page_number: page_number + 1,
                    page_size: metadata.page_size,
                    user_uid: profile.uid,
                    search,
                    missing_info_required,
                    completion,
                    fields: ["name", "address", "logo", "locationState", "companyId", "quicksiteInfo"],
                    quicksite: true,
                })

                if (response?.error) {
                    throw new Error(t("SYSTEM_ERROR.INVALID_REQUEST"))
                }

                if (response?.data) {
                    const filteredLocations = response.data.filter((location) => {
                        const company = companies.find((company) => company.uid === location.companyId)

                        return company
                    })

                    setLocations(filteredLocations)

                    dispatchMetadata({ type: "SET_PAGE", page: page_number })
                    dispatchMetadata({ type: "SET_TOTAL", total: response.metadata?._count || 0 })
                    dispatchMetadata({ type: "SET_SIZE", size: response.metadata?._limit || metadata.page_size })
                }
            } catch (error: any) {
                notif({ message: error.message, type: "ERROR" })
            } finally {
                setIsLoading(false)
            }
        },
        [profile.uid, metadata.page_size, companies, notif, t]
    )

    const handleChangePage = useCallback(
        (_: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
            refreshLocationList({ page_number: newPage, search: filterValue })
        },
        [refreshLocationList, filterValue]
    )

    useEffect(() => {
        if (!isLoadingBusiness && companies.length > 0) {
            refreshLocationList({ search: filterValue })
        }
    }, [filterValue, businesses, isLoadingBusiness, companies, refreshLocationList])

    useEffect(() => {
        fetchCompanies()
    }, [fetchCompanies])

    return {
        metadata,
        isLoading: isLoadingBusiness || isLoading,
        locations,
        filterValue,
        handleChangePage,
        setFilterValue,
    }
}

export default useLogic
