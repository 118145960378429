import { ResellerApi } from "@/api"
import useLocation from "@/hooks/useLocation"
import useNotification from "@/hooks/useNotification"
import useBusinessCategoriesStore from "@/store/overview/categories"
import UiPageSessionStore from "@/store/ui-page-session"
import { zodResolver } from "@hookform/resolvers/zod"
import cloneDeep from "lodash.clonedeep"
import { useCallback, useEffect, useMemo, useState } from "react"
import { SubmitErrorHandler, SubmitHandler, useForm, useWatch } from "react-hook-form"
import { useTranslation } from "react-i18next"
import { z } from "zod"
import useBusinessLogic from "../useBusinessLogic"
interface CategoryValue {
    primaryCategory: string
    secondaryCategory: string[]
    facebookCategory?: string[]
}
const useLogic = (handleCustomPrevious: () => void) => {
    const { navigateTo } = useBusinessLogic()
    const { t } = useTranslation()
    const { location, fetchPrimaryCategory, fetchSecondaryCategory, setCategories } = useLocation()
    const { categories, categoriesDraft, updateCategories } = useBusinessCategoriesStore()

    const [defaultValues, setDefaultValues] = useState({
        primaryCategory: {
            title: "",
            value: "",
        },
        secondaryCategory: [],
        facebookCategory: [],
    })
    const { showPage, updateState: setUiPageSession } = UiPageSessionStore()
    const [loading, setLoading] = useState(false)
    const { notif } = useNotification()
    const onErrors: SubmitErrorHandler<any> = async (errors) => {
        console.log(errors)
    }
    const schema = useMemo(() => {
        return z.object({
            primaryCategory: z.string(),
            secondaryCategory: z.string().array().optional(),
            facebookCategory: z.string().array().optional(),
        })
    }, [])

    const { trigger, setValue, handleSubmit, getValues, control } = useForm<CategoryValue>({
        defaultValues: {
            primaryCategory: "",
            secondaryCategory: [],
            facebookCategory: [],
        },
        resolver: zodResolver(schema),
        mode: "onTouched",
    })

    const primaryValue = useWatch({ control, name: "primaryCategory" })
    const secondaryValue = useWatch({ control, name: "secondaryCategory" })
    const facebookValue = useWatch({ control, name: "facebookCategory" })

    const isFormValid = useMemo(() => {
        if (location?.meta?.facebook_id) {
            return facebookValue?.length > 0
        }
        return true
    }, [location?.meta, facebookValue])

    const defaultPrimary = useMemo(() => {
        return primaryValue
    }, [primaryValue])

    const defaultSecondary = useMemo(() => {
        return secondaryValue
    }, [secondaryValue])

    const defaultFacebook = useMemo(() => {
        return facebookValue
    }, [facebookValue])

    const setValues = useCallback(
        ({ primaryCategory, secondaryCategory, facebookCategory }) => {
            if (primaryCategory) {
                setValue("primaryCategory", primaryCategory.value || "")
                trigger(["primaryCategory"])
            }
            if (secondaryCategory?.length) {
                setValue(
                    "secondaryCategory",
                    secondaryCategory.map((category) => category.value)
                )
                trigger(["secondaryCategory"])
            }
            if (facebookCategory?.length) {
                setValue(
                    "facebookCategory",
                    facebookCategory.map((category) => category.value)
                )
                trigger(["facebookCategory"])
            }
        },
        [setValue, trigger]
    )

    const onSubmit: SubmitHandler<any> = async (data: CategoryValue) => {
        setLoading(true)
        const body = { ...location }
        body["categories"].primary = data.primaryCategory
        body["categories"].secondary = data.secondaryCategory
        if (data?.facebookCategory && location?.meta?.facebook_id) {
            body["categories"].facebook = data.facebookCategory
        }
        const response = await ResellerApi.updateLocation({ ...body })
        if (response?.error) {
            notif({ message: t("SYSTEM_ERROR.INVALID_REQUEST"), type: "ERROR" })
        } else {
            notif({ message: t("BUSINESSES.EDIT_SUCCESS"), type: "SUCCESS" })
            setDefaultValues({
                primaryCategory: { title: "", value: "" },
                secondaryCategory: [],
                facebookCategory: [],
            })
            navigateTo("detail")
            setUiPageSession({ refreshItems: true })
            setCategories(response.categories, { isDraft: false })
            if (typeof handleCustomPrevious === "function") {
                handleCustomPrevious()
            }
        }
        setLoading(false)
    }

    const handlePrevious = useCallback(() => {
        setCategories({ primary: primaryValue, secondary: secondaryValue, facebook: facebookValue }, { isDraft: true })
        navigateTo("detail")
        if (typeof handleCustomPrevious === "function") {
            handleCustomPrevious()
        }
    }, [primaryValue, secondaryValue, facebookValue])

    const handleCancelButton = useCallback(() => {
        setDefaultValues({
            primaryCategory: { title: "", value: "" },
            secondaryCategory: [],
            facebookCategory: [],
        })
        updateCategories({ categoriesDraft: null })
        navigateTo("detail")
        if (typeof handleCustomPrevious === "function") {
            handleCustomPrevious()
        }
    }, [])

    const fetchData = useCallback(async () => {
        const data = {
            primaryCategory: { title: "", value: "" },
            secondaryCategory: [],
            facebookCategory: [],
        }
        const primary = categoriesDraft ? categoriesDraft.primary : categories?.primary
        const secondary = categoriesDraft ? categoriesDraft.secondary : categories?.secondary
        const facebook = categoriesDraft ? categoriesDraft.facebook : categories?.facebook

        if (primary) {
            const primaryResponse = await fetchPrimaryCategory(primary)

            if (primaryResponse) {
                data.primaryCategory = {
                    title: primaryResponse.label,
                    value: primaryResponse.id,
                }
            }
        }
        if (secondary?.length) {
            const secondaryResponse = await fetchSecondaryCategory(secondary)

            if (secondaryResponse?.length) {
                const mappedData = secondaryResponse
                    ?.filter((item) => !item?.meta?.facebook_id)
                    .map((item) => ({
                        title: item.label,
                        value: item.id,
                    }))
                data.secondaryCategory = cloneDeep(mappedData)
            }
        }
        if (facebook?.length) {
            const facebookResponse = await fetchSecondaryCategory(facebook)
            if (facebookResponse?.length) {
                const mappedData = facebookResponse
                    ?.filter((item) => item?.meta?.facebook_id)
                    .map((item) => ({
                        title: item.label,
                        value: item.id,
                    }))
                data.facebookCategory = cloneDeep(mappedData)
            }
        }
        setDefaultValues({
            primaryCategory: data?.primaryCategory,
            secondaryCategory: data?.secondaryCategory,
            facebookCategory: data?.facebookCategory,
        })
        setValues(data)
    }, [fetchPrimaryCategory, fetchSecondaryCategory, categories, categoriesDraft, setValues])

    useEffect(() => {
        if (showPage === "edit-category" && location?.id) {
            setDefaultValues({
                primaryCategory: { title: "", value: "" },
                secondaryCategory: [],
                facebookCategory: [],
            })
            fetchData()
        }
    }, [showPage, location?.id, fetchData])
    return {
        loading,
        defaultValues,
        primaryValue,
        defaultPrimary,
        defaultSecondary,
        defaultFacebook,
        location,
        isFormValid,
        navigateTo,
        handleSubmit,
        onSubmit,
        onErrors,
        setValues,
        getValues,
        setValue,
        handlePrevious,
        handleCancelButton,
        setDefaultValues,
    }
}

export default useLogic
