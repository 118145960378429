import { ThemeOptions } from "@mui/material"

declare module "@mui/material/styles" {
    interface Palette {
        orange: Palette["primary"]
    }

    interface PaletteOptions {
        orange?: PaletteOptions["primary"]
    }
}

const lightTheme: ThemeOptions = {
    palette: {
        orange: {
            main: "#FF881B",
        },
        primary: {
            main: "#0180FF",
            dark: "#0059B2",
            light: "rgba(1, 128, 255, 0.16)",
        },
        secondary: {
            main: "#00041F",
        },
        success: {
            main: "#03CC6C",
        },
        error: {
            main: "#F46259",
            light: "rgba(244, 98, 89, 0.12)",
        },
        warning: {
            main: "#FFC431",
        },
        action: {
            active: "#68738D",
            disabled: "rgba(104, 115, 141, 0.42)",
        },
        info: {
            main: "#2EAFF7",
        },
        background: {
            default: "rgb(244, 245, 250)",
        },
        divider: "rgba(91, 125, 216, 0.16)",
        text: {
            disabled: "rgba(104, 115, 141, 0.64)",
            primary: "#00041F",
            secondary: "#68738D",
        },
        common: {
            white: "rgb(255, 255, 255)",
            black: "rgb(0, 0, 0)",
        },
        grey: {
            50: "#F4F5FA",
        },
    },
    components: {
        MuiBackdrop: {
            styleOverrides: {
                root: {
                    backgroundColor: "rgba(53, 56, 75, 0.42)",
                },
            },
        },
        MuiTypography: {
            styleOverrides: {
                root: {
                    "&.primary": {
                        color: "#0180FF",
                    },
                    "&.success": {
                        color: "#03CC6C",
                    },
                    "&.error": {
                        color: "#F46259",
                    },
                    "&.MuiTypography-h1": {
                        fontWeight: 500,
                    },
                    "&.MuiTypography-h2": {
                        fontSize: "24px",
                        fontWeight: 500,
                        lineHeight: "32px",
                        letterSpacing: "0.15000000596046448px",
                    },
                    "&.MuiTypography-h3": {
                        fontSize: "20px",
                        fontWeight: 500,
                        lineHeight: "32px",
                        letterSpacing: "0.15000000596046448px",
                    },
                    color: "#00041F",
                },
            },
        },
        MuiDrawer: {
            styleOverrides: {
                paper: {
                    "@media (max-width: 767px)": {
                        padding: 0,
                    },
                },
            },
        },
        MuiPaper: {
            styleOverrides: {
                root: {
                    padding: 16,
                    display: "flex",
                    flexDirection: "column",
                    boxShadow: "rgba(8, 0, 31, 0.18) 0px 1px 2px -1px",
                    // boxShadow: "0px 1px 1px 0px rgba(0, 4, 31, 0.08), 0px 1px 4px 0px rgba(8, 0, 31, 0.12)",
                    boxSizing: "border-box",
                    height: "auto",
                    gap: 16,
                    borderRadius: 16,
                    "@media (max-width: 767px)": {
                        gap: 8,
                        padding: 8,
                    },
                },
                elevation1: {
                    boxShadow: "0px 1px 1px 0px #00041F14, 0px 1px 4px 0px #08001F1F",
                },
                elevation2: {
                    border: "2px solid var(--Divider, rgba(91, 125, 216, 0.16))",
                    boxShadow: "none",
                    display: "flex",
                    flexDirection: "column",
                    padding: 16,
                    gap: 16,
                    borderRadius: 8,
                    justifyContent: "start",
                    boxSizing: "border-box",
                    height: "auto",
                    "@media (max-width: 767px)": {
                        gap: 8,
                        padding: 8,
                    },
                },
                elevation6: {
                    boxShadow: "0px 6px 10px 0px rgba(0, 4, 31, 0.16), 0px 1px 18px 0px rgba(0, 4, 31, 0.16)",
                    "@media (max-width: 767px)": {
                        padding: 8,
                        gap: 16,
                    },
                },
                elevation8: {
                    boxShadow: "0px 8px 10px 1px rgba(0, 4, 31, 0.16), 0px 3px 14px 2px rgba(0, 4, 31, 0.16)",
                },
            },
        },
        MuiModal: {
            defaultProps: { disableRestoreFocus: true },
        },
        MuiPopover: {
            defaultProps: { disableRestoreFocus: true },
            styleOverrides: {
                root: {
                    "& .MuiModal-backdrop": {
                        background: "transparent",
                    },
                },
            },
        },
        MuiButton: {
            styleOverrides: {
                root: {
                    boxShadow: "0px 2px 2px 0px rgba(0, 4, 31, 0.08), 0px 1px 5px 0px rgba(0, 4, 31, 0.08)",
                    "&:disabled": {
                        color: "rgba(104, 115, 141, 0.42) !important",
                        backgroundColor: "rgba(104, 115, 141, 0.32)",
                        "& *": {
                            color: "rgba(104, 115, 141, 0.42) !important",
                        },
                    },
                    "&.MuiButton-containedSuccess *": {
                        color: "white",
                    },
                    "& .MuiTypography-root": {
                        color: "white",
                    },
                },
                outlined: {
                    "&:disabled": {
                        backgroundColor: "transparent",
                    },
                },
                text: {
                    "&:disabled": {
                        backgroundColor: "transparent",
                    },
                },
                containedPrimary: { backgroundColor: "#0180FF", "& *": { color: "white" } },
                textSecondary: {
                    backgroundColor: "rgba(1, 128, 255, 0.08)",
                    color: "#0180FF",
                    border: "1px solid transparent",
                    boxShadow: "none",
                    "&:hover": {
                        backgroundColor: "rgba(1, 128, 255, 0.08)",
                        border: "1px solid rgba(33, 150, 243, 0.50)",
                    },
                },
                containedSecondary: {
                    color: "#00041F",
                    fontWeight: "bold",
                    boxShadow: "none",
                    backgroundColor: "rgba(0, 4, 31, 0.04)",
                    border: "1px solid transparent",
                    "&:hover": {
                        borderRadius: "8px",
                        border: "1px solid rgba(0, 4, 31, 0.50)",
                        background: "rgba(0, 4, 31, 0.08)",
                        boxShadow: "none",
                    },
                },
                textPrimary: {
                    boxShadow: "none",
                },
            },
        },
        MuiFilledInput: {
            styleOverrides: {
                root: {
                    "&.Mui-disabled input": {
                        WebkitTextFillColor: "rgba(104, 115, 141, 0.64)",
                    },
                },
            },
        },
        MuiTableCell: {
            styleOverrides: {
                root: {
                    borderBottom: "1px solid #E4EAFA",
                },
            },
        },
        MuiCheckbox: {
            styleOverrides: {
                colorPrimary: {
                    "& svg": {
                        color: "#68738D",
                    },
                    "&.Mui-checked svg": {
                        color: "#0180FF",
                    },
                    "&.Mui-disabled svg": {
                        color: "rgba(104, 115, 141, 0.42)",
                    },
                },
            },
        },
        MuiFormGroup: {
            styleOverrides: {
                root: {
                    "& .MuiFormControlLabel-root": {
                        margin: 0,
                    },
                },
            },
        },
        MuiChip: {
            styleOverrides: {
                outlinedSecondary: {
                    border: "1px solid rgba(91, 125, 216, 0.16)",
                    padding: 4,
                    "& svg": {
                        color: "#0180FF",
                    },
                    "& .MuiChip-label": {
                        padding: "3px 6px",
                        color: "#00041F",
                        fontSize: 13,
                    },
                },
            },
        },
        MuiInputBase: {
            styleOverrides: {
                root: {
                    backgroundColor: "rgba(172, 182, 229, 0.16)",
                    "& input": {
                        "&::placeholder, &::-ms-input-placeholder": {
                            color: "#68738D",
                            opacity: 1,
                        },
                    },
                    "& fieldset": {
                        border: "none",
                        borderBottom: "1px solid #5B7DD81F",
                    },
                    "&:hover, &.Mui-focused": {
                        "& fieldset": {
                            borderBottom: "none",
                        },
                    },
                },
            },
        },
        MuiAlert: {
            styleOverrides: {
                standardInfo: {
                    boxShadow: "none",
                },
                standardError: {
                    backgroundColor: "#FDEDED",
                    color: "#5F2120",
                },
                icon: {
                    marginRight: 0,
                },
            },
        },
        MuiAlertTitle: {
            styleOverrides: {
                root: {
                    color: "inherit",
                    fontFamily: "Poppins Medium",
                },
            },
        },
        MuiAccordion: {
            styleOverrides: {
                root: {
                    boxShadow: "none",
                    "&:last-of-type": {
                        borderBottomLeftRadius: 16,
                        borderBottomRightRadius: 16,
                    },
                },
            },
        },
        MuiListItem: {
            styleOverrides: {
                root: {
                    padding: 16,
                    backgroundColor: "#fff",
                    boxShadow: "rgba(8, 0, 31, 0.18) 0px 1px 2px -1px",
                    boxSizing: "border-box",
                    height: "auto",
                    gap: 16,
                    borderRadius: 16,
                    "@media (max-width: 767px)": {
                        gap: 8,
                        padding: 8,
                    },
                },
                container: {
                    maxWidth: "100%",
                },
            },
        },
        MuiListItemButton: {
            styleOverrides: {
                root: {
                    borderRadius: 16,
                    boxShadow: "rgba(8, 0, 31, 0.18) 0px 1px 2px -1px",
                    boxSizing: "border-box",
                    padding: 8,
                    backgroundColor: "#FFF",
                    display: "flex",
                    gap: 16,
                    "@media (min-width: 768px)": {
                        padding: 16,
                    },
                },
            },
        },
        MuiSkeleton: {
            styleOverrides: {
                rectangular: {
                    borderRadius: 8,
                },
            },
        },
        MuiContainer: {
            styleOverrides: {
                root: {
                    gap: 16,
                    paddingLeft: 0,
                    paddingRight: 0,
                    display: "flex",
                    flexDirection: "column",
                    margin: 0,
                    "@media (min-width: 600px)": {
                        paddingLeft: 0,
                        paddingRight: 0,
                    },
                    "@media (min-width: 768px)": {
                        gap: 24,
                        maxWidth: "100%",
                    },
                },
            },
        },
        MuiAutocomplete: {
            styleOverrides: {
                paper: {
                    maxHeight: "200px",
                },
            },
        },
    },
}

export default lightTheme
