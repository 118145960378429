import useBusinessLogic from "@/hooks/businesses/useBusinessLogic"
import useLocation from "@/hooks/useLocation"
import { sectionSx } from "@/pages/businesses/overview-business"
import useLocationStore from "@/store/location"
import useBusinessCategoriesStore from "@/store/overview/categories"
import { Box, InputLabel, Paper } from "@mui/material"
import { useEffect } from "react"
import { useTranslation } from "react-i18next"
import BoxChip from "@/components/features/business/box-chip"
import OverviewTitle from "../title"
import ErrorMessage from "@/components/features/business/error-message"

const OverviewBusinessCategory = ({ meta, mandatoryCheck = false }: any) => {
    const { navigateTo } = useBusinessLogic()
    const { t } = useTranslation()
    const { location } = useLocationStore()
    const { setCategories } = useLocation()
    const { primaryCategory, secondaryCategory, facebookCategory } = useBusinessCategoriesStore()
    useEffect(() => {
        if (location?.id) {
            setCategories(location?.categories, { isDraft: false })
        }
    }, [location?.id])
    // Iterate through each child element of MuiBox-root
    return (
        <Paper
            elevation={2}
            className="clickable"
            id="categories"
            sx={sectionSx({
                isError: mandatoryCheck,
            })}
            data-e2e="location-categories"
            onClick={() => navigateTo("edit-category")}
        >
            <OverviewTitle
                title={t("BUSINESSES.CATEGORIES")}
                // icon="tag"
                isSecondTitle
                tooltipText={t("BUSINESSES.EDIT_CATEGORIES.MAIN_TOOLTIP")}
            />
            {mandatoryCheck && <ErrorMessage />}
            {primaryCategory?.label && (
                <Box className="chip-wrapper">
                    <InputLabel>{t("BUSINESSES.PRIMARY_CATEGORY")}</InputLabel>
                    <BoxChip items={[primaryCategory]} isAction={false} dataTestid="primary-category" />
                </Box>
            )}
            {secondaryCategory?.length > 0 && (
                <Box className="chip-wrapper">
                    <InputLabel>{t("BUSINESSES.SECONDARY_CATEGORY")}</InputLabel>
                    <BoxChip items={secondaryCategory} isAction={false} dataTestid="secondary-category" />
                </Box>
            )}
            {meta?.facebook_id && facebookCategory?.length > 0 && (
                <Box className="chip-wrapper" data-e2e="facebook-category">
                    <InputLabel>{t("BUSINESSES.FACEBOOK_CATEGORY")}</InputLabel>
                    <BoxChip items={facebookCategory} isAction={false} dataTestid="facebook-category" />
                </Box>
            )}
        </Paper>
    )
}

export default OverviewBusinessCategory
