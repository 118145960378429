import { HexColor } from "@/types/hexadecimal"

export const TO_RADIANS = Math.PI / 180

export const iconStatus = {
    RECEIVED: { key: "campaignSent", color: "#377BFF" },
    CLICKED: { key: "campaignOpened", color: "#00D863" },
    FAILED: { key: "send", color: "#B337FF" },
    SENT: { key: "send", color: "#B337FF" },
    SCHEDULED: { key: "clock", color: "#B337FF" },
}

export const imgListExtension = ["jpg", "jpeg", "png"]
export const videoListExtension = ["mp4", "mov", "m4v"]

/**
 *  @description The default colors propsed to the user mentionned in the design.
 *
 * **source : https://www.figma.com/proto/OYIClkbI9OdQqMkYo5SRZI/Quicksite---Handoff?node-id=18993-30600&t=faycY50NASd6T8Pa-1&scaling=min-zoom&content-scaling=fixed&page-id=18583%3A44&starting-point-node-id=18886%3A22205 **
 */
export const defaultColors: HexColor[] = ["#000000", "#D233C2", "#0162D2", "#33BAD2", "#3EC232", "#D29D33", "#D23333"]
