import { QuicksiteService, ResellerApi } from "@/api"
import CopiableLink from "@/components/base/copiable-link"
import Icon from "@/components/base/Icon"
import DomainSelectModal from "@/components/features/web-page/modals/add-domain-modal"
import StatusChip from "@/components/features/web-page/status-chip"
import useResponsive from "@/hooks/use-responsive"
import UserSessionStore from "@/store/user-session"
import { Box, Button, CircularProgress, Container, Paper, Skeleton, Stack } from "@mui/material"
import { useCallback, useEffect, useMemo, useState } from "react"
import { useTranslation } from "react-i18next"
import { useLocation, useNavigate, useParams } from "react-router-dom"
import useStyles from "./styles"
import { SkeletonHeaderBtn } from "@/components/widget/skeleton/header-btn-left-right"
import useNotification from "@/hooks/useNotification"
import { TitleWrapper } from "@/components/base/title-wrapper"
import useUiPageSession from "@/hooks/useUiPageSession"
import EditMainInfo from "@/pages/businesses/edit-business/edit-main-info"
import { Quicksite } from "@dilypse/quicksite-components/quicksite"
import UiStore from "@/store/ui"
import EditContact from "@/pages/businesses/edit-business/edit-contact"
import EditCategory from "@/pages/businesses/edit-business/edit-category"
import EditLink from "@/pages/businesses/edit-business/edit-link"
import UiPageSessionStore, { PageBusinesssType } from "@/store/ui-page-session"
import useBusinessLogic from "@/hooks/businesses/useBusinessLogic"
import EditServices from "@/pages/businesses/edit-business/edit-service"
import EditDescription from "@/pages/businesses/edit-business/edit-description"
import useLogic from "@/hooks/businesses/overview-business/useLogic"
import ListMedia from "@/pages/businesses/edit-business/list-media"
import useMediaStore from "@/store/overview/media"
import useBusinessServicesStore from "@/store/overview/services"
import EditStandardSchedule from "@/pages/businesses/edit-business/edit-standard-schedule"
import EditZone from "@/pages/businesses/edit-business/edit-zone"
import useBusinessRegularHoursStore from "@/store/overview/regular-hours"
import useLocationHours from "@/hooks/useLocationHours"
import EditMedia from "@/pages/businesses/edit-business/edit-media"
import ViewQuicksite from "@/components/widget/web-page/view-quicksite"
import ColorPicker from "@/components/widget/color-picker"
import { defaultColors } from "@/data/constants"
import { HexColor } from "@/types/hexadecimal"
import { getTextColor, isValidHex } from "@/utils/web-page/get-text-color-from-hex"
import EditProInfos from "@/pages/businesses/edit-business/edit-pro-infos"

type ActionBlocType = {
    mainInfo: boolean
    contact: boolean
    socials: boolean
    category: boolean
    services: boolean
    description: boolean
    gallery: boolean
    heroImage: boolean
    standardSchedule: boolean
    servicesArea: boolean
    proInfos: boolean
}

const initStateBloc: ActionBlocType = {
    mainInfo: false,
    contact: false,
    category: false,
    socials: false,
    services: false,
    description: false,
    gallery: false,
    heroImage: false,
    standardSchedule: false,
    servicesArea: false,
    proInfos: false,
}

const WebPageDetails = () => {
    const { locationId } = useParams<{ locationId: string }>()
    const { classes } = useStyles()
    const { notif } = useNotification()
    const { t } = useTranslation()
    const [addDomainModalOpen, setAddDomainModalOpen] = useState(false)
    const { isSingleLocation } = UserSessionStore()
    const { isMobile } = useResponsive()
    const [loadingRepublish, setLoadingRepublish] = useState<boolean>(false)
    const [generalLoading, setGeneralLoading] = useState<boolean>(false)
    const [currentLocation, setCurrentLocation] = useState<any | null>(null)
    const navigate = useNavigate()
    const { preferredLanguage } = UiStore()
    const [quicksiteJson, setQuicksiteJson] = useState<any | null>(null)
    const [editBloc, setEditBloc] = useState<ActionBlocType>(initStateBloc)
    const { navigateTo } = useBusinessLogic()
    const { handlePreviousButton, profile, location } = useLogic({ businessId: locationId })
    const { updateRegularHours } = useBusinessRegularHoursStore()
    const { previousItem, updatePreviousItem } = UiPageSessionStore()
    const routerLocation = useLocation()
    const { update: updateMedia, listMedia, selectedMediaIndex } = useMediaStore()
    const { handleDrawerClose, showPage } = useUiPageSession({
        title: "WEB_PAGE.MENU",
        page: "web-page-detail",
    })
    const [computeMediaIndex, setComputeMediaIndex] = useState(true)
    const [colorDirty, setColorDirty] = useState<boolean>(false)
    const [primaryColor, setPrimaryColor] = useState<HexColor>(defaultColors[0])
    const [secondaryColor, setSecondaryColor] = useState<HexColor>("#FFFFFF")
    const [updatingLoading, setUpdatingLoading] = useState<boolean>(false)

    useLocationHours()
    const setNewColor = useCallback((newColor: HexColor) => {
        if (!isValidHex(newColor)) return
        const secondary: HexColor = getTextColor(newColor)
        setPrimaryColor(newColor)
        setSecondaryColor(secondary)

        setQuicksiteJson((prev: any) => ({
            ...prev,
            settings: {
                ...prev.settings,
                theme: {
                    ...prev.settings.theme,
                    color: {
                        ...prev.settings.theme.color,
                        primary: newColor,
                        secondary,
                    },
                },
            },
        }))
        setColorDirty(true)
    }, [])

    const handlePublishColorChanges = useCallback(() => {
        if (!profile?.uid || !locationId) return
        const payload = {
            userUid: profile.uid,
            design: {
                primaryColor,
                secondaryColor,
            },
        }
        setUpdatingLoading(true)
        QuicksiteService.updateColor(locationId, payload)
            .then((response) => {
                if (response.error) throw new Error("Error updating color")
                setColorDirty(false)
                notif({
                    message: t("WEB_PAGE.NOTIFICATIONS.DOMAIN_PUBLISHED"),
                    type: "SUCCESS",
                })
            })
            .catch(() => {
                notif({ message: t("SYSTEM_ERROR.INVALID_REQUEST"), type: "ERROR" })
            })
            .finally(() => setUpdatingLoading(false))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [profile.uid, locationId, primaryColor, secondaryColor])

    const getLocationAndQuicksite = useCallback(async () => {
        if (locationId && location?.companyId && profile?.uid) {
            setGeneralLoading(true)
            const quicksite = await QuicksiteService.getQuicksitePreview({
                location_id: locationId,
                user_uid: profile.uid,
                company_id: location?.companyId,
            })
            setPrimaryColor(quicksite?.settings?.theme?.color?.primary || defaultColors[0])
            if (!quicksite.error) {
                setCurrentLocation(location)
                setQuicksiteJson({
                    ...quicksite,
                    is_edit: true,
                })
            }
            setGeneralLoading(false)
        }
    }, [locationId, profile.uid, location])

    const handleClose = useCallback(
        async (blocId: string) => {
            setEditBloc({
                ...initStateBloc,
                [blocId]: false,
            })
            await getLocationAndQuicksite()
        },
        [getLocationAndQuicksite]
    )

    const onSuccessPublishing = () => {
        setCurrentLocation((prev: any) => {
            return {
                ...prev,
                quicksite: {
                    status: "IN_PROGRESS",
                },
            }
        })
    }

    const quicksiteMediaIds = useMemo(() => {
        return quicksiteJson?.medias?.map((media: any) => media.id)
    }, [quicksiteJson?.medias])

    const updateSelectedMediaIndex = useCallback(() => {
        if (quicksiteJson?.location && computeMediaIndex) {
            if (editBloc?.gallery) {
                const selectedMediaIndex = quicksiteMediaIds
                    .map((id: string) => listMedia.findIndex((media: any) => media.id === id))
                    .filter((index: number) => index !== -1)
                updateMedia({ selectedMediaIndex })
            } else if (editBloc?.heroImage) {
                const selectedMediaIndex = listMedia.findIndex(
                    (media: any) => media.url === quicksiteJson?.location?.hero_image
                )

                updateMedia({ selectedMediaIndex: [selectedMediaIndex] })
            }

            setComputeMediaIndex(false)
        }
    }, [
        quicksiteJson,
        computeMediaIndex,
        editBloc?.gallery,
        editBloc?.heroImage,
        quicksiteMediaIds,
        updateMedia,
        listMedia,
    ])

    const { services, updateServices } = useBusinessServicesStore()
    const { refreshItems } = UiPageSessionStore()
    const fetchServices = useCallback(
        async (uid = "") => {
            const response: any = await ResellerApi.fetchServices({ uid })
            if (response) {
                const services = response?.data?.[0] || {}
                updateServices({ services })
            }
        },
        [updateServices]
    )

    useEffect(() => {
        if (location?.id && (refreshItems || !services?.sections)) {
            fetchServices(location?.id)
        }
    }, [fetchServices, location?.id, refreshItems, services?.sections])

    useEffect(() => {
        updateSelectedMediaIndex()
    }, [updateSelectedMediaIndex])

    const handleRepublish = useCallback(async () => {
        setLoadingRepublish(true)
        const res = await QuicksiteService.unsuspend(locationId, profile.uid)
        if (res?.status === "PUBLISHED") {
            await getLocationAndQuicksite()
            notif({
                message: t("WEB_PAGE.NOTIFICATIONS.DOMAIN_PUBLISHED"),
                type: "SUCCESS",
            })
        } else {
            notif({ message: t("SYSTEM_ERROR.INVALID_REQUEST"), type: "ERROR" })
        }
        setLoadingRepublish(false)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [getLocationAndQuicksite, locationId, profile.uid])

    useEffect(() => {
        const createEventHandler = (blocKey: keyof ActionBlocType, navigationPath?: PageBusinesssType) => () => {
            if (navigationPath) {
                navigateTo(navigationPath)
            }
            setEditBloc({ ...initStateBloc, [blocKey]: true })
        }

        const eventHandlers = {
            "main-info": createEventHandler("mainInfo", "edit-main-info"),
            contact: createEventHandler("contact", "edit-contact"),
            category: createEventHandler("category", "edit-category"),
            socials: createEventHandler("socials", "edit-link"),
            services: createEventHandler("services", "edit-service"),
            description: createEventHandler("description", "edit-description"),
            "standard-schedule": createEventHandler("standardSchedule", "edit-standard-schedule"),
            gallery: createEventHandler("gallery", "list-media"),
            "services-area": createEventHandler("servicesArea", "edit-zone"),
            "hero-image": createEventHandler("heroImage", "list-media"),
            "professional-info": createEventHandler("proInfos", "edit-pro-infos"),
        }

        window.addEventListener("webpage-edit", (e) => {
            const { section } = (e as CustomEvent).detail
            eventHandlers[section]()
            if (section === "gallery" || section === "hero-image") {
                if (!previousItem?.galleryIndex || !previousItem?.heroIndex) {
                    setComputeMediaIndex(true)
                }
            }
        })

        return () => {
            window.removeEventListener("webpage-edit", (e) => {
                const { section } = (e as CustomEvent).detail
                eventHandlers[section]()
            })
        }
    }, [navigateTo, previousItem?.galleryIndex, previousItem?.heroIndex])

    useEffect(() => {
        getLocationAndQuicksite()
    }, [getLocationAndQuicksite])

    useEffect(() => {
        if (location?.status || location?.regularHours) {
            updateRegularHours({
                status: location?.status,
                regularHours: location?.regularHours,
            })
        }
    }, [location?.status, location?.regularHours, updateRegularHours])

    useEffect(() => {
        return () => {
            updateMedia({ listMedia: [], selectedMediaIndex: [] })
        }
    }, [routerLocation, updateMedia])

    useEffect(() => {
        if (!quicksiteJson) return
        const newPrimary = isValidHex(quicksiteJson.settings?.theme?.color?.primary)
            ? quicksiteJson.settings.theme.color.primary
            : defaultColors[0]

        const newSecondary = isValidHex(quicksiteJson.settings?.theme?.color?.secondary)
            ? quicksiteJson.settings.theme.color.secondary
            : "#FFFFFF"

        setPrimaryColor(newPrimary)
        setSecondaryColor(newSecondary)
    }, [quicksiteJson])

    return (
        <Container>
            <DomainSelectModal
                open={addDomainModalOpen}
                onClose={() => setAddDomainModalOpen(false)}
                userUid={profile.uid}
                locationId={locationId}
                onSuccessPublishing={onSuccessPublishing}
                colors={{
                    primary: primaryColor,
                    secondary: secondaryColor,
                }}
            />
            {currentLocation ? (
                <TitleWrapper
                    title={currentLocation?.name}
                    handlePreviousButton={() => handlePreviousButton("/web-page")}
                    className="title-overview"
                    isSingleBusiness={isSingleLocation}
                />
            ) : (
                <Skeleton variant="rectangular" width={300} height={45} />
            )}
            {!currentLocation && <SkeletonHeaderBtn />}
            {currentLocation && (
                <Paper className={classes.wrapper}>
                    <div>
                        <Stack flexDirection={"row"} gap={2}>
                            <ColorPicker
                                colors={defaultColors}
                                currentValue={primaryColor}
                                onSelectColor={setNewColor}
                            />
                            {currentLocation && currentLocation.quicksite && (
                                <Button
                                    color="primary"
                                    variant="text"
                                    data-testid="web-page-parameter"
                                    startIcon={!isMobile && <Icon name="setting" sx={{ color: "primary.main" }} />}
                                    className="icon-parameter"
                                    sx={{
                                        backgroundColor: "#00041F0A",
                                        color: "black",
                                    }}
                                    onClick={() => navigate(`/web-page/parameters/${locationId}`)}
                                >
                                    {!isMobile ? (
                                        t("GENERAL.ACTION.PARAMETERS")
                                    ) : (
                                        <Icon name="setting" sx={{ color: "primary.main" }} />
                                    )}
                                </Button>
                            )}
                        </Stack>
                    </div>

                    {currentLocation?.quicksite ? (
                        <Stack flexDirection={"row"} alignContent={"center"} alignItems={"center"} gap={2} minWidth={0}>
                            <ViewQuicksite link={currentLocation.quicksite.url} />
                            {!isMobile &&
                                currentLocation &&
                                // {/* [MVP] Ne pas afficher le lien vers le quicksite lorsque le statut est "En cours" ou "En échec" */}
                                // {/* https://ubiweb.atlassian.net/jira/software/c/projects/DLP/issues/DLP-4636 */}
                                currentLocation.quicksite.status !== "IN_PROGRESS" &&
                                currentLocation?.quicksite?.status !== "FAILED" && (
                                    <CopiableLink link={currentLocation.quicksite.url} />
                                )}
                            {currentLocation.quicksite.status === "SUSPENDED" && (
                                <Button
                                    variant="contained"
                                    startIcon={
                                        loadingRepublish && <CircularProgress size={16} sx={{ marginRight: "8px" }} />
                                    }
                                    onClick={() => handleRepublish()}
                                    disabled={loadingRepublish}
                                >
                                    {t("WEB_PAGE.HEADER.REPUBLISH")}
                                </Button>
                            )}
                            {currentLocation.quicksite.status !== "SUSPENDED" &&
                                (!colorDirty ? (
                                    <StatusChip status={currentLocation.quicksite.status} squared />
                                ) : (
                                    <Button
                                        variant="contained"
                                        onClick={handlePublishColorChanges}
                                        startIcon={
                                            updatingLoading && (
                                                <CircularProgress size={16} sx={{ marginRight: "8px" }} />
                                            )
                                        }
                                        disabled={updatingLoading || generalLoading}
                                    >
                                        {t("WEB_PAGE.HEADER.REPUBLISH")}
                                    </Button>
                                ))}
                        </Stack>
                    ) : (
                        <Button
                            variant="contained"
                            onClick={() => setAddDomainModalOpen(true)}
                            disabled={generalLoading}
                        >
                            {t("WEB_PAGE.HEADER.PUBLISH")}
                        </Button>
                    )}
                </Paper>
            )}
            {isMobile &&
                currentLocation &&
                currentLocation?.quicksite &&
                // {/* [MVP] Ne pas afficher le lien vers le quicksite lorsque le statut est "En cours" ou "En échec" */}
                // {/* https://ubiweb.atlassian.net/jira/software/c/projects/DLP/issues/DLP-4636 */}
                currentLocation?.quicksite?.status !== "IN_PROGRESS" &&
                currentLocation?.quicksite?.status !== "FAILED" && (
                    <Paper>
                        <CopiableLink link={currentLocation.quicksite.url} />
                    </Paper>
                )}
            {quicksiteJson && (
                <Box sx={{ position: "relative" }}>
                    <Quicksite
                        data={quicksiteJson}
                        lang={preferredLanguage === "en" ? "en" : "fr"}
                        reviews={quicksiteJson?.reviews}
                    />
                </Box>
            )}
            {!quicksiteJson && (
                <Paper sx={{ height: "599px", width: "100%", justifyContent: "center", alignItems: "center" }}>
                    <img
                        src={"/quicksite_bg_image.png"}
                        alt="background image"
                        width={"100%"}
                        style={{ maxWidth: "max-content", maxHeight: "-webkit-fit-content" }}
                    />
                </Paper>
            )}

            <EditMainInfo
                open={showPage === "edit-main-info" && editBloc.mainInfo}
                handlePrevious={() => handleClose("mainInfo")}
                onClose={handleDrawerClose}
            />
            <EditContact
                open={showPage === "edit-contact" && editBloc.contact}
                onClose={handleDrawerClose}
                handlePrevious={() => handleClose("contact")}
            />
            <EditCategory
                open={showPage === "edit-category" && editBloc.category}
                onClose={handleDrawerClose}
                handlePrevious={() => handleClose("category")}
            />
            <EditLink
                open={showPage === "edit-link" && editBloc.socials}
                onClose={handleDrawerClose}
                handlePrevious={() => handleClose("socials")}
            />
            <EditServices
                open={showPage === "edit-service" && editBloc.services}
                onClose={handleDrawerClose}
                handlePrevious={() => handleClose("services")}
            />
            <EditDescription
                open={showPage === "edit-description" && editBloc.description}
                onClose={handleDrawerClose}
                handlePrevious={() => handleClose("description")}
            />
            <EditStandardSchedule
                open={showPage === "edit-standard-schedule" && editBloc.standardSchedule}
                onClose={handleDrawerClose}
                handlePrevious={() => handleClose("standard-schedule")}
            />
            <EditZone
                open={showPage === "edit-zone" && editBloc.servicesArea}
                onClose={handleDrawerClose}
                handlePrevious={() => handleClose("zone")}
            />
            <ListMedia
                onClose={() => {
                    if (editBloc.gallery) handleClose("gallery")
                    if (editBloc.heroImage) handleClose("heroImage")
                }}
                open={
                    (showPage === "list-media" || showPage === "edit-media") && (editBloc.gallery || editBloc.heroImage)
                }
                maxImages={editBloc.heroImage ? 1 : 8}
                mode="selection"
                context="quicksite"
                cancelSelection={() => {
                    if (editBloc.gallery) {
                        handleClose("gallery")
                        updatePreviousItem({ galleryIndex: null })
                    }
                    if (editBloc.heroImage) {
                        handleClose("heroImage")
                        updatePreviousItem({ heroIndex: null })
                    }
                    setComputeMediaIndex(true)
                }}
                onPrevious={() => {
                    if (editBloc.gallery) {
                        handleClose("gallery")
                        updatePreviousItem({ galleryIndex: selectedMediaIndex })
                    }
                    if (editBloc.heroImage) {
                        handleClose("heroImage")
                        updatePreviousItem({ heroIndex: selectedMediaIndex })
                    }
                }}
                onMediaUpdated={() => {
                    updateMedia({ selectedMediaIndex: [] })
                    setComputeMediaIndex(true)
                }}
                onSubmitSelection={async (images) => {
                    const payload = {
                        userUid: profile?.uid,
                        hero_image: images?.[0]?.id || null,
                        medias: images?.map((image) => image?.id),
                    }
                    if (editBloc.gallery) {
                        delete payload.hero_image
                        await QuicksiteService.updateMedia(locationId, payload)
                        handleClose("gallery")
                    } else if (editBloc.heroImage) {
                        delete payload.medias
                        await QuicksiteService.updateMedia(locationId, payload)
                        handleClose("heroImage")
                    }
                    return Promise.resolve()
                }}
            />
            <EditMedia
                open={showPage === "edit-media" && (editBloc.gallery || editBloc.heroImage)}
                onClose={handleDrawerClose}
            />
            <EditProInfos
                open={showPage === "edit-pro-infos" && editBloc.proInfos}
                onClose={handleDrawerClose}
                handlePrevious={() => handleClose("description")}
            />
        </Container>
    )
}

export default WebPageDetails
