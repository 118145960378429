import env from "@/config/env"
import { CookiesService } from "@/services"
import { decode64AndParse, encodeBase64, objectToQueryString } from "@/utils"

interface AuthInput {
    ag_uid: string
    callbackPath?: string
    updateAccessToken?: boolean
    type?: string
    gmb_access_id?: string
}

interface GoogleAccountParams {
    companyId: string
    user_id: string
    gmb_access_id?: string
    yext_id?: string
    locationName?: string
    multiple_accounts?: boolean
    bearer?: string
}

const generateUrl = ({
    ag_uid = "",
    callbackPath = "/businesses",
    updateAccessToken = false,
    type = "",
    gmb_access_id = "",
}: AuthInput) => {
    const idc = ag_uid
    const id = CookiesService.get("resellerUid") ?? ""
    const uri = `${window.location.origin}${callbackPath}`
    const messagingData = updateAccessToken ? "&updateAccessToken=true" : ""
    const typeData = type != "" ? `&${type}` : ""
    const gmbId = gmb_access_id != "" ? `&gmb_access_id=${gmb_access_id}` : ""
    const param = `id=${id}&idc=${idc}&cb=${uri}${messagingData}${typeData}${gmbId}`
    const dataParam = encodeBase64(param)
    const url = env.AUTH_URL
    return `${url}?inf=` + dataParam
}

const openGoogleAccount = ({
    companyId,
    user_id,
    gmb_access_id = "",
    yext_id = "",
    locationName = "",
    multiple_accounts = false,
    bearer = "",
}: GoogleAccountParams) => {
    const params = [
        `companyId=${encodeURIComponent(companyId)}`,
        `user_id=${encodeURIComponent(user_id)}`,
        `cb=${encodeURIComponent(window.location.origin)}/businesses`,
        `auth_type=google`,
        gmb_access_id && `gmb_access_id=${encodeURIComponent(gmb_access_id)}`,
        yext_id && `yext_id=${encodeURIComponent(yext_id)}`,
        locationName && `locationName=${encodeURIComponent(locationName)}`,
        multiple_accounts && `multiple_accounts=1`,
        bearer && `bearer=${encodeURIComponent(bearer)}`,
    ]
        .filter(Boolean)
        .join("&")

    const b64 = encodeBase64(params)

    const query = objectToQueryString({
        client_id: env.GBP_CLIENT_ID,
        redirect_uri: env.AUTH_URL,
        response_type: "code",
        scope: env.GBP_SCOPE,
        prompt: "consent",
        access_type: "offline",
        gsiwebsdk: "3",
        flowName: "GeneralOAuthFlow",
        state: b64,
    })
    const authUrl = multiple_accounts
        ? `${env.AUTH_URL}?state=${b64}`
        : `https://accounts.google.com/o/oauth2/v2/auth/oauthchooseaccount?${query}`

    window.location.href = authUrl
}

const checkIsAdminCompany = (role = "") => {
    let isAdminCompany = false
    if (!role) return isAdminCompany
    const roleList: any = decode64AndParse(role)
    if (
        roleList?.includes("ROLE_ADMIN_COMPANY") &&
        !roleList?.includes("ROLE_ADMIN_LOCATION") &&
        !roleList?.includes("ROLE_ADMIN_ESTABLISHMENT")
    ) {
        isAdminCompany = true
    }
    return isAdminCompany
}

const checkIsSuperAdmin = (role: string) => {
    if (!role || role === undefined) return false
    const roleList: any = decode64AndParse(role)
    return roleList?.includes("ROLE_SUPER_ADMIN")
}

const getPageAccess = ({ companies }) => {
    const page = { quicksite: false }

    if (companies && companies.length > 0) {
        page.quicksite = companies.some((company) => company.quicksite)
    }

    return page
}

export { checkIsAdminCompany, checkIsSuperAdmin, generateUrl, openGoogleAccount, getPageAccess }
