import { ResellerApi } from "@/api"
import { FileDetails, LocationImage } from "@/api/reseller"
import { MAX_INPUT } from "@/data/limiter"
import useLocation from "@/hooks/useLocation"
import useNotification from "@/hooks/useNotification"
import useWindowSize from "@/hooks/useWindowSize"
import useMediaStore from "@/store/overview/media"
import UiPageSessionStore from "@/store/ui-page-session"
import { MediaFormValues } from "@/types/businesses"
import { MediaService } from "@/services"
import { ErrorDuplicate, MediaObject } from "@/services/media"
import { zodResolver } from "@hookform/resolvers/zod"
import { ChangeEvent, useCallback, useEffect, useMemo, useRef, useState } from "react"
import { SubmitErrorHandler, SubmitHandler, useForm } from "react-hook-form"
import { useTranslation } from "react-i18next"
import { z } from "zod"
import useBusinessLogic from "../useBusinessLogic"
import { ensureArray } from "@/utils"

const useListMediaLogic = (onPrevious: () => void, context = "local-profile") => {
    const { t } = useTranslation()
    const refList = useRef(null)
    const [width, height] = useWindowSize()
    const { navigateTo } = useBusinessLogic()
    const { updateState: setUiPageSession, showPage, updatePreviousItem, previousItem } = UiPageSessionStore()
    const [openAddMediaModal, setOpenAddMediaModal] = useState(false)
    const [openRemoveModal, setOpenRemoveModal] = useState(false)
    const inputRef = useRef<HTMLInputElement>()
    const [error, setError] = useState(false)
    const { menuPage } = UiPageSessionStore()
    const {
        update: updateMedia,
        listMediaLocal,
        listMedia,
        fbChecked,
        googleChecked,
        resetChecked,
        selectedMediaIndex,
    } = useMediaStore()
    const { notif } = useNotification()
    const [loading, setLoading] = useState(false)
    const [mediaTest, setMediaTest] = useState({
        mediaValue: null,
        index: null,
    })
    const { location } = useLocation()
    const [isLoading, setIsLoading] = useState(false)

    const schema = useMemo(() => {
        return z.object({
            category: z.string().max(MAX_INPUT.TEXT).optional(),
        })
    }, [])

    const heightBoxList = useMemo(() => {
        let value = "auto"
        const boxListHeight = refList?.current?.getBoundingClientRect()?.height - 32
        if (boxListHeight + 180 < height) {
            value = "100%"
        }

        if (width < 768) {
            value = "100%;"
            if (boxListHeight + 280 > height) {
                value = "auto"
            }
        }
        return value
    }, [height, width])

    const onOpenAddMediaModal = useCallback(() => {
        resetChecked()
        setOpenAddMediaModal(true)
    }, [resetChecked])

    const handlePreviousButton = useCallback(() => {
        setUiPageSession({ refreshItems: true })
        if (menuPage !== "web-page-detail") {
            updateMedia({ listMedia: [] })
        }
        navigateTo("detail")
        if (typeof onPrevious === "function") {
            onPrevious()
        }
    }, [navigateTo, setUiPageSession, menuPage])

    const onCloseAddMediaModal = useCallback(() => {
        setError(false)
        setOpenAddMediaModal(false)
    }, [])

    const handleCancelButton = useCallback(() => {
        updateMedia({ listMediaLocal: [] })
        updatePreviousItem({ mediaItem: null, mediaItems: null })
        if (typeof onPrevious === "function") {
            onPrevious()
        }
    }, [updateMedia, updatePreviousItem])

    const handleDeleteLocal = useCallback(
        (index) => {
            listMediaLocal.splice(index, 1)
            updatePreviousItem({ mediaItems: listMediaLocal })
            setIsLoading(false)
            setOpenRemoveModal(false)
        },
        [listMediaLocal, updatePreviousItem]
    )

    const handleDeleteMedia = useCallback(
        async (img: LocationImage, key: number) => {
            const response = await ResellerApi.deleteMedia({ idMedia: img.id })
            if (response?.error) {
                notif({ message: t("SYSTEM_ERROR.INVALID_REQUEST"), type: "ERROR" })
                setLoading(false)
                setIsLoading(false)
            } else {
                updatePreviousItem({ mediaItem: null })
                listMedia.splice(key, 1)
                setOpenRemoveModal(false)
                setIsLoading(false)
            }
        },
        [listMedia, notif, t, updatePreviousItem]
    )

    const handleSelectMedia = useCallback(
        (img) => {
            if (
                !previousItem?.mediaItem ||
                (previousItem?.mediaItem &&
                    (!previousItem?.mediaItem.saveChange ||
                        previousItem?.mediaItem.id != img.id ||
                        previousItem?.mediaItem?.file?.name != img?.file?.name))
            ) {
                updatePreviousItem({
                    mediaItem: {
                        ...img,
                    },
                })
            }
            navigateTo("edit-media")
        },
        [navigateTo, previousItem?.mediaItem, updatePreviousItem]
    )

    const handleImage = useCallback(
        async (mediaFiles: FileList) => {
            if (mediaFiles?.length > 0) {
                setError(false)
                const mediaList = await MediaService.mediaUploadListWithNetwork(
                    listMediaLocal,
                    mediaFiles,
                    googleChecked,
                    fbChecked
                )
                if ((mediaList as ErrorDuplicate)?.duplicate) {
                    onCloseAddMediaModal()
                } else if ((mediaList as MediaObject[]).length > 0) {
                    if (listMediaLocal) {
                        const newTab = [...(mediaList as MediaObject[]), ...listMediaLocal]

                        updateMedia({ listMediaLocal: newTab })
                        updatePreviousItem({ mediaItems: newTab })
                    } else {
                        updateMedia({ listMediaLocal: mediaList as MediaObject[] })
                        updatePreviousItem({ mediaItems: mediaList })
                    }
                    onCloseAddMediaModal()
                } else {
                    setError(true)
                }
            }
        },
        [googleChecked, fbChecked, listMediaLocal, onCloseAddMediaModal, updateMedia, updatePreviousItem]
    )

    const handleModalRemove = useCallback(
        (mediaValue, index, value) => {
            if (index != null) {
                setMediaTest({
                    mediaValue: mediaValue,
                    index: index,
                })

                setOpenRemoveModal(value)
            }
            if (mediaTest?.index != null && value) {
                setIsLoading(true)
                if (mediaTest.mediaValue) {
                    handleDeleteMedia(mediaTest.mediaValue, mediaTest.index)
                } else {
                    handleDeleteLocal(mediaTest.index)
                }
                setMediaTest({
                    mediaValue,
                    index,
                })
            }
            if (mediaValue === null && index === null && value === false) {
                setMediaTest(null)
                setOpenRemoveModal(value)
            }
        },
        [handleDeleteLocal, handleDeleteMedia, mediaTest?.index, mediaTest?.mediaValue]
    )

    const inputChangeHandler = useCallback(
        (e: ChangeEvent<HTMLInputElement>) => {
            handleImage(e.target.files)
        },
        [handleImage]
    )

    const { handleSubmit } = useForm<MediaFormValues>({
        defaultValues: {
            category: "ADDITIONAL",
        },
        resolver: zodResolver(schema),
        mode: "onTouched",
    })

    const fetchMedias = useCallback(
        async (uid = "") => {
            try {
                const response = await ResellerApi.getListMedia({ locationId: uid, perpage: 0, context })

                // Check if response is not undefined or null
                if (response && !response.error) {
                    // Check if response.data is not undefined or null
                    const medias =
                        ensureArray(response.data)?.filter(
                            (media) => media.category === "" || media.category === "ADDITIONAL"
                        ) || []

                    updateMedia({ listMedia: medias })
                }
            } catch (error) {
                console.error("Error fetching medias:", error)
                // Handle error appropriately
            }
        },
        [updateMedia, context]
    )

    const submitMedia = useCallback(
        async (responseFile: FileDetails[]) => {
            const body = []
            if (responseFile && Array.isArray(responseFile)) {
                responseFile.map((media, index) => {
                    body.push({
                        locationId: location.id,
                        label: listMediaLocal[index].label,
                        type: listMediaLocal[index].type,
                        category: "ADDITIONAL",
                        url: media.url,
                        provider: MediaService.getMediaProvider(
                            listMediaLocal[index].googleChecked,
                            listMediaLocal[index].fbChecked
                        ),
                    })
                })
                const response = await ResellerApi.postMedias(body)
                if (response?.error) {
                    notif({ message: t("SYSTEM_ERROR.INVALID_REQUEST"), type: "ERROR" })
                    updatePreviousItem({ mediaItem: null })
                    setLoading(false)
                } else {

                    updatePreviousItem({ mediaItem: null })
                    updateMedia({ listMediaLocal: [] })
                    await fetchMedias(location.id)
                    notif({ message: t("MEDIA.SUCCESS_SAVE"), type: "SUCCESS" })
                    setLoading(false)
                    setUiPageSession({ refreshItems: true })
                }
            } else {
                notif({ message: t("SYSTEM_ERROR.INVALID_REQUEST"), type: "ERROR" })
                updatePreviousItem({ mediaItem: null })
                setLoading(false)
            }
        },
        [
            fetchMedias,
            listMedia,
            listMediaLocal,
            location?.id,
            notif,
            setUiPageSession,
            t,
            updateMedia,
            updatePreviousItem,
        ]
    )

    const onErrors: SubmitErrorHandler<any> = async (errors) => {
        if (errors) {
            console.warn(errors)
        }
    }

    const onSubmit: SubmitHandler<any> = useCallback(async () => {
        setLoading(true)
        if (listMediaLocal) {
            const newFiles = []
            listMediaLocal?.map((media) => newFiles.push(media.fileCrop ? media.fileCrop : media.file))
            const responseFile = await ResellerApi.postFiles({ location_id: location.id, files: newFiles })

            if (responseFile?.error) {
                setLoading(false)
                notif({ message: t("SYSTEM_ERROR.INVALID_REQUEST"), type: "ERROR" })
            } else {
                await submitMedia(responseFile)
            }
        }
    }, [listMediaLocal, location?.id, notif, t, submitMedia])

    useEffect(() => {
        if (
            location?.id &&
            ((menuPage === "businesses" && showPage === "list-media") || menuPage === "web-page-detail")
        ) {
            fetchMedias(location.id)
        }
    }, [fetchMedias, location?.id, menuPage, showPage])

    useEffect(() => {
        updateMedia({ listMediaLocal: previousItem?.mediaItems })
    }, [previousItem?.mediaItems])

    return {
        location,
        t,
        loading,
        onSubmit,
        onErrors,
        handleSubmit,
        handlePreviousButton,
        navigateTo,
        openAddMediaModal,
        onCloseAddMediaModal,
        onOpenAddMediaModal,
        handleImage,
        handleCancelButton,
        inputRef,
        listMediaLocal,
        inputChangeHandler,
        error,
        listMedia,
        handleSelectMedia,
        mediaTest,
        setMediaTest,
        heightBoxList,
        refList,
        handleModalRemove,
        handleDeleteMedia,
        openRemoveModal,
        isLoading,
    }
}

export default useListMediaLogic
