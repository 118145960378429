import { CookiesService } from "@/services"
import { UserType } from "@/types/user"
import { ApiService } from "../"
import { ErrorResponse } from "../base"

export interface UserLoginResponse {
    [x: string]: any
    user: {
        uid: string
        role: string
        email: string
        firstname: string
        lastname: string
        gender: null
        lang: string
        logo: string
        is_super_admin: boolean
    }
    vendor: {
        uid: string
        smsSender: string
        emailSpotconnect: string
    }
    metadata: {
        linkUrl: string
    }
    tokens: {
        token: string
        refresh_token: string
    }
}

interface OldMetadata {
    total_count: number
    page_size: number
    page_number: number
}

export interface UserListResponse {
    metadata: OldMetadata
    data: UserType[]
}

export interface InitPasswordProps {
    email: string
    uid: string
    password: string
    confirm_password: string
}

class UserApi {
    connectUser = async (
        id: string,
        credential: string,
        authType = "default"
    ): Promise<UserLoginResponse & Partial<ErrorResponse>> => {
        if (authType === "google") {
            return ApiService.post(`/user-login`, {
                client_id: id,
                credential,
                redirect_uri: location.origin,
            })
        }
        return ApiService.post(`/user-login`, { username: id, password: credential })
    }

    resetPassword = async (email: string, lang: string): Promise<Partial<ErrorResponse>> => {
        return ApiService.post(`/reset-password`, { email, lang })
    }

    initPassword = async (userInfos: InitPasswordProps): Promise<Partial<ErrorResponse>> => {
        return ApiService.post(`/init-password`, userInfos)
    }

    fetchListUser = async ({
        page_number,
        page_size,
        user_uid,
    }): Promise<UserListResponse & Partial<ErrorResponse>> => {
        return ApiService.get(`/users`, {
            page_number,
            page_size,
            user_uid,
            reseller_uid: CookiesService.get("resellerUid"),
        })
    }

    fetchUserInfo = async ({ id, user_uid }): Promise<UserType & Partial<ErrorResponse>> => {
        return ApiService.get(`/user/${id}`, {
            user_uid,
            reseller_uid: CookiesService.get("resellerUid"),
        })
    }

    createUser = async (userInfos: UserType) => {
        delete userInfos.uid
        return ApiService.post(`/user`, { ...userInfos, reseller_uid: CookiesService.get("resellerUid") })
    }

    updateUser = async (userInfos: UserType, user_uid: string): Promise<UserType & Partial<ErrorResponse>> => {
        const userObject = {
            user_uid,
            firstname: userInfos.firstname,
            lastname: userInfos.lastname,
            email: userInfos.email,
            roles: userInfos.roles,
        }
        if (userObject.roles?.[0] === "admin_company") {
            userObject["companies"] = userInfos.companies
        } else {
            userObject["locations"] = userInfos.locations
        }
        return ApiService.put(`/user/${userInfos.uid}`, userObject, {
            params: { reseller_uid: CookiesService.get("resellerUid") },
        })
    }

    deleteUser = async (userInfos: UserType, user_uid: string): Promise<Partial<ErrorResponse>> => {
        return ApiService.delete(`/user/${userInfos.uid}`, {
            params: { user_uid, reseller_uid: CookiesService.get("resellerUid") },
        })
    }

    fetchUserInfos = async (
        user_uid: string,
        company_uid: string
    ): Promise<UserLoginResponse & Partial<ErrorResponse>> => {
        return ApiService.post(`/user-promote`, {
            user_uid,
            company_uid,
        })
    }
}

export default new UserApi()
