import Icon from "@/components/base/Icon"
import CustomTooltip from "@/components/base/Tooltip"
import useLogicLogo from "@/hooks/businesses/edit-main-info/useLogicLogo"
import { modalStyle } from "@/pages/users/styles"
import { withSxProp } from "@/utils"
import { Box, Button, IconButton, Modal, Paper, Stack, Typography } from "@mui/material"
import { useRef } from "react"
import { useTranslation } from "react-i18next"
import { labelErrorSx, logoSx } from "../form-container/edit/edit-main-info/styles"

interface IModalLogo {
    open: boolean
    close: () => void
    setLogo: (file: File) => void
}

const AddLogoModal = ({ open, close, setLogo }: IModalLogo) => {
    const { t } = useTranslation()
    const inputRef = useRef<HTMLInputElement>()

    const {
        inputChangeHandler,
        handleImage,
        imageFile,
        error,
        closeModal,
        isThereStaticLogo,
        onAdd,
        labelRefHandler,
        label,
    } = useLogicLogo({
        setLogo,
        close,
    })

    return (
        <Modal
            open={open}
            onClose={closeModal}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Paper elevation={6} sx={withSxProp(modalStyle, logoSx)} data-testid="add-logo-modal">
                <Box className="title-wrapper">
                    <Typography id="modal-modal-title" data-testid="title" variant="h2" data-e2e="add-logo-title">
                        {t("BUSINESSES.EDIT_LOGO.ADD_FILE")}
                    </Typography>
                    <CustomTooltip data-testid="title-close" title={t("USERS.CLOSE")}>
                        <IconButton onClick={closeModal} className="btn-x">
                            <Icon name="x" />
                        </IconButton>
                    </CustomTooltip>
                </Box>
                <Box sx={{ width: "100%" }}>
                    <Box
                        component={"label"}
                        ref={labelRefHandler}
                        className="drop-zone"
                        onDrop={(e) => {
                            e.preventDefault()
                            handleImage(e.dataTransfer.files[0])
                        }}
                        onDragOver={(e) => {
                            e.preventDefault()
                        }}
                        sx={labelErrorSx({ error, height: label?.offsetWidth, hidden: !!isThereStaticLogo })}
                    >
                        <Icon name="imagePlus" className="imagePlus" />
                        <Box>
                            <Typography data-testid="title-choose" component={"span"} className="btn-txt">
                                {t("BUSINESSES.EDIT_LOGO.CHOOSE_FILE")}
                            </Typography>{" "}
                            <Typography
                                data-testid="title-deposite"
                                component="span"
                                sx={{ display: "none", "@media (min-width: 768px)": { display: "initial" } }}
                            >
                                {t("BUSINESSES.EDIT_LOGO.DEPOSIT_FILE")}
                            </Typography>
                        </Box>
                        <Stack sx={{ alignItems: "center" }} className="prerequisites">
                            <Typography
                                dangerouslySetInnerHTML={{
                                    __html: t("BUSINESSES.EDIT_LOGO.MAX_SIZE", {
                                        maxSize: "<strong>10 Mo</strong>",
                                        interpolation: { escapeValue: false },
                                    }),
                                }}
                                component="span"
                                className="image-type"
                                variant="caption"
                            />
                            <Typography component="span" className="image-type" variant="caption">
                                {t("BUSINESSES.EDIT_LOGO.ACCEPTED_EXTENSION")}: <strong>JPEG, JPG, PNG</strong>
                            </Typography>
                            <Typography component="span" className="image-type" variant="caption">
                                {t("BUSINESSES.EDIT_LOGO.FORMAT")}: <strong>{t("BUSINESSES.EDIT_LOGO.SQUARED")}</strong>
                            </Typography>
                            <Typography component="span" className="image-type" variant="caption">
                                {t("BUSINESSES.EDIT_LOGO.MIN_FORMAT")}: <strong>250x250px</strong>
                            </Typography>
                        </Stack>
                        <input
                            type="file"
                            hidden
                            ref={inputRef}
                            accept=".png, .jpg, .jpeg"
                            onChange={inputChangeHandler}
                        />
                    </Box>
                    {isThereStaticLogo && (
                        <Box
                            onClick={() => label?.click()}
                            sx={{
                                height: label?.offsetWidth,
                                color: "common.white",
                                position: "relative",
                                borderRadius: 1,
                                "@media (min-width: 768px)": { height: 225 },
                                "&::before": {
                                    content: '""',
                                    width: "100%",
                                    height: "100%",
                                    position: "absolute",
                                    top: 0,
                                    left: 0,
                                    backgroundColor: "rgba(53, 56, 75, 0.42)",
                                    borderRadius: 1,
                                    opacity: 0,
                                    transition: "opacity 600ms ease-in-out",
                                    cursor: "pointer",
                                },
                                "&:hover": {
                                    "&::before": {
                                        opacity: 1,
                                    },
                                    "& svg": {
                                        opacity: 1,
                                    },
                                },
                            }}
                        >
                            <Box
                                component={"img"}
                                src={URL.createObjectURL(imageFile)}
                                alt="Logo"
                                sx={{ width: "100%", height: "100%", borderRadius: 1 }}
                            />
                            <Icon
                                name="imagePlus"
                                sx={{
                                    position: "absolute",
                                    top: "50%",
                                    left: "50%",
                                    transform: "translate(-50%, -50%)",
                                    opacity: 0,
                                    transition: "opacity 600ms ease-in-out",
                                }}
                            />
                        </Box>
                    )}
                    {error && (
                        <Typography component="span" className="error">
                            {t("BUSINESSES.EDIT_LOGO.ERROR_FILE")}
                        </Typography>
                    )}
                </Box>
                <Box className="action-wrapper">
                    <Button onClick={closeModal} data-testid="cancel">
                        {t("USERS.CANCEL")}
                    </Button>
                    <Button
                        variant={"contained"}
                        onClick={onAdd}
                        sx={{ display: "flex", gap: 1, alignItems: "center" }}
                        disabled={!isThereStaticLogo}
                        data-testid="add"
                    >
                        <Icon name="filePlus" /> {t("COMMON.ADD")}
                    </Button>
                </Box>
            </Paper>
        </Modal>
    )
}

export default AddLogoModal
