import { SxProps, Theme } from "@mui/material/styles"

export const closeButton: SxProps<Theme> = {
    position: "absolute",
    top: 8,
    right: 8,
}

export const titleStyle: SxProps<Theme> = {
    fontWeight: 500,
    fontSize: "24px",
    fontLineHeight: "32.02px",
}

export const subtitleStyle: SxProps<Theme> = {
    color: "text.secondary",
    fontWeight: 400,
    fontSize: "14px",
    fontLineHeight: "20.02px",
}

export const saveButton: SxProps<Theme> = {
    flex: 1,
    height: "42px",
}
