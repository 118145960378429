import Icon from "@/components/base/Icon"
import CustomTooltip from "@/components/base/Tooltip"
import { modalStyle } from "@/pages/users/styles"
import { withSxProp } from "@/utils"
import { Box, Button, CircularProgress, Divider, IconButton, Modal, Paper, Typography } from "@mui/material"
import { useTranslation } from "react-i18next"
import { deleteModal } from "./styles"

type PropsModal = {
    open: boolean
    onClose: () => void
    loading: boolean
    onDelete: () => void
    title: string
    description: string
    popupContainer?: any
    submitLabel?: string
    noIcon?: boolean
    warnButton?: boolean
    withDivider?: boolean
}

export const DeleteModal = ({
    open,
    onClose,
    loading,
    onDelete,
    title,
    description,
    popupContainer,
    submitLabel,
    noIcon,
    warnButton,
    withDivider,
}: PropsModal) => {
    const { t } = useTranslation()
    return (
        <Modal
            open={open}
            onClose={onClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-remove"
            disableRestoreFocus
            container={() => popupContainer?.current}
            sx={{
                position: popupContainer?.current && popupContainer?.current !== undefined ? "absolute" : "fixed",
            }}
        >
            <Paper elevation={6} sx={withSxProp(modalStyle, deleteModal)} data-testid="modal-content">
                <Box className="title-wrapper">
                    <Typography id="modal-modal-title" variant="h2">
                        {title}
                    </Typography>
                    <CustomTooltip title={t("USERS.CLOSE")}>
                        <IconButton onClick={onClose} data-testid="close-button">
                            <Icon name="x" />
                        </IconButton>
                    </CustomTooltip>
                </Box>
                <Typography id="modal-remove">{description}</Typography>
                {withDivider === true && (
                    <Divider sx={{ width: "110%", borderBottomWidth: "1px", alignSelf: "center" }} />
                )}

                <Box className="action-wrapper">
                    <Button onClick={onClose} variant="contained" color="secondary">
                        {t("REPUTATION.CANCEL")}
                    </Button>
                    <Button
                        className="btn-remove"
                        onClick={onDelete}
                        variant="contained"
                        disabled={loading}
                        data-testid="delete-button-modal"
                        color={warnButton ? "error" : "primary"}
                    >
                        {loading && <CircularProgress size={16} />}
                        {!loading && noIcon !== true && <Icon name="trash" />}
                        {submitLabel || t("REPUTATION.REMOVE")}
                    </Button>
                </Box>
            </Paper>
        </Modal>
    )
}
