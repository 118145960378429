import { ResellerApi } from "@/api"
import { PlaceType } from "@/components/widget/autocomplete/address-autocomplete/types"
import { MAX_INPUT } from "@/data/limiter"
import { PROVINCES_CA } from "@/data/provinces"
import { US_STATES_CODE } from "@/data/states"
import useCountryList from "@/hooks/useCountryList"
import useNotification from "@/hooks/useNotification"
import useZipInputLogic from "@/hooks/zip-input/useZipInputLogic"
import CompaniesStore from "@/store/companies"
import UiPageSessionStore from "@/store/ui-page-session"
import UserSessionStore from "@/store/user-session"
import { FormValues } from "@/types/companies"
import { formatZipByCountry } from "@/utils"
import { zodResolver } from "@hookform/resolvers/zod"
import deburr from "lodash.deburr"
import { ChangeEvent, useCallback, useEffect, useMemo, useState } from "react"
import { useForm, useWatch } from "react-hook-form"
import { useTranslation } from "react-i18next"
import { z } from "zod"
import { isValidEmail } from "@/utils"

interface logicInput {
    pageType: "edit" | "create"
}

const useLogic = ({ pageType }: logicInput) => {
    const [isSaving, setIsSaving] = useState(false)
    const { profile } = UserSessionStore()
    const { t } = useTranslation()
    const { notif } = useNotification()
    const { selectedItem, previousItem, updateState: setUiPageSession, menuPage, showPage } = UiPageSessionStore()
    const { isZipValid, zipInputValue, checkZipIsValid, setZipInputValue, setIsZipValid } = useZipInputLogic()
    const [nameError, setNameError] = useState(false)
    const { countryList } = useCountryList()

    const { update: updateCompanieState } = CompaniesStore()

    const MESSAGE_TEXT = useMemo(() => {
        return {
            INVALID_REQUEST: t("SYSTEM_ERROR.INVALID_REQUEST"),
            COMPANY_UPDATE_SUCCESS: t("COMPANIES.EDIT.SUCCESS"),
            FIELD_REQUIRED: t("USERS.FIELD_REQUIRED"),
            CANCEL: t("USERS.CANCEL"),
            BACK: t("USERS.BACK_TOOLTIP"),
            COMPANY_CREATE_SAVE: t("COMPANIES.CREATE.SAVE"),
            COMPANY_NAME: t("COMPANIES.NAME"),
            COMPANY_EDIT_TITLE: t("COMPANIES.EDIT.TITLE"),
            EXIST: t("COMPANIES.CREATE.EXIST"),
            MAIN_INFO: t("USERS.MAIN_INFO"),
            COMPANY_CREATE_SUCCESS: t("COMPANIES.CREATE.SUCCESS"),
            COMPANY_CREATE_TITLE: t("COMPANIES.CREATE.TITLE"),
            ADDRESS: t("COMPANIES.ADDRESS"),
            COUNTRY: t("COMPANIES.COUNTRY"),
            TOWN: t("COMPANIES.TOWN"),
            CODE: t("COMPANIES.CODE"),
            PROVINCE: t("COMPANIES.PROVINCE"),
            STATES: t("COMPANIES.STATE"),
            ZIP_INVALID: t("COMPANIES.ZIP_INVALID"),
            EMAIL_REPLY_TITLE: t("REPUTATION.REPLY_EMAIL"),
            EMAIL_REPLY_DETAILS: t("REPUTATION.ADD_EMAIL_DETAILS"),
            EMAIL_REPLY_ERROR: t("INPUT.ERROR_EMAIL_SUPPORT"),
        }
    }, [t])

    const refreshCompany = useCallback(
        async (id: string) => {
            try {
                const response = await ResellerApi.fetchCompanyInfo({
                    id,
                    user_uid: profile.uid,
                })
                setValues(response)
                // eslint-disable-next-line @typescript-eslint/no-unused-vars
            } catch (error) {
                notif({ message: t("SYSTEM_ERROR.INVALID_REQUEST"), type: "ERROR" })
            }
        },
        [profile, t, MESSAGE_TEXT, notif]
    )

    const schema = z
        .object({
            name: z.string().trim().max(MAX_INPUT.TEXT).nonempty(MESSAGE_TEXT.FIELD_REQUIRED),
            address: z.string().trim().max(MAX_INPUT.TEXT).optional(),
            zip: z.string().trim().max(MAX_INPUT.TEXT).optional(),
            city: z.string().trim().max(MAX_INPUT.TEXT).optional(),
            country_code: z.string().max(MAX_INPUT.TEXT).nonempty(MESSAGE_TEXT.FIELD_REQUIRED),
            province_code: z.string().max(MAX_INPUT.TEXT).nullable().optional(),
            email_support: z.string().nullable().optional(),
        })
        .superRefine(({ email_support }, refinementContext) => {
            if (!isValidEmail(email_support)) {
                return refinementContext.addIssue({
                    code: z.ZodIssueCode.custom,
                    message: MESSAGE_TEXT.EMAIL_REPLY_ERROR,
                    path: ["email_support"],
                })
            }
        })

    const {
        reset,
        control,
        getValues,
        register,
        trigger,
        watch,
        setValue,
        handleSubmit,
        formState: { errors },
        // setError: setFormError,
        setFocus,
    } = useForm<FormValues>({
        defaultValues: {
            address: null,
            city: "",
            country_code: "",
            name: "",
            province_code: "",
            zip: "",
            email_support: "",
        },
        resolver: zodResolver(schema),
        mode: "onSubmit",
    })

    const countryCodeValue = useWatch({ control, name: "country_code" })

    const setValues = useCallback(
        (data) => {
            const address = data.address
            setValue("country_code", data.country_code)
            setAddressValuePlace({ description: address, structured_formatting: address })
            setValue("name", data.name)
            setValue("email_support", data?.email_support ?? "")
            handleAddressChange(data)
        },
        [setValue]
    )

    const provinceList = useMemo(() => {
        return PROVINCES_CA.map((code) => ({ label: t(`PROVINCE.${code}`), value: code })).sort((a, b) =>
            deburr(a.label) > deburr(b.label) ? 1 : -1
        )
    }, [t])

    const stateList = useMemo(() => {
        return US_STATES_CODE.map((code) => ({ label: t(`PROVINCE.${code}`), value: code })).sort((a, b) =>
            deburr(a.label) > deburr(b.label) ? 1 : -1
        )
    }, [t])

    const handleCancelButton = useCallback(() => {
        reset()
        setAddressValuePlace({ description: "", structured_formatting: null })
        setZipInputValue("")
        setNameError(false)
        setUiPageSession({ showPage: "list", previousItem: null, selectedItem: null })
    }, [reset])

    const handleAddressChange = useCallback(
        (data: FormValues) => {
            setIsZipValid(true)
            setValue("address", data.address)
            setValue("city", data.city)
            if (countryCodeValue !== "FR" && countryCodeValue !== "BE") setValue("province_code", data.province_code)
            setValue("zip", data.zip)
            setZipInputValue(data.zip)
            trigger(["address", "city", "province_code", "zip", "name"])
            setIsZipValid(checkZipIsValid(data.zip, data.country || data.country_code))
        },
        [trigger, setValue]
    )

    const handleAddressInputValue = useCallback((address: string) => {
        setValue("address", address)
        trigger("address")
    }, [])

    const [addressValuePlace, setAddressValuePlace] = useState<PlaceType>({
        description: "",
        structured_formatting: null,
    })

    const zipInputHandler = (e: ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value
        const formattedZip = formatZipByCountry(value, countryCodeValue)
        if (formattedZip || formattedZip === "") {
            setZipInputValue(formattedZip)
        }
    }

    const onSubmit = useCallback(
        async (data: FormValues, callApi: (data: FormValues) => any) => {
            setIsSaving(true)
            setIsZipValid(true)
            let isValid = true
            const body = { ...data }
            body["zip"] = zipInputValue

            if (!checkZipIsValid(zipInputValue, data.country_code)) {
                isValid = false
                setIsZipValid(false)
            }
            if (isValid) {
                const response = await callApi({ ...body })
                if (response?.error) {
                    if (response.error === "COMPANY_ALREADY_EXIST") {
                        // setFormError("name", { message: "" }, { shouldFocus: true })
                        setFocus("name")
                        setNameError(true)
                    } else {
                        notif({ message: MESSAGE_TEXT.INVALID_REQUEST, type: "ERROR" })
                    }
                } else {
                    notif({
                        message:
                            pageType === "edit"
                                ? MESSAGE_TEXT.COMPANY_UPDATE_SUCCESS
                                : MESSAGE_TEXT.COMPANY_CREATE_SUCCESS,
                        type: "SUCCESS",
                    })
                    reset()
                    setUiPageSession({ showPage: "list", previousItem: null, selectedItem: null, refreshItems: true })
                }
            }
            updateCompanieState({ refetchCompanies: true })
            setIsSaving(false)
        },
        [zipInputValue]
    )

    const handleCountrySelected = useCallback(
        ({ value }) => {
            setValue("address", "")
            setValue("city", "")
            setValue("province_code", "")
            setValue("country_code", value)
            setValue("zip", "")
            setZipInputValue("")
            setAddressValuePlace({ description: "", structured_formatting: null })
        },
        [setValue]
    )

    useEffect(() => {
        if (menuPage !== "companies" || showPage !== pageType) return

        const isEditPage: boolean = pageType === "edit"
        const isCreatePage: boolean = pageType === "create"

        if (isEditPage) {
            const hasItemChanged: boolean = previousItem?.uid !== selectedItem?.uid
            const isPreviousEditPage: boolean = previousItem?.pageType === "edit"

            if (hasItemChanged) {
                reset()
                refreshCompany(selectedItem.uid)
            } else if (isPreviousEditPage) {
                setValues(previousItem)
            }
            return
        }

        if (isCreatePage) {
            const hasPreviousData: boolean = !!previousItem?.name || !!previousItem?.country_code
            const isPreviousCreatePage: boolean = previousItem?.pageType === "create"

            if (hasPreviousData && isPreviousCreatePage) {
                setValues({
                    ...previousItem,
                    address: previousItem?.address || "",
                })
            } else {
                reset()
            }
        }
    }, [showPage, menuPage, previousItem, selectedItem, pageType, reset, refreshCompany, setValues])

    useEffect(() => {
        return () => {
            setUiPageSession({
                previousItem: {
                    ...getValues(),
                    uid: selectedItem?.uid,
                    pageType,
                },
            })
        }
    }, [getValues, selectedItem?.uid])

    return {
        MESSAGE_TEXT,
        stateList,
        provinceList,
        countryList,
        addressValuePlace,
        control,
        errors,
        isZipValid,
        zipInputValue,
        isSaving,
        nameError,
        handleSubmit,
        onSubmit,
        zipInputHandler,
        setAddressValuePlace,
        handleAddressChange,
        watch,
        handleCancelButton,
        register,
        handleAddressInputValue,
        handleCountrySelected,
        refreshCompany,
        getValues,
    }
}

export default useLogic
