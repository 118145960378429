import { Card, CardActions, CardContent, CardHeader, styled, SxProps } from "@mui/material"
import { PropsWithChildren, ReactNode } from "react"

type AdvancedCardProps = PropsWithChildren<{
    header?: ReactNode
    subHeader?: ReactNode
    footer?: ReactNode
    sx?: SxProps
    onClick?: () => void
    footerSx?: SxProps
    contentSx?: SxProps
}>

const StyledCardHeader = styled(CardHeader)(({ theme }) => ({
    padding: theme.spacing(2),
    borderBottom: `1px solid ${theme.palette.divider}`,
}))

const FooterCardAction = styled(CardActions)(({ theme }) => ({
    padding: theme.spacing(0),
    borderTop: `1px solid ${theme.palette.divider}`,
}))

/**
 * An general component for displaying cards
 */
export const AdvancedCard: React.FC<AdvancedCardProps> = ({
    header,
    subHeader,
    children,
    footer,
    sx,
    onClick,
    footerSx,
    contentSx,
}) => {
    return (
        <Card sx={{ padding: 0, gap: 0, ...sx }} variant="elevation" data-testid="card-item">
            {header && <StyledCardHeader title={header} subheader={subHeader} />}

            {children && (
                <CardContent sx={{ ...contentSx }} onClick={onClick}>
                    {children}
                </CardContent>
            )}

            {footer && <FooterCardAction sx={footerSx}>{footer}</FooterCardAction>}
        </Card>
    )
}
