import Icon from "@/components/base/Icon"
import BaseInput from "@/components/base/input"
import QuillEditor from "@/components/base/input-editor"
import useLogicEditModel from "@/hooks/campaign/edit-model/useLogic"
import useLogic, { FormModelData } from "@/hooks/campaign/form-model/useLogic"
import { Button, CircularProgress, Paper, Stack } from "@mui/material"
import { useCallback, useMemo } from "react"
import { Controller } from "react-hook-form"
import { useTranslation } from "react-i18next"
import useStyles from "./styes"

const maxLength = 300

interface PropsFormModel {
    onCancel: () => void
    defaultFormValue?: FormModelData
}

export const FormModel = ({ onCancel, defaultFormValue }: PropsFormModel) => {
    const { t } = useTranslation()
    const { classes } = useStyles()
    const { hasModel, fetchReviews } = useLogicEditModel()
    const { handleSubmit, onSubmit, control, setValue, loading, formValue, errors } = useLogic({
        defaultFormValue,
        onCancel,
        fetchReviews,
    })

    const atValues = useMemo(() => {
        return [
            { id: "firstname", value: t("CAMPAIGN.FIRSTNAME") },
            { id: "lastname", value: t("CAMPAIGN.LASTNAME") },
            { id: "business", value: t("CAMPAIGN.BUSINESS") },
            { id: "url", value: "url" },
        ]
    }, [])

    const onChange = useCallback((value: { html: string; delta: any; rawText: string; editorText: string }) => {
        setValue("content", value?.html)
        setValue("contentText", value.editorText)
    }, [])

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <Paper className={classes.formModel}>
                <Controller
                    render={({ field }) => (
                        <BaseInput
                            {...field}
                            onChange={(e) => {
                                // Trim the value before updating the state
                                const trimmedValue = e.target.value.trim()
                                if (trimmedValue !== "") {
                                    field.onChange(e.target.value)
                                } else {
                                    field.onChange("")
                                }
                            }}
                            required
                            name="name"
                            label={t("CAMPAIGN.MODEL.MODEL_NAME")}
                            error={false}
                            // helperText={errors?.name ? errors?.name?.message : ""}
                            showLabelStar
                        />
                    )}
                    control={control}
                    name="title"
                />
                <Controller
                    render={({ field }) => (
                        <BaseInput
                            {...field}
                            onChange={(e) => {
                                // Trim the value before updating the state
                                const trimmedValue = e.target.value.trim()
                                if (trimmedValue !== "") {
                                    field.onChange(e.target.value)
                                } else {
                                    field.onChange("")
                                }
                            }}
                            required
                            name="subject"
                            label={t("CAMPAIGN.SUBJECT")}
                            descriptionBottom={t("CAMPAIGN.MODEL.DESCRIPTION_SUBJECT")}
                            error={false}
                            showLabelStar
                        />
                    )}
                    control={control}
                    name="subject"
                />
                <QuillEditor
                    onChange={onChange}
                    placeholder={""}
                    atValues={atValues}
                    toolbar={null}
                    maxLength={maxLength}
                    label={t("CAMPAIGN.MODEL.CONTENT")}
                    className="email-editor hide-toolbar"
                    defaultValue={formValue.content}
                    descriptionBottom={t("CAMPAIGN.MODEL.DESCRIPTION_CONTENT")}
                    type={"edition"}
                />
                <Stack flexDirection={"row"} gap={2} justifyContent={"end"} className="btn-form-model">
                    {hasModel && (
                        <Button
                            color="secondary"
                            sx={{ padding: "8px 16px" }}
                            onClick={() => {
                                onCancel()
                            }}
                            data-testid="cancel-model"
                        >
                            {t("USERS.CANCEL")}
                        </Button>
                    )}
                    <Button
                        disabled={Boolean(errors.title || errors.subject || errors.contentText)}
                        variant={"contained"}
                        type="submit"
                        data-testid="save-model"
                    >
                        {loading && <CircularProgress size={16} sx={{ marginRight: "8px" }} />}
                        {!loading && <Icon name="save" sx={{ marginRight: "8px" }} />}
                        {t("USERS.SAVE")}
                    </Button>
                </Stack>
            </Paper>
        </form>
    )
}
