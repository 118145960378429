import { AccountsApi, ApiService, UserApi } from "@/api"
import { UserLoginResponse } from "@/api/user"
import { CookiesService, ResellerService } from "@/services"
import CampaignStore from "@/store/campaign"
import CampaignReviewsStore from "@/store/campaign-reviews"
import CompaniesStore from "@/store/companies"
import KpiLocalPresenceStore from "@/store/kpi-local-presence"
import KpiReputationStore from "@/store/kpi-reputation"
import KpiCampaignStore from "@/store/kpi-request-opinion"
import LocationStore from "@/store/location"
import useOverviewStore from "@/store/overview"
import ReputationStore from "@/store/reputation"
import ResellerStore from "@/store/reseller"
import SocialStore from "@/store/social"
import SocialPopupStore from "@/store/social-popup"
import UiPageSessionStore from "@/store/ui-page-session"
import UserSessionStore, { IUserSessionSetter, IUserSessionStore } from "@/store/user-session"
import UsersStore from "@/store/users"
import { checkIsAdminCompany } from "@/utils/auth"
import { AMPLITUDE_EVENT, LOGGED_IN_PROPERTIES } from "../amplitude/types"
import useDeviceCheck from "@/hooks/useDeviceCheck"
import amplitude from "../amplitude"

interface LoginReturn {
    success: boolean
    error?: string
}

class UserSessionService {
    clearSession = () => {
        CookiesService.flush()
    }

    setUserSession = (loginResponse: UserLoginResponse) => {
        const expandGoogleBanner = CookiesService.get("expandGoogleBanner") ?? true
        CookiesService.set({
            firstname: loginResponse?.user?.firstname,
            lastname: loginResponse?.user?.lastname,
            role: loginResponse?.user?.role,
            isAdminCompany: checkIsAdminCompany(loginResponse?.user?.role),
            user_uid: loginResponse?.user?.uid,
            smsSender: loginResponse?.vendor?.smsSender,
            feedbackUrl: loginResponse?.metadata?.linkUrl,
            emailSpotconnect: loginResponse?.vendor?.emailSpotconnect,
            email: loginResponse?.user?.email,
            token: loginResponse?.tokens?.token,
            refreshToken: loginResponse?.tokens?.refresh_token,
            resellerUid: loginResponse?.vendor?.uid,
            expandGoogleBanner,
        })
        ResellerService.setState({ resellerUid: loginResponse?.vendor?.uid })
        ApiService.setToken(loginResponse?.tokens?.token, loginResponse?.tokens?.refresh_token)
    }

    login = async (
        emailOrClientId: string,
        passwordOrCredential: string,
        authType = "default"
    ): Promise<LoginReturn> => {
        const loginResponse = await UserApi.connectUser(emailOrClientId, passwordOrCredential, authType)
        this.clearSession()

        const { isMobile, isTablet, isWebView } = useDeviceCheck()

        const loggedInProperties: LOGGED_IN_PROPERTIES = {
            user_id: "",
            timestamp: new Date(),
            failure_reason: "",
            login_method: authType === "google" ? "Google" : "mail/password",
            login_status: "",
            platform: isWebView
                ? "Application mobile"
                : isTablet || isMobile
                ? "Application web téléphone"
                : "Application web",
        }

        if (loginResponse?.error) {
            loggedInProperties.login_status = "échec"
            loggedInProperties.failure_reason =
                loginResponse?.error === "LOGIN_ERROR.INVALID_CREDENTIAL" ? "mauvais mot de passe" : "erreur système"

            amplitude.trackEvent(AMPLITUDE_EVENT.LOGGED_IN, loggedInProperties)

            return {
                success: false,
                error: loginResponse?.error === undefined ? "SYSTEM_ERROR.INVALID_REQUEST" : loginResponse?.error,
            }
        } else if (loginResponse?.user) {
            loggedInProperties.user_id = loginResponse.user.uid
            loggedInProperties.login_status = "succès"
            amplitude.trackEvent(AMPLITUDE_EVENT.LOGGED_IN, loggedInProperties)

            this.setUserSession(loginResponse)
            return { success: true }
        } else {
            loggedInProperties.login_status = "échec"
            loggedInProperties.failure_reason = "erreur système"

            amplitude.trackEvent(AMPLITUDE_EVENT.LOGGED_IN, loggedInProperties)
            return { success: false }
        }
    }

    loginSwitch = async ({ company_uid, token }: { company_uid: string; token: string }) => {
        const { isMobile, isTablet, isWebView } = useDeviceCheck()

        const loggedInProperties: LOGGED_IN_PROPERTIES = {
            user_id: "",
            timestamp: new Date(),
            failure_reason: "",
            login_method: "SSO",
            login_status: "",
            platform: isWebView
                ? "Application mobile"
                : isTablet || isMobile
                ? "Application web téléphone"
                : "Application web",
        }

        try {
            const { data } = await AccountsApi.userRedirect({ company_uid, access_token: token })

            if (data?.user) {
                loggedInProperties.user_id = data.user.uid
                loggedInProperties.login_status = "succès"
                loggedInProperties.failure_reason = ""
                amplitude.trackEvent(AMPLITUDE_EVENT.LOGGED_IN, loggedInProperties)

                UserSessionStore.getState().updateState({
                    role: data.user.role,
                    isAdminCompany: checkIsAdminCompany(data.user.role),
                    token: data.tokens?.token,
                    refreshToken: data.tokens?.refresh_token,
                    isLoggedIn: true,
                    profile: {
                        email: data.user.email,
                        firstname: data.user.firstname,
                        lastname: data.user.lastname,
                        uid: data.user.uid,
                    },
                })
                this.setUserSession(data)

                return true
            }
            return false
        } catch (error) {
            loggedInProperties.login_status = "échec"
            loggedInProperties.failure_reason = "erreur système"
            amplitude.trackEvent(AMPLITUDE_EVENT.LOGGED_IN, loggedInProperties)
            return false
        }
    }

    flushStore = async () => {
        CampaignStore.getState().flush()
        CampaignReviewsStore.getState().flush()
        CompaniesStore.getState().flush()
        KpiLocalPresenceStore.getState().flush()
        KpiReputationStore.getState().flush()
        KpiCampaignStore.getState().flush()
        LocationStore.getState().flush()
        useOverviewStore.getState().flush()
        ReputationStore.getState().flush()
        ResellerStore.getState().flush()
        SocialStore.getState().flush()
        SocialPopupStore.getState().flush()
        UiPageSessionStore.getState().flush()
        UserSessionStore.getState().flush()
        UsersStore.getState().flush()
    }

    logout = async () => {
        this.clearSession()
        await ApiService.invalidateToken()
    }

    resetPassword = async (email: string, lang: string) => {
        return await UserApi.resetPassword(email, lang)
    }

    initPassword = async ({ email, uid, password, confirm_password }) => {
        return await UserApi.initPassword({ email, uid, password, confirm_password })
    }

    getState = (): IUserSessionStore & IUserSessionSetter => {
        return UserSessionStore.getState()
    }

    setState = (update: Partial<IUserSessionStore>): void => {
        const { updateState } = this.getState()
        updateState(update)
    }
}

export default new UserSessionService()
