import { useMemo, useState } from "react"
import { Box, Modal, Paper, Typography, Button, Stack, IconButton } from "@mui/material"
import { useTranslation } from "react-i18next"
import {
    modalContainer,
    paperStyle,
    closeButton,
    titleStyle,
    subtitleStyle,
    textFieldStyle,
    cancelButton,
    saveButton,
} from "./style"
import Icon from "@/components/base/Icon"
import BaseInput from "@/components/base/input"
import { CompanyApi } from "@/api"
import { isValidEmail } from "@/utils"

type AddAddressModalProps = {
    /**
     * Controls whether the modal is open.
     */
    open: boolean
    /**
     * Function to handle the closure of the modal.
     */
    handleClose: () => void
    /**
     * The ID of the company to which the address is being added.
     */
    companyId: string
    /**
     * The unique identifier for the user making the change.
     */
    userUid: string
    /**
     * Callback to handle the change of the email choice.
     *
     * @param {string} companyId - The company's unique identifier.
     */
    handleChangeEmailChoice: (companyId: string) => void
}

/**
 * AddAddressModal Component
 *
 * Displays a modal to add or update the support email address for a company.
 *
 * @param {boolean} open - Indicates if the modal is open.
 * @param {() => void} handleClose - Callback to close the modal.
 * @param {string} companyId - The company's unique identifier.
 * @param {string} userUid - The user's unique identifier.
 * @returns {JSX.Element} The rendered component.
 * @example
 * <AddAddressModal open={true} handleClose={() => {}} companyId="123" userUid="abc" />
 */
export const AddAddressModal: React.FC<AddAddressModalProps> = ({
    open,
    handleClose,
    companyId,
    userUid,
    handleChangeEmailChoice,
}) => {
    const { t } = useTranslation()
    const [email, setEmail] = useState<string>("")

    const isMailValid = useMemo<boolean>(() => isValidEmail(email), [email])

    /**
     * Handles the save action by updating the company email support.
     */
    const handleSave = () => {
        CompanyApi.updateACompanyEmailSupport({
            companyId,
            user_uid: userUid,
            email_support: email,
            is_show_choice_email_support: false,
        }).then(() => {
            handleClose()
            handleChangeEmailChoice(companyId)
        })
    }

    return (
        <Modal open={open} onClose={handleClose} data-testid="add-address-modal">
            <Box sx={modalContainer}>
                <Paper elevation={6} sx={paperStyle}>
                    {/* Close button */}
                    <IconButton onClick={handleClose} sx={closeButton}>
                        <Icon name="x" />
                    </IconButton>

                    {/* Title */}
                    <Typography variant="h6" sx={titleStyle}>
                        {t("REPUTATION.ADD_RESPONSE_EMAIL")}
                    </Typography>

                    {/* Subtitle */}
                    <Typography variant="body2" sx={subtitleStyle}>
                        {t("REPUTATION.ADD_EMAIL_DETAILS")}
                    </Typography>

                    {/* Input Field */}
                    <BaseInput
                        fullWidth
                        type="email"
                        required
                        label={t("REPUTATION.REPLY_EMAIL")}
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        sx={textFieldStyle}
                        data-testid="email"
                    />

                    {/* Action Buttons */}
                    <Stack direction="row" justifyContent="space-between" spacing={2}>
                        <Button onClick={handleClose} variant="outlined" sx={cancelButton} data-testid="cancel">
                            {t("REPUTATION.CANCEL")}
                        </Button>
                        <Button
                            onClick={handleSave}
                            variant="contained"
                            color="primary"
                            sx={saveButton}
                            disabled={!isMailValid}
                            startIcon={<Icon name="save" />}
                            data-testid="save"
                        >
                            {t("REPUTATION.SAVE")}
                        </Button>
                    </Stack>
                </Paper>
            </Box>
        </Modal>
    )
}
