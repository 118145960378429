import useDeviceCheck from "@/hooks/useDeviceCheck"
import amplitude from "@/services/amplitude"
import { AMPLITUDE_EVENT, LOGGED_IN_PROPERTIES } from "@/services/amplitude/types"
import UiPageSessionStore from "@/store/ui-page-session"
import UserSessionStore from "@/store/user-session"
import { useEffect } from "react"
import { useNavigate } from "react-router-dom"

const useRedirect = () => {
    const navigate = useNavigate()
    const { isLoggedIn, profile } = UserSessionStore()
    const { updateState: updatePageSession } = UiPageSessionStore()
    const { isMobile, isTablet, isWebView } = useDeviceCheck()

    useEffect(() => {
        const isBasePath = window.location.pathname === "/"

        if (isLoggedIn && isBasePath) {
            const loggedInProperties: LOGGED_IN_PROPERTIES = {
                user_id: profile?.uid,
                timestamp: new Date(),
                failure_reason: "",
                login_method: "SSO",
                login_status: "succès",
                platform: isWebView
                    ? "Application mobile"
                    : isTablet || isMobile
                    ? "Application web téléphone"
                    : "Application web",
            }
            amplitude.trackEvent(AMPLITUDE_EVENT.LOGGED_IN, loggedInProperties)

            updatePageSession({
                menuPage: "reputation",
            })
            navigate("/reputation")
            return
        } else if (!isLoggedIn) {
            const url = window.location.pathname

            if (url.includes("/login")) {
                navigate(`/login`)
            } else {
                navigate(`/login?redirectTo=${encodeURIComponent(url)}`)
            }
            return
        }
    }, [isLoggedIn, isMobile, isTablet, isWebView, navigate, profile?.uid, updatePageSession])
}

export default useRedirect
