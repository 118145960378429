import { Box, Paper, Typography } from "@mui/material"
import { RefObject } from "react"
import { useTranslation } from "react-i18next"
import Icon from "@/components/base/Icon"
import BusinessAdditionalContact from "../overview/additional-contact"
import LinkBusiness from "../overview/link"
import OverviewProInfos from "../overview/pro-infos"

interface IAdditionalInfo {
    isLoading: boolean
    linksRef: RefObject<HTMLDivElement>
    contactRef: RefObject<HTMLDivElement>
    sx: any
    mandatoryCheck: boolean
    quicksiteDisabled?: boolean
}

const AdditionalInfo = ({ isLoading, linksRef, contactRef, sx, mandatoryCheck, quicksiteDisabled }: IAdditionalInfo) => {
    const { t } = useTranslation()
    return (
        <Paper className="section description additional-info">
            <Box className="title-wrapper">
                <Icon name="annotationInfo" />
                <Typography variant="h2">{t("BUSINESSES.ADDITIONAL_INFO")}</Typography>
            </Box>
            <Box className="section-content reverse">
                <LinkBusiness sx={sx} mandatoryCheck={mandatoryCheck} isLoading={isLoading} contactRef={contactRef} />
                <BusinessAdditionalContact isLoading={isLoading} linksRef={linksRef} />
            </Box>
            {!quicksiteDisabled && (
                <Box className="section-content">
                    <OverviewProInfos isLoading={isLoading} />
                </Box>
            )}

        </Paper>
    )
}

export default AdditionalInfo
