import { create } from "zustand"


export interface IProInfosStore {
    professionalInfo?: string
}

const initalState: IProInfosStore = {
    professionalInfo: "",
}

export interface IProInfosSetter {
    update?: (update: Partial<IProInfosStore>) => void
    flush?: () => void
}

const OverviewProInfosStore = create<IProInfosStore & IProInfosSetter>((set) => ({
    ...initalState,
    update: (update) => set(() => ({ ...update })),
    getInitialState: () => initalState,
    flush: () => set(initalState),
}))

export default OverviewProInfosStore
