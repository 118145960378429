import { SxProps, Theme } from "@mui/material"
import { makeStyles } from "tss-react/mui"

const modalStyle: SxProps<Theme> = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    maxWidth: "calc(100% - 48px)",
    width: 494,
    bgcolor: "background.paper",
    p: 2,
    display: "flex",
    flexDirection: "column",
    alignItems: "start",
    justifyContent: "center",
    gap: 2,
    "@media (max-width: 767px)": {
        maxWidth: "calc(100% - 16px)",
        width: "calc(100% - 16px)",
        padding: "16px",
    },
    "& .title-wrapper": {
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        gap: 1,
        width: "100%",
        alignItems: "baseline",
        "& h2": {
            width: "100%",
            fontSize: 24,
            fontWeight: 500,
        },
        "& p": {
            color: "action.active",
            textAlign: "center",
        },
        "& button": {
            padding: 0,
            color: "primary.main",
            top: "4px",
        },
        "& .description": {
            whiteSpace: "wrap",
        },
    },
    "& .action-wrapper": {
        display: "flex",
        gap: 1,
        flexDirection: "column",
        width: "100%",
        "& button": {
            padding: "8px 22px",
            fontSize: 15,
            // fontWeight: 500,
            width: "100%",
            // "&:first-of-type": {
            //     backgroundColor: "rgba(1, 128, 255, 0.08)",
            //     color: "secondary.main",
            //     "&:disabled": {
            //         backgroundColor: "rgba(104, 115, 141, 0.32)",
            //     },
            // },
        },
        "@media (min-width: 768px)": {
            flexDirection: "row",
            gap: 2,
        },
    },
}

const useStyles = makeStyles()(() => ({
    usersWrapper: {},
}))

export default useStyles
export { modalStyle }
