import { QuicksiteService } from "@/api"
import Icon from "@/components/base/Icon"
import BaseInput from "@/components/base/input"
import useNotification from "@/hooks/useNotification"
import { modalStyle } from "@/pages/users/styles"
import { withSxProp } from "@/utils"
import { Box, Button, CircularProgress, IconButton, Modal, Paper, Stack, Typography } from "@mui/material"
import { useCallback, useEffect, useMemo, useState } from "react"
import { useTranslation } from "react-i18next"
import { closeButton, saveButton, subtitleStyle, titleStyle } from "./style"
import { MAX_INPUT } from "@/data/limiter"
import { HexColor } from "@/types/hexadecimal"

type DomainSelectModalProps = {
    open: boolean
    onClose: () => void
    locationId: string
    userUid: string
    onSuccessPublishing: () => void
    colors?: {
        primary: HexColor
        secondary: HexColor
    }
}

const DomainSelectModal: React.FC<DomainSelectModalProps> = ({
    open,
    onClose,
    locationId,
    userUid,
    onSuccessPublishing,
    colors,
}) => {
    const [domain, setDomain] = useState<string>("")
    const { t } = useTranslation()
    const [domainName, setDomainName] = useState<string>("")
    const [publishLoading, setPublishLoading] = useState<boolean>(false)
    const { notif } = useNotification()
    const [domainAlreadyExists, setDomainAlreadyExists] = useState<boolean>(false)

    useEffect(() => {
        if (locationId) {
            QuicksiteService.getDomain(locationId).then((response) => {
                setDomainName(response.domain)
            })
        }
    }, [locationId])

    const publish = useCallback(
        async (subdomain: string) => {
            setPublishLoading(true)
            const response = await QuicksiteService.publish({
                locationId: locationId,
                userUid,
                subdomain,
                domain: domainName,
                settings: {
                    design: {
                        theme: "default",
                        primaryColor: colors?.primary ?? "#000000",
                        secondaryColor: colors?.secondary ?? "#FFFFFF",
                        darkTheme: "false",
                    },
                    sections: {
                        header: "true",
                        hero: "true",
                        services: "true",
                        area: "true",
                        gallery: "true",
                        reviews: "true",
                        contact: "true",
                        footer: "true",
                    },
                },
            })

            if (response?.error) {
                if (response?.error === "DOMAIN_NOT_AVAILABLE") {
                    setDomainAlreadyExists(true)
                } else {
                    notif({ message: t("SYSTEM_ERROR.INVALID_REQUEST"), type: "ERROR" })
                }
                setPublishLoading(false)
                return
            }

            onClose()
            onSuccessPublishing()
            notif({
                message: t("WEB_PAGE.NOTIFICATIONS.DOMAIN_PUBLISHED"),
                type: "SUCCESS",
            })
            setPublishLoading(false)
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [domain, domainName, locationId, userUid]
    )

    const isValidSubdomain = useMemo((): boolean => {
        if (domain === "") return true // Pas d'erreur au départ
        const regex: RegExp = /^(?!-)(?!.*--)[a-z0-9-]{1,63}(?<!-)$/
        return regex.test(domain)
    }, [domain])

    const handleChangeDomaine = useCallback(
        (value) => {
            setDomain(value)
            if (domainAlreadyExists) {
                setDomainAlreadyExists(false)
            }
        },
        [domainAlreadyExists]
    )

    return (
        <Modal
            open={open}
            onClose={onClose}
            data-testid="domain-select-modal"
            disableEnforceFocus
            BackdropProps={{
                onClick: (event) => event.stopPropagation(), // Avoid close modal onclickAway on the backdrop
            }}
        >
            <Paper
                elevation={6}
                sx={withSxProp(modalStyle, {
                    width: "460px",
                    padding: "16px",
                    gap: "16px",
                })}
            >
                {/* Close button */}
                <IconButton onClick={onClose} sx={closeButton}>
                    <Icon name="x" color={"#0180FF"} />
                </IconButton>

                <Box sx={{ width: "428px", height: "52px" }}>
                    {/* Title */}
                    <Typography variant="h2" sx={titleStyle}>
                        {t("WEB_PAGE.MODAL.TITLE")}
                    </Typography>

                    {/* Subtitle */}
                    <Typography variant="body2" sx={subtitleStyle}>
                        {t("WEB_PAGE.MODAL.SUBTITLE")}
                    </Typography>
                </Box>

                {/* Input Field */}
                <BaseInput
                    fullWidth
                    type="text"
                    required
                    label={t("WEB_PAGE.MODAL.INPUT_LABEL")}
                    value={domain}
                    onChange={(e) => handleChangeDomaine(e.target.value)}
                    data-testid="domain-name"
                    InputProps={{
                        endAdornment: <Typography sx={subtitleStyle}>.{domainName}</Typography>,
                    }}
                    error={(!isValidSubdomain && domain !== "") || domainAlreadyExists}
                    helperText={
                        !isValidSubdomain && domain !== ""
                            ? t("SYSTEM_ERROR.INVALID_DOMAIN")
                            : domainAlreadyExists
                            ? t("WEB_PAGE.NOTIFICATIONS.DOMAIN_ALREADY_USED")
                            : ""
                    }
                    max={MAX_INPUT.TEXT}
                />

                {/* Action Buttons */}
                <Stack direction="row" justifyContent="space-between" spacing={2} width={"100%"}>
                    <Button
                        onClick={() => publish(domain)}
                        variant="contained"
                        color="primary"
                        sx={saveButton}
                        disabled={!isValidSubdomain || domain === "" || publishLoading}
                        data-testid="save"
                        startIcon={publishLoading && <CircularProgress size={16} sx={{ marginRight: "8px" }} />}
                    >
                        {t("CAMPAIGN.CONTINUE")}
                    </Button>
                </Stack>
            </Paper>
        </Modal>
    )
}

export default DomainSelectModal
