import { SxProps } from "@mui/material"

export const paperColor: SxProps = {
    position: "absolute",
    top: 160,
    left: 70,
    zIndex: 1200,
    transform: "translate(-50%, -50%)",
    backgroundColor: "#fff",
    padding: "16px",
    border: "1px solid #ccc",
    borderRadius: "8px",
    boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
}

export const endAdornmentColor: SxProps = {
    width: "14px",
    height: "13px",
    borderRadius: "100%",
    filter: "drop-shadow(0px 0px 5px rgba(0, 0, 0, 0.70))",
    stroke: "#FFF",
 
    strokeWidth: "1px",
}

export const chevronBtn: SxProps = {
    padding: "0 !important",
}

export const colorItem: SxProps = {
    width: "30px",
    height: "30px",
    borderRadius: "8px",
    cursor: "pointer",
}
