import { getWebStatusChipProps, WebStatusProps } from "@/utils/web-page/get-status"
import { Chip } from "@mui/material"
import { useTranslation } from "react-i18next"

const StatusChip: React.FC<WebStatusProps & { squared?: boolean }> = ({ status, squared = false }) => {
    const { t } = useTranslation()
    const { label, color } = getWebStatusChipProps(t, status)
    if (!label) return <></>
    return (
        <Chip
            label={label}
            color={color as any}
            variant="filled"
            sx={{
                color: "white",
                borderRadius: squared ? "8px" : "16px",
            }}
        />
    )
}

export default StatusChip
