import { create } from "zustand"

type MenuPageType =
    | "default"
    | "companies"
    | "users"
    | "businesses"
    | "reputation"
    | "local_presence"
    | "request_opinion"
    | "statistic_reputation"
    | "qr"
    | "web-page-detail"

type PageType = "list" | "create" | "edit" | "detail"

export type PageBusinesssType =
    | PageType
    | "edit-link"
    | "edit-category"
    | "edit-description"
    | "edit-main-info"
    | "edit-standard-schedule"
    | "edit-exceptional-schedule"
    | "edit-zone"
    | "edit-service"
    | "edit-attribute"
    | "edit-contact"
    | "edit-media"
    | "list-media"
    | "edit-pro-infos"

export interface IUiPageSessionStore {
    menuPage: MenuPageType
    showPage: PageBusinesssType
    selectedItem: any
    previousItem: any
    refreshItems: boolean
}

export interface IUiPageSessionSetter {
    updateState: (value: Partial<IUiPageSessionStore>) => void
    updatePreviousItem: (value: any) => void
    flush: () => void
}

const initalState: IUiPageSessionStore = {
    menuPage: "default",
    showPage: "list",
    selectedItem: null,
    previousItem: null,
    refreshItems: false,
}

const UiPageSessionStore = create<IUiPageSessionStore & IUiPageSessionSetter>((set) => ({
    ...initalState,
    updateState: (newValue: any = {}) => set(() => ({ ...newValue })),
    updatePreviousItem: (newValue: any = {}) =>
        set(({ previousItem = {} }) => {
            return { previousItem: { ...previousItem, ...newValue } }
        }),
    flush: () => set(initalState),
}))

export default UiPageSessionStore
