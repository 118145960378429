import { CheckBoxOutlineBlankOutlined } from "@mui/icons-material"
import { Box, Checkbox } from "@mui/material"
import { ReactNode } from "react"
import { UseFormSetValue } from "react-hook-form"
import Icon from "../Icon"
import { useTranslation } from "react-i18next"
import { componentProps, CustomAutocomplete } from "./styles"
import LocationOnIcon from "@mui/icons-material/LocationOn"
import parse from "autosuggest-highlight/parse"
import Grid from "@mui/material/Grid"
import RenderInputBloc from "../autocomplete-render-input"

export type BaseAutocompletePropsType = {
    options: any
    setValue?: UseFormSetValue<any>
    name?: string
    label?: string
    defaultValue?: any
    control?: any
    multiple?: boolean
    hideArrow?: boolean
    required?: boolean
    disabled?: boolean
    popupIcon?: ReactNode
    keepIconRotationOnOpen?: boolean
    helperTextPosition?: AlignSetting
    id?: string
    onChange?: any
    autocompleteValue?: any
    field?: any
    type: string
    open?: boolean
    getOptionDisabled?: any
    onOpen?: any
    onClose?: any
    placeholder?: string
    isOptionEqualToValue?: any
    getOptionLabel?: any
    loading?: boolean
    loadingText?: string
    noOptionsText?: string
    onInputChange?: any
    helperText?: string
    inputCssProps?: any
    inputValue?: any
    freeSolo?: boolean
    includeInputInList?: boolean
    filterSelectedOptions?: boolean
    value?: any
    error?: boolean
    onBlur?: () => void
    customRenderOption?: (option) => ReactNode
}

const icon = <CheckBoxOutlineBlankOutlined fontSize="small" />
const checkedIcon = <Icon name="checkboxFilled" />

const RenderOptionBloc = ({ type, option, options, selected, otherProps, multiple, customRenderOption }) => {
    switch (type) {
        case "checkbox":
            return (
                <Box
                    key={selected}
                    component={"li"}
                    sx={{
                        ...(options.length === 1 && option.disabled
                            ? { "&.MuiBox-root": { justifyContent: "center", padding: 2, textAlign: "center" } }
                            : {}),
                        '&.MuiAutocomplete-option[aria-disabled="true"]': {
                            opacity: 1,
                            color: "action.active",
                        },
                        ...(!multiple && {
                            "&.Mui-focused": {
                                "& svg": {
                                    color: "text.primary",
                                },
                            },
                            "&[aria-selected='true']": {
                                "& svg": {
                                    color: "primary.main",
                                },
                            },
                            "& svg": {
                                color: "transparent",
                            },
                        }),
                    }}
                    {...otherProps}
                >
                    {!option.disabled && <Checkbox icon={icon} checkedIcon={checkedIcon} checked={selected} />}
                    {customRenderOption ? customRenderOption(option) : option?.label}
                </Box>
            )
        case "category":
            return (
                <Box
                    component={"li"}
                    sx={{
                        "&.MuiAutocomplete-option": {
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            width: "100%",
                            "&.Mui-focused": {
                                "& svg": {
                                    color: "text.primary",
                                },
                            },
                            "&[aria-selected='true']": {
                                "& svg": {
                                    color: "primary.main",
                                },
                            },
                            "& svg": {
                                color: "transparent",
                            },
                        },
                    }}
                    {...otherProps}
                    key={option.value}
                >
                    {option.title}
                    <Icon name="check" />
                </Box>
            )

        case "google":
            // eslint-disable-next-line no-case-declarations
            const matches = option?.structured_formatting?.main_text_matched_substrings || []
            // eslint-disable-next-line no-case-declarations
            const parts = parse(
                `${option?.structured_formatting?.main_text}, ${option?.structured_formatting?.secondary_text}`,
                matches.map((match: any) => [match?.offset, match?.offset + match?.length])
            )
            return (
                <li {...otherProps} key={`${parts?.join("_")}_${selected}`}>
                    <Grid container alignItems="center" sx={{ p: 1 }}>
                        <Grid item sx={{ display: "flex", width: 44 }}>
                            <LocationOnIcon sx={{ color: "text.secondary" }} />
                        </Grid>
                        <Grid item sx={{ width: "calc(100% - 44px)", wordWrap: "break-word" }}>
                            {parts.map((part, index) => (
                                <Box
                                    key={index}
                                    component="span"
                                    sx={{
                                        fontWeight: part.highlight ? "bold" : "regular",
                                        color: part.highlight ? "#111" : "#888",
                                    }}
                                >
                                    {part.text}
                                </Box>
                            ))}
                        </Grid>
                    </Grid>
                </li>
            )

        case "google-place":
            return (
                <Box
                    component={"li"}
                    data-e2e="autocomplete-option"
                    sx={{
                        "&.MuiAutocomplete-option": {
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            width: "100%",
                            "&.Mui-focused": {
                                "& svg": {
                                    color: "text.primary",
                                },
                            },
                            "&[aria-selected='true']": {
                                "& svg": {
                                    color: "primary.main",
                                },
                            },
                            "& svg": {
                                color: "transparent",
                            },
                        },
                    }}
                    key={`${option.uid}`}
                    {...otherProps}
                >
                    {option.description}
                    <Icon name="check" />
                </Box>
            )
        default:
            return <></>
    }
}

const BaseAutocomplete = ({
    id,
    options,
    label,
    defaultValue,
    multiple = true,
    hideArrow,
    noOptionsText,
    required,
    disabled,
    popupIcon,
    onChange,
    helperText,
    helperTextPosition,
    autocompleteValue,
    field,
    type,
    inputCssProps,
    error,
    customRenderOption,
    ...rest
}: BaseAutocompletePropsType) => {
    const { t } = useTranslation()

    return (
        <CustomAutocomplete
            disabled={disabled}
            id={id}
            value={autocompleteValue || ""}
            multiple={multiple}
            options={options}
            noOptionsText={noOptionsText ?? t("AUTOCOMPLETE.NO_OPTIONS")}
            loadingText={t("AUTOCOMPLETE.LOADING")}
            {...(hideArrow && { popupIcon: "" })}
            disableCloseOnSelect
            onChange={onChange}
            getOptionDisabled={(option: any) => option.disabled}
            defaultValue={defaultValue}
            getOptionLabel={(option: any) => option.label ?? ""}
            popupIcon={popupIcon}
            inputCssProps={{
                $requiredelement: required,
                $multiple: multiple,
                $multipleelement: multiple && (rest?.value || field)?.length > 0 ? true : false,
                ...inputCssProps,
            }}
            ChipProps={{
                dataTestid: "chip-item",
            }}
            componentsProps={componentProps(["category", "google-place"].includes(type) ? 1 : "4px 0px")}
            filterOptions={(x) => x}
            renderOption={(props, option: any, { selected }) => (
                <RenderOptionBloc
                    option={option}
                    options={options}
                    selected={selected}
                    type={type}
                    multiple={multiple}
                    otherProps={props}
                    key={option?.uid}
                    customRenderOption={customRenderOption}
                />
            )}
            renderInput={(params) => (
                <RenderInputBloc
                    value={autocompleteValue}
                    label={label ?? ""}
                    multiple={multiple}
                    options={options}
                    field={field}
                    type={type}
                    required={required}
                    helperText={helperText}
                    helperTextPosition={helperTextPosition}
                    otherParams={{
                        ...params,
                        error,
                    }}
                />
            )}
            {...rest}
        />
    )
}

export default BaseAutocomplete
