import { CompanyApi, ReputationApi } from "@/api"
import { ReviewStatus } from "@/data/reputationStatus"
import useNotification from "@/hooks/useNotification"
import { ReputationService } from "@/services"
import ReputationStore from "@/store/reputation"
import UiStore from "@/store/ui"
import UserSessionStore from "@/store/user-session"
import { useCallback, useMemo, useState } from "react"
import { useTranslation } from "react-i18next"

const useLogic = (id: string, content: string, handleDeleteChange, emailSupport?: string) => {
    const { t } = useTranslation()
    const { update: setReputationUpdate, reviews } = ReputationStore()
    const { profile } = UserSessionStore()
    const [text, setText] = useState<string>(content)
    const [openModalDelete, setOpenModalDelete] = useState<boolean>(false)
    const [openModalDeleteAvis, setOpenModalDeleteAvis] = useState<boolean>(false)
    const [modify, setModify] = useState<boolean>(false)
    const [loading, setLoading] = useState<boolean>(false)
    const [loadingAI, setLoadingAI] = useState<boolean>(false)
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
    const open = useMemo(() => Boolean(anchorEl), [anchorEl])
    const [anchorElDelete, setAnchorElDelete] = useState<null | HTMLElement>(null)
    const openDelete = useMemo(() => Boolean(anchorElDelete), [anchorElDelete])
    const { preferredLanguage } = UiStore()
    const { notif } = useNotification()
    const maxLength = 4096

    const handleClose = () => {
        setAnchorEl(null)
    }

    const handleCloseDelete = () => {
        setAnchorElDelete(null)
    }

    const handleModalDelete = useCallback(
        (value: boolean) => {
            setOpenModalDelete(value)
            if (!value) {
                handleClose()
            }
        },
        [handleClose, setOpenModalDelete]
    )

    const handleModalDeleteAvis = useCallback(
        (value: boolean) => {
            setOpenModalDeleteAvis(value)
            if (!value) {
                handleClose()
            }
        },
        [setOpenModalDeleteAvis, handleClose]
    )

    const toggleMenu = useCallback(
        (event: React.MouseEvent<HTMLButtonElement>) => {
            setAnchorEl(!open ? event.currentTarget : null)
        },
        [setAnchorEl, open]
    )

    const toggleDeleteMenu = useCallback(
        (event: React.MouseEvent<HTMLButtonElement>) => {
            setAnchorElDelete(!openDelete ? event.currentTarget : null)
        },
        [setAnchorElDelete, openDelete]
    )

    const handleModify = useCallback(
        (value: boolean) => {
            setModify(value)
            handleClose()
            if (!value) {
                setText(content)
            }
        },
        [handleClose, setText, setModify]
    )

    const handleOnEnter = useCallback(
        async (text: string, isByAi = false) => {
            setLoading(true)
            const body = {
                status: text ? ReviewStatus.TREATED : ReviewStatus.WAITING,
                lang: preferredLanguage,
                reply: {
                    content: text,
                    ai: isByAi,
                    // updatedAt: dayjs().format("YYYY-MM-DDTHH:mm:ss.SSS[Z]"),
                },
                replyTo: emailSupport && emailSupport !== "" ? emailSupport : profile.email,
            }
            const response = await ReputationApi.answerReview({ id, body })
            if (response?.id) {
                setModify(false)
                setText(text || "")
                const populatedLogoPlatform = await ReputationService.populateLogoPlatform([response])
                const newReviews = reviews.map((item) => {
                    if (item?.id === response?.id) {
                        item = populatedLogoPlatform?.[0] ?? response
                    }
                    return item
                })
                setReputationUpdate({ reviews: newReviews })
            }
            setOpenModalDelete(false)
            handleClose()
            setLoading(false)
        },
        [handleClose, id, preferredLanguage, reviews, setReputationUpdate]
    )

    const handleDeleteAvis = useCallback(async () => {
        setLoading(true)
        const response = await ReputationApi.deleteReview({ id })
        if (response?.error) {
            notif({ message: t("SYSTEM_ERROR.INVALID_REQUEST"), type: "ERROR" })
            setOpenModalDeleteAvis(false)
            handleClose()
            setLoading(false)
        } else {
            handleDeleteChange()
            setOpenModalDeleteAvis(false)
            handleClose()
            setLoading(false)
        }
    }, [handleClose, id, reviews, t, handleDeleteChange, notif])

    const handleText = useCallback(
        (text: string) => {
            const newText = text.length >= maxLength ? text.substring(0, maxLength) : text
            setText(newText)
        },
        [maxLength]
    )

    const handleSuggest = useCallback(async () => {
        if (id) {
            setLoadingAI(true)
            const response = await ReputationService.suggestResponse(id)
            if (response?.aiReply) {
                setText(response.aiReply)
            }
            setLoadingAI(false)
        }
    }, [id])

    const handleContinueWithActualAddress = async (companyId?: string) => {
        if (!companyId) throw new Error["No company id provided"]()
        await CompanyApi.updateACompanyEmailSupport({
            companyId,
            user_uid: profile.uid,
            is_show_choice_email_support: false,
        }).then(() => {
            setReputationUpdate({
                reviews: reviews.map((item) => (item?.id === id ? { ...item, isShowChoiceEmailSupport: false } : item)),
            })
        })
    }

    return {
        t,
        text,
        open,
        openDelete,
        anchorEl,
        anchorElDelete,
        loading,
        loadingAI,
        maxLength,
        setText,
        handleOnEnter,
        handleClose,
        toggleMenu,
        toggleDeleteMenu,
        handleCloseDelete,
        modify,
        handleModify,
        handleText,
        setLoading,
        handleModalDelete,
        openModalDelete,
        openModalDeleteAvis,
        handleModalDeleteAvis,
        handleDeleteAvis,
        handleSuggest,
        userUid: profile.uid,
        userEmail: profile.email,
        handleContinueWithActualAddress,
    }
}

export default useLogic
