import SerializedString from "@/components/widget/serialized-string"
import useBusinessLogic from "@/hooks/businesses/useBusinessLogic"
import { Paper } from "@mui/material"
import { useTranslation } from "react-i18next"
import ErrorMessage from "../../error-message"
import OverviewTitle from "../title"
import OverviewProInfosStore from "@/store/overview/pro-infos"

const OverviewProInfos = ({ isLoading }) => {
    const { t } = useTranslation()
    const { professionalInfo } = OverviewProInfosStore()
    const { navigateTo } = useBusinessLogic()
    return (
        <Paper
            elevation={2}
            className="clickable"
            id="description"
            onClick={() => !isLoading && navigateTo("edit-pro-infos")}
        >
            <OverviewTitle
                title={t("BUSINESSES.EDIT_PRO_INFOS.TITLE")}
                // icon="file"
                isSecondTitle
                tooltipText={t("BUSINESSES.EDIT_PRO_INFOS.TOOLTIP_ADD")}
            />
            {/* {(!description || !description?.short) && <ErrorMessage />} */}
            {!!professionalInfo && <SerializedString text={professionalInfo ?? ""} />}
        </Paper>
    )
}

export default OverviewProInfos
