import { TFunction } from "i18next"

export type WebStatusProps = {
    status: "IN_PROGRESS" | "PUBLISHED" | "SUSPENDED" | "FAILED"
}

export const getWebStatusChipProps = (t: TFunction<"translation", undefined>, status: WebStatusProps["status"]) => {
    switch (status) {
        case "IN_PROGRESS":
            return { label: t("LISTINGS.ONGOING"), color: "info" }
        case "SUSPENDED":
            return { label: t("WEB_PAGE.STATUS.SUSPENDED"), color: "error" }
        case "PUBLISHED":
            return { label: t("WEB_PAGE.STATUS.PUBLISHED"), color: "success" }
        case "FAILED":
            return { label: t("WEB_PAGE.STATUS.FAILED"), color: "error" }
        default:
            return { label: undefined, color: undefined }
    }
}
