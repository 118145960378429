import { IconButton } from "@mui/material"
import Icon from "../Icon"
import useClipboard from "@/hooks/useClipboard"
import CustomTooltip from "../Tooltip"
import { useTranslation } from "react-i18next"

/**
 * Props pour le composant `CopiableLink`.
 */
type CopiableLinkProps = {
    link: string
}

/**
 * Composant qui donne la possibilité de copier le lien entré en paramètre.
 *
 * @component
 * @example
 * // Exemple d'utilisation du composant CopiableLink
 * <CopiableLink link="https://example.com" />
 *
 * @param {CopiableLinkProps} link - Le lien copiable affiché
 * @returns {JSX.Element} L'affichage attentu
 */
const CopiableLink: React.FC<CopiableLinkProps> = ({ link }) => {
    const { handleClipboardCopy } = useClipboard()
    const { t } = useTranslation()

    return (
        <CustomTooltip
            title={t("WEB_PAGE.CARD.COPY_LINK")}
            sx={{ backgroundColor: "#F5F5F5", width: "36px", height: "36px", borderRadius: "8px" }}
        >
            <IconButton onClick={() => handleClipboardCopy(link)}>
                <Icon name="link" />
            </IconButton>
        </CustomTooltip>
    )
}

export default CopiableLink
